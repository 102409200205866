import { useQuery } from '@tanstack/react-query'
import { vehiclesQueryKey } from 'constants/reactQueryKeys'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllVehiclesService } from 'services/vehicles'

const useVehicles = () => {
	const { t } = useTranslation()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	//GET ALL VEHICLES
	const {
		data: vehicles,
		isLoading: isLoadingVehicles,
		error: vehiclesError,
		isError: isErrorVehicles
	} = useQuery({
		queryKey: [vehiclesQueryKey],
		queryFn: getAllVehiclesService,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	useEffect(() => {
		if (isErrorVehicles && vehiclesError) {
			setErrorMessage(t('vehicle.vehicles_error'))
		}
	}, [isErrorVehicles, vehiclesError, t])

	return {
		vehicles: vehicles || [],
		loading: isLoadingVehicles,
		error: errorMessage
	}
}

export default useVehicles
