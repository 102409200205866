import StepDetailsInputs from 'components/Transfer/StepInputs/StepDetailsInputs'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useDrivers from 'hooks/useDrivers'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { TransferPost, type StepDetailsForm } from 'models/Transfer'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTransfersStore } from 'stores/useTransfersStore'
import * as Yup from 'yup'

const StepDetails = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const updateActualTransferCreation = useTransfersStore(
		(state) => state.updateActualTransferCreation
	)
	const { drivers } = useDrivers()

	const formik = useFormik<StepDetailsForm>({
		initialValues: {
			externalId: null,
			driverId: null,
			providerId: null,
			vehicleId: null,
			passengers: 1
		},
		validationSchema: Yup.object().shape({
			externalId: Yup.string().nullable(),
			driverId: Yup.number().required(t('errors.required_m', { field: t('transfer.driver') })),
			providerId: Yup.number().required(t('errors.required_m', { field: t('transfer.provider') })),
			vehicleId: Yup.number().nullable(),
			passengers: Yup.number().required(t('errors.required_m', { field: t('transfer.passengers') }))
		}),
		onSubmit: (values) => {
			if (!values.driverId || !values.providerId) return
			const transferPost: Partial<TransferPost> = {
				externalId: values.externalId,
				driverId: values.driverId,
				providerId: values.providerId,
				vehicleId: values.vehicleId ?? undefined,
				passengers: values.passengers
			}
			updateActualTransferCreation(transferPost)
			navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.ADDRESSES.toLocaleLowerCase()}`)
		}
	})

	useEffect(() => {
		const DEFAULT_DRIVER = drivers.find((driver) => driver.isPending === true)
		if (DEFAULT_DRIVER) {
			formik.setFieldValue('driverId', DEFAULT_DRIVER.id)
		}
	}, [drivers])

	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={2}>
			<FormSectionLayout title={t('transfer.create_steps.step_details.h1')} noDivider>
				<StepDetailsInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepDetails
