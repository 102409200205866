import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	driverAssociationsQueryKey,
	driverQueryKey,
	driversQueryKey
} from 'constants/reactQueryKeys'
import { DriverAssociations, DriverPost } from 'models/Driver'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	createDriverService,
	deleteAssociationsByDriverId,
	deleteDriverById,
	getDriverAssociations,
	getDriverById,
	resetAndSetAssociationsToDriver,
	updateDriverService
} from 'services/drivers'
import { createDriverAssociationsByUserService } from 'services/user'

const useDriver = (driverId?: number) => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	// GET SINGLE DRIVER
	const {
		data: driver,
		isLoading: isLoadingDriver,
		error: driverError,
		isError: isErrorDriver,
		isSuccess: isSuccessDriver
	} = useQuery({
		queryKey: [driverQueryKey, driverId],
		queryFn: () => getDriverById(driverId!),
		enabled: !!driverId,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	// GET DRIVER ASSOCIATIONS
	const {
		data: driverAssociations,
		isLoading: isLoadingAssociations,
		error: associationsError,
		isError: isErrorAssociations,
		refetch: refetchAssociations
	} = useQuery({
		queryKey: [driverAssociationsQueryKey],
		queryFn: getDriverAssociations,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true,
		enabled: isSuccessDriver
	})

	useEffect(() => {
		if (isErrorDriver && driverError) {
			setErrorMessage(t('driver.error_load_driver'))
		}
		if (isErrorAssociations && associationsError) {
			setErrorMessage(t('driver.error_load_associations'))
		}
	}, [isErrorDriver, driverError, isErrorAssociations, associationsError, t])

	// Invalidate Queries
	const invalidateDriverQueries = (driverId?: number) => {
		if (driverId) {
			queryClient.invalidateQueries({
				queryKey: [driverQueryKey, driverId]
			})
		}
		queryClient.invalidateQueries({
			queryKey: [driverAssociationsQueryKey]
		})

		queryClient.invalidateQueries({
			queryKey: [driversQueryKey]
		})
	}

	// CREATE DRIVER
	const { mutateAsync: createDriver } = useMutation({
		mutationFn: createDriverService,
		onSuccess: () => {
			invalidateDriverQueries()
		},
		onError: (error: unknown) => {
			setErrorMessage(t('driver.error_create'))
			console.error('Error creando conductor:', error)
		}
	})

	// UPDATE DRIVER
	const { mutateAsync: updateDriver } = useMutation({
		mutationFn: (data: { driver: Partial<DriverPost>; driverId: number }) =>
			updateDriverService(data.driver, data.driverId),
		onSuccess: () => {
			invalidateDriverQueries(driverId)
		},
		onError: (error: unknown) => {
			setErrorMessage(t('driver.error_update'))
			console.error('Error actualizando conductor:', error)
		}
	})

	// resetAndSetAssociationsToDriver
	const { mutateAsync: resetAndSetAssociations } = useMutation({
		mutationFn: (data: { driverId: number; userIds: number[] }) =>
			resetAndSetAssociationsToDriver(data.driverId, data.userIds),
		onSuccess: () => {
			invalidateDriverQueries()
		},
		onError: () => {
			setErrorMessage(t('driver.reset_set_associations_error'))
		}
	})

	// Activate Driver
	const activateDriver = async (driverId: number) => {
		try {
			await updateDriver({ driver: { isActive: true }, driverId })
		} catch (e) {
			setErrorMessage(t('driver.activate_driver_error'))
			console.error(e)
		}
	}

	// Deactivate Driver
	const deactivateDriver = async (driverId: number) => {
		try {
			await updateDriver({ driver: { isActive: false }, driverId })
		} catch (e) {
			setErrorMessage(t('driver.deactivate_driver_error'))
			console.error(e)
		}
	}

	const deleteAssociationsByDriver = async (driverId: number) => {
		try {
			await deleteAssociationsByDriverId(driverId)
		} catch (e) {
			setErrorMessage(t('driver.delete_associations_error'))
			console.error(e)
		}
	}

	const createAssociationsByUserId = async (userId: number, driverIds: number[]) => {
		try {
			return await createDriverAssociationsByUserService(userId, driverIds)
		} catch (e) {
			setErrorMessage(t('driver.create_associations_error'))
			console.error(e)
		}
	}

	const getAssociationsByUserId = (userId: number, associations: DriverAssociations[]) => {
		return associations?.filter((association) => association.userId === userId) || []
	}

	const hasAssociations = (driverId: number) => {
		return driverAssociations?.some((association) => association.driverId === driverId) || false
	}

	const getAssociationsByDriverId = (driverId: number) => {
		return driverAssociations?.filter((association) => association.driverId === driverId) || []
	}

	// DELETE DRIVER
	const { mutateAsync: removeDriver } = useMutation({
		mutationFn: deleteDriverById,
		onSuccess: (_data, driverId) => {
			invalidateDriverQueries(driverId)
		},
		onError: () => {
			setErrorMessage(t('driver.delete_driver_error'))
		}
	})

	return {
		driver,
		loading: isLoadingDriver,
		error: errorMessage,
		isSuccess: isSuccessDriver,
		driverAssociations: driverAssociations || [],
		loadingAssociations: isLoadingAssociations,
		createDriver,
		updateDriver,
		activateDriver,
		deactivateDriver,
		deleteAssociationsByDriver,
		resetAndSetAssociations,
		createAssociationsByUserId,
		getAssociationsByUserId,
		hasAssociations,
		getAssociationsByDriverId,
		removeDriver,
		refetchAssociations
	}
}

export default useDriver
