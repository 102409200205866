import { useConfigStore } from 'stores/index'
import { useLoadingStore } from 'stores/useLoadingStore'

const useInitializeAppData = () => {
	// const [isLoading, setIsLoading] = useState(false)
	const configState = useConfigStore()
	const loadingState = useLoadingStore()

	const getAllData = async () => {
		try {
			loadingState.setLoading(true)
			await Promise.all([
				configState.fetchAllVehicleClasses(),
				configState.fetchDocumentTypes(),
				configState.fetchAllRoles(),
				configState.fetchAllRouteTypes(),
				configState.fetchAllProvinces(),
				configState.fetchAllMunicipalities()
			])
		} catch (error) {
			console.error(error)
		} finally {
			loadingState.setLoading(false)
		}
	}

	return { getAllData, isLoading: loadingState.isLoading }
}

export default useInitializeAppData
