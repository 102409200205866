import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CancelCommunicationIcon: FC<Props> = (props) => {
	const { width = 35, height = 35 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 35 35'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M25.7752 25.6962C25.1302 24.5852 24.1715 21.4417 24.1715 17.3359C24.1715 14.8703 23.192 12.5056 21.4485 10.7621C19.705 9.01855 17.3403 8.03906 14.8746 8.03906C12.4089 8.03906 10.0442 9.01855 8.30075 10.7621C6.55724 12.5056 5.57775 14.8703 5.57775 17.3359C5.57775 21.4428 4.61785 24.5852 3.97288 25.6962C3.80818 25.9786 3.72086 26.2995 3.71974 26.6264C3.71862 26.9534 3.80373 27.2749 3.96649 27.5584C4.12925 27.842 4.36391 28.0776 4.64679 28.2416C4.92968 28.4055 5.2508 28.4919 5.57775 28.4922H10.3203C10.5348 29.5418 11.1052 30.485 11.9351 31.1625C12.765 31.8399 13.8034 32.2099 14.8746 32.2099C15.9459 32.2099 16.9843 31.8399 17.8141 31.1625C18.644 30.485 19.2144 29.5418 19.4289 28.4922H24.1715C24.4984 28.4917 24.8193 28.4051 25.1021 28.2411C25.3848 28.0771 25.6193 27.8414 25.7819 27.5579C25.9445 27.2744 26.0295 26.953 26.0284 26.6261C26.0272 26.2993 25.9399 25.9785 25.7752 25.6962ZM14.8746 30.3516C14.298 30.3514 13.7356 30.1725 13.2649 29.8395C12.7941 29.5065 12.4381 29.0358 12.2459 28.4922H17.5033C17.3111 29.0358 16.9551 29.5065 16.4844 29.8395C16.0136 30.1725 15.4512 30.3514 14.8746 30.3516Z'
				fill={colors.violetMt}
			/>
			<circle cx='22.75' cy='12.25' r='12.25' fill='#FEF5F5' />
			<path
				d='M22.75 2.29688C20.7815 2.29688 18.8571 2.88062 17.2203 3.97428C15.5836 5.06794 14.3078 6.62241 13.5545 8.4411C12.8012 10.2598 12.6041 12.261 12.9881 14.1918C13.3722 16.1225 14.3201 17.896 15.7121 19.2879C17.1041 20.6799 18.8775 21.6278 20.8082 22.0119C22.739 22.3959 24.7402 22.1988 26.5589 21.4455C28.3776 20.6922 29.9321 19.4164 31.0257 17.7797C32.1194 16.1429 32.7031 14.2185 32.7031 12.25C32.7003 9.61112 31.6508 7.08113 29.7848 5.21516C27.9189 3.34919 25.3889 2.29966 22.75 2.29688ZM26.3542 14.7708C26.4253 14.842 26.4817 14.9264 26.5202 15.0193C26.5587 15.1123 26.5786 15.2119 26.5786 15.3125C26.5786 15.4131 26.5587 15.5127 26.5202 15.6057C26.4817 15.6986 26.4253 15.783 26.3542 15.8542C26.2831 15.9253 26.1986 15.9817 26.1057 16.0202C26.0127 16.0587 25.9131 16.0786 25.8125 16.0786C25.7119 16.0786 25.6123 16.0587 25.5194 16.0202C25.4264 15.9817 25.342 15.9253 25.2708 15.8542L22.75 13.3324L20.2292 15.8542C20.1581 15.9253 20.0736 15.9817 19.9807 16.0202C19.8877 16.0587 19.7881 16.0786 19.6875 16.0786C19.5869 16.0786 19.4873 16.0587 19.3943 16.0202C19.3014 15.9817 19.217 15.9253 19.1458 15.8542C19.0747 15.783 19.0183 15.6986 18.9798 15.6057C18.9413 15.5127 18.9215 15.4131 18.9215 15.3125C18.9215 15.2119 18.9413 15.1123 18.9798 15.0193C19.0183 14.9264 19.0747 14.842 19.1458 14.7708L21.6676 12.25L19.1458 9.72918C19.0022 9.58552 18.9215 9.39067 18.9215 9.1875C18.9215 8.98433 19.0022 8.78948 19.1458 8.64582C19.2895 8.50216 19.4843 8.42145 19.6875 8.42145C19.8907 8.42145 20.0855 8.50216 20.2292 8.64582L22.75 11.1676L25.2708 8.64582C25.342 8.57469 25.4264 8.51826 25.5194 8.47976C25.6123 8.44126 25.7119 8.42145 25.8125 8.42145C25.9131 8.42145 26.0127 8.44126 26.1057 8.47976C26.1986 8.51826 26.2831 8.57469 26.3542 8.64582C26.4253 8.71695 26.4817 8.8014 26.5202 8.89435C26.5587 8.98729 26.5786 9.0869 26.5786 9.1875C26.5786 9.2881 26.5587 9.38771 26.5202 9.48066C26.4817 9.5736 26.4253 9.65805 26.3542 9.72918L23.8324 12.25L26.3542 14.7708Z'
				fill='#F4516C'
			/>
		</svg>
	)
}

export default CancelCommunicationIcon
