import StepClientInputs from 'components/Transfer/StepInputs/StepClientInputs'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { StepClientForm, TransferPost } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import * as Yup from 'yup'

const StepClient = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const transferState = useTransfersStore()

	const formik = useFormik<StepClientForm>({
		initialValues: {
			clientName: emptyString,
			email: emptyString,
			phone: emptyString
		},
		validationSchema: Yup.object().shape({
			clientName: Yup.string().required(
				t('errors.required_m', { field: t('transfer.clientName') })
			),
			email: Yup.string().email(t('errors.mail_not_valid')).nullable()
		}),
		onSubmit: (values) => {
			const transferPost: Partial<TransferPost> = {
				clientName: values.clientName,
				email: values.email,
				phone: values.phone
			}
			transferState.updateActualTransferCreation(transferPost)
			navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.EXTRAS.toLocaleLowerCase()}`)
		}
	})
	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={6}>
			<FormSectionLayout title={t('transfer.create_steps.step_client.h1')}>
				<StepClientInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepClient
