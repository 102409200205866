import {
	ChangeMyPasswordForm,
	DriverAssociations,
	FetchResponse,
	ProviderAssociations,
	User,
	UserForm,
	UserPost
} from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserId, getUserToken } from './utils'

export interface Register {
	name: string
	email: string
	password: string
}

export interface Credentials {
	username: string
	password: string
}

export interface IAMResponse {
	token?: string
	user: User
}

export const loginService = async (credentials: Credentials): Promise<User> => {
	try {
		const response = await customFetch<FetchResponse<IAMResponse>>(`${endpoints.login}`, {
			method: 'POST',
			bodyReq: credentials
		})

		if ('error' in response) throw response

		const { token, user } = response.data
		return { ...user, token }
	} catch (err) {
		throw err
	}
}

export const getMeService = async (token: string): Promise<User> => {
	try {
		const response = await customFetch<FetchResponse<IAMResponse>>(`${endpoints.me}`, {
			method: 'GET',
			token
		})
		if ('error' in response) throw response

		const { user } = response.data
		return {
			...user,
			token
		}
	} catch (err) {
		throw err
	}
}

export const registerService = async (user: Register): Promise<void> => {
	try {
		const response = await customFetch<FetchResponse<void>>(`${endpoints.register}`, {
			method: 'POST',
			bodyReq: user
		})
		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}

export const createUserByTypeService = async (user: UserPost): Promise<User> => {
	try {
		const token = getUserToken()
		const response = await customFetch<FetchResponse<User>>(`${endpoints.users}`, {
			method: 'POST',
			bodyReq: user,
			token
		})
		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const fetchUsersService = async () => {
	try {
		const token = getUserToken()
		const response = await customFetch<FetchResponse<User[]>>(`${endpoints.users}`, {
			method: 'GET',
			token
		})
		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const forgotPasswordService = async (email: string) => {
	try {
		const response = await customFetch<FetchResponse<void>>(`${endpoints.forgotPassword}`, {
			method: 'POST',
			bodyReq: { email }
		})
		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}

export const changePasswordService = async (password: string, token: string) => {
	try {
		const response = await customFetch<FetchResponse<void>>(`${endpoints.changePassword}`, {
			method: 'POST',
			bodyReq: { password, token }
		})
		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}

export const getUserByIdService = async (id: number) => {
	try {
		const token = getUserToken()
		const response = await customFetch<FetchResponse<User>>(`${endpoints.users}/${id}`, {
			method: 'GET',
			token
		})
		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const skipTourService = async () => {
	try {
		const userId = getUserId()
		const token = getUserToken()
		const response = await customFetch<FetchResponse<void>>(`${endpoints.users}/${userId}`, {
			method: 'PUT',
			token,
			bodyReq: { showTutorial: false }
		})
		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const createDriverAssociationsByUserService = async (
	userId: number,
	driverIds: number[]
) => {
	try {
		const token = getUserToken()
		const response = await customFetch<FetchResponse<DriverAssociations[]>>(
			`${endpoints.users}/driver-associations`,
			{
				method: 'POST',
				token,
				bodyReq: { userId, driverIds }
			}
		)
		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const createProviderAssociationsByUserService = async (
	userId: number,
	providerIds: number[]
) => {
	try {
		const token = getUserToken()
		const response = await customFetch<FetchResponse<ProviderAssociations[]>>(
			`${endpoints.users}/provider-associations`,
			{
				method: 'POST',
				token,
				bodyReq: { userId, providerIds }
			}
		)
		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateUserService = async (user: Partial<UserForm>, userId: number): Promise<User> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<User>>(`${endpoints.users}/${userId}`, {
			method: 'PATCH',
			token,
			bodyReq: user
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const changeMyPasswordService = async (newPassword: ChangeMyPasswordForm): Promise<User> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<User>>(`${endpoints.changeMyPassword}`, {
			method: 'PATCH',
			token,
			bodyReq: newPassword
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const deleteUserById = async (userId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(`${endpoints.users}/${userId}`, {
			method: 'DELETE',
			token
		})

		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}
