import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const BellIcon: FC<Props> = (props) => {
	const { width = 26, height = 26 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M22.5273 17.8689C21.9637 16.898 21.1258 14.1507 21.1258 10.5625C21.1258 8.40762 20.2698 6.34099 18.746 4.81726C17.2223 3.29352 15.1557 2.4375 13.0008 2.4375C10.8459 2.4375 8.77927 3.29352 7.25553 4.81726C5.7318 6.34099 4.87578 8.40762 4.87578 10.5625C4.87578 14.1517 4.03687 16.898 3.4732 17.8689C3.32925 18.1157 3.25294 18.3962 3.25196 18.6819C3.25098 18.9677 3.32536 19.2486 3.46761 19.4964C3.60986 19.7443 3.81493 19.9502 4.06216 20.0935C4.30939 20.2367 4.59003 20.3123 4.87578 20.3125H9.02054C9.208 21.2298 9.70652 22.0541 10.4318 22.6462C11.1571 23.2382 12.0645 23.5616 13.0008 23.5616C13.937 23.5616 14.8445 23.2382 15.5698 22.6462C16.295 22.0541 16.7936 21.2298 16.981 20.3125H21.1258C21.4114 20.3121 21.6919 20.2364 21.939 20.0931C22.1861 19.9497 22.3911 19.7438 22.5332 19.496C22.6753 19.2482 22.7496 18.9673 22.7486 18.6817C22.7475 18.396 22.6712 18.1157 22.5273 17.8689ZM13.0008 21.9375C12.4968 21.9373 12.0053 21.781 11.5939 21.49C11.1825 21.199 10.8714 20.7876 10.7034 20.3125H15.2981C15.1301 20.7876 14.819 21.199 14.4076 21.49C13.9962 21.781 13.5047 21.9373 13.0008 21.9375ZM4.87578 18.6875C5.65781 17.3428 6.50078 14.2269 6.50078 10.5625C6.50078 8.83859 7.1856 7.18529 8.40458 5.96631C9.62357 4.74732 11.2769 4.0625 13.0008 4.0625C14.7247 4.0625 16.378 4.74732 17.597 5.96631C18.816 7.18529 19.5008 8.83859 19.5008 10.5625C19.5008 14.2238 20.3417 17.3398 21.1258 18.6875H4.87578Z'
				fill={colors.blueMt}
			/>
		</svg>
	)
}

export default BellIcon
