import InputButton from 'components/elements/InputButton/InputButton'
import { Modal } from 'components/elements/Modal/Modal'
import { useModal } from 'hooks/useModal'
import { forwardRef, RefObject, useEffect, useRef, useState } from 'react'
import { emptyString } from 'utils/common'
import { handleGeneralKeyboardEvent } from 'utils/functions'
import './Input.scss'

interface Props {
	value: string | undefined
	id: string
	label: string
	onSelect: (time: string | undefined) => void
	error: string | undefined
}
const defaultTime = '12:00'

const InputTime = forwardRef<HTMLElement, Props>((props, ref) => {
	const { value, onSelect, id, label, error } = props

	const [modalIsVisible, toggleModalVisibility] = useModal()
	const [valueSelected, onChangeSelected] = useState<string | undefined>(value)

	const timeRefs = useRef<(HTMLDivElement | null)[]>([])

	const times = Array.from({ length: 24 * 12 }, (_, i) => {
		const hour = Math.floor(i / 12)
		const minute = (i % 12) * 5
		const hourString = hour.toString().padStart(2, '0')
		const minuteString = minute.toString().padStart(2, '0')
		return `${hourString}:${minuteString}`
	})

	const handleTimeSelect = (time: string | undefined) => {
		if (!time || !time.length) return
		onChangeSelected(time)
		onSelect(time)
		toggleModalVisibility()
	}

	useEffect(() => {
		onChangeSelected(value)
	}, [value])

	useEffect(() => {
		if (modalIsVisible) {
			const timeToScroll = valueSelected || defaultTime
			const index = times.indexOf(timeToScroll)
			if (!timeRefs.current[index]) return
			if (index >= 0 && index < times.length && timeRefs.current[index] instanceof HTMLElement) {
				timeRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		}
	}, [modalIsVisible, valueSelected])

	return (
		<>
			{!modalIsVisible ? (
				<div className={`input-time ${error ? 'error' : emptyString}`}>
					<label htmlFor={id}>
						{label}
						{/* <span className='optional'> ({t('general.optional')})</span> */}
					</label>
					<InputButton
						id={id}
						type='time'
						onClick={toggleModalVisibility}
						placeholder='HH:MM'
						text={value}
						error={error}
						ref={ref as RefObject<HTMLButtonElement>}
					/>
					{error && <span className='input-error-message'>{error}</span>}
				</div>
			) : null}

			<Modal
				isVisible={modalIsVisible}
				toggleVisibility={toggleModalVisibility}
				isFull
				showCloseButton={false}>
				<div className='hour-picker-container'>
					{times.map((time, index) => (
						<div
							key={index}
							ref={(el) => {
								timeRefs.current[index] = el
							}}
							className={valueSelected === time ? 'selected' : emptyString}
							onClick={() => handleTimeSelect(time)}
							onKeyDown={handleGeneralKeyboardEvent(() => handleTimeSelect(time))}>
							{time}
						</div>
					))}
				</div>
			</Modal>
		</>
	)
})

export default InputTime
