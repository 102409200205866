import Alert from 'components/elements/Alert/Alert'
import { FRIENDS_LIST_URL, TRANSFER_RECEIVE_URL, TRANSFER_REQUESTS_URL } from 'constants/routes'
import { FriendRequestReceived } from 'models/Friend'
import { SharedTransfer } from 'models/Transfer'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { currentUserIsAdmin } from 'utils/currentUser'

interface Props {
	pending: FriendRequestReceived[] | SharedTransfer[]
	isFriendRequest?: boolean
}

const ShareTransferAlertComponent: FC<Props> = (props) => {
	const { pending, isFriendRequest = false } = props
	const { t } = useTranslation()
	const navigate = useNavigate()
	const isAdmin = currentUserIsAdmin()

	const [message, setMessage] = useState<string>('')

	useEffect(() => {
		if (!pending) return
		if (!isAdmin) return

		if (isFriendRequest) {
			const message =
				pending.length === 1
					? t('friend.alert_one', { companyName: pending[0].sendingCompanyName })
					: t('friend.alert', { count: pending.length })
			setMessage(message)
		} else {
			const message =
				pending.length === 1
					? t('transfer_share.alert_one_shared', { companyName: pending[0].sendingCompanyName })
					: t('transfer_share.alert_multiple_shared', { count: pending.length })
			setMessage(message)
		}
	}, [pending])

	if (!pending?.length) return null

	const redirectTo = () => {
		if (isFriendRequest) {
			navigate(`${FRIENDS_LIST_URL}/requests/received`)
		} else {
			if (pending.length === 1) {
				return navigate(`${TRANSFER_RECEIVE_URL}/${pending[0].id}`)
			}
			navigate(`${TRANSFER_REQUESTS_URL}/received`)
		}
	}

	if (!message) return null

	return <Alert message={message} type='info' onClick={redirectTo} />
}

export default ShareTransferAlertComponent
