import Loading from 'components/elements/Loading/Loading'
import { LOGIN_URL, NOT_ALLOWED_URL, ONBOARDING_URL, TOUR_URL } from 'constants/routes'
import useCompany from 'hooks/useCompany'
import useConfigurations from 'hooks/useConfigurations'
import _ from 'lodash'
import { Permissions } from 'models/Configurations'
import { AllUserRoles, UserRoles } from 'models/User'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useUserStore } from 'stores/index'
import { useLoadingStore } from 'stores/useLoadingStore'

type PrivateRouteProps = {
	component: React.ComponentType
	roles?: Array<AllUserRoles>
	permissionsRequired?: Permissions[]
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
	const { component: Component, roles, permissionsRequired = [], ...rest } = props

	const location = useLocation()
	const userStore = useUserStore()
	const loadingStore = useLoadingStore()
	const { company, loading } = useCompany()
	const { isConfigurationActive } = useConfigurations()
	const currentUser = userStore.currentUser

	const isLogged = !_.isEmpty(currentUser)

	if (!isLogged) {
		return <Navigate to={LOGIN_URL} />
	}

	if (loadingStore.isLoading || loading) {
		return <Loading fullscreen />
	}

	if (!company) {
		return null
	}

	const missingPermissions =
		permissionsRequired.length > 0 &&
		permissionsRequired.some((permission) => !isConfigurationActive(permission))

	if (missingPermissions) {
		return <Navigate to={NOT_ALLOWED_URL} />
	}

	let roleMatch = roles ? roles?.includes(currentUser.userTypeId) : true

	if (currentUser.userTypeId === UserRoles['super-admin']) {
		roleMatch = true
	}

	if (!roleMatch) {
		return <Navigate to={NOT_ALLOWED_URL} />
	}

	const onTourPage = location.pathname === TOUR_URL
	const firstSteps = location.pathname.includes(ONBOARDING_URL)
	if (!onTourPage && currentUser.showTutorial) {
		return <Navigate to={TOUR_URL} />
	}
	if (
		!onTourPage &&
		!firstSteps &&
		(!company.hasVehicles || !company.hasDrivers || !company.hasProviders) &&
		company.hasTransfers === false
	) {
		return <Navigate to={ONBOARDING_URL} />
	}

	return <Component {...rest} />
}

export default PrivateRoute
