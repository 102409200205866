import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const WhatsappIcon: FC<Props> = (props) => {
	const { width = 32, height = 32 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_342_15715)'>
				<path
					d='M0.683437 15.809C0.682687 18.4977 1.39069 21.1229 2.73694 23.4368L0.554688 31.3428L8.70869 29.2214C10.964 30.4396 13.4909 31.078 16.0587 31.0781H16.0654C24.5423 31.0781 31.4427 24.2337 31.4463 15.8211C31.4479 11.7445 29.8494 7.91125 26.9452 5.02728C24.0414 2.14356 20.1796 0.554595 16.0648 0.552734C7.58694 0.552734 0.687063 7.39677 0.683563 15.809'
					fill='url(#paint0_linear_342_15715)'
				/>
				<path
					d='M0.13375 15.8035C0.132875 18.5889 0.86625 21.308 2.2605 23.7048L0 31.8942L8.44637 29.6967C10.7736 30.9558 13.3939 31.6196 16.0601 31.6206H16.067C24.848 31.6206 31.9963 24.53 32 15.8162C32.0015 11.5932 30.3455 7.62208 27.3375 4.63479C24.3291 1.64788 20.3291 0.00173643 16.067 0C7.2845 0 0.13725 7.08961 0.13375 15.8035ZM5.16375 23.292L4.84837 22.7953C3.52262 20.7036 2.82288 18.2865 2.82387 15.8045C2.82675 8.56174 8.76725 2.66915 16.072 2.66915C19.6095 2.67064 22.934 4.03895 25.4345 6.52155C27.9349 9.0044 29.3108 12.3049 29.3099 15.8152C29.3066 23.058 23.366 28.9513 16.067 28.9513H16.0618C13.6851 28.9501 11.3542 28.3168 9.3215 27.12L8.83775 26.8353L3.8255 28.1393L5.16375 23.2919V23.292Z'
					fill='url(#paint1_linear_342_15715)'
				/>
				<path
					d='M12.0864 9.19759C11.7882 8.53986 11.4743 8.52658 11.1907 8.51505C10.9584 8.50513 10.6929 8.50587 10.4277 8.50587C10.1622 8.50587 9.73078 8.60497 9.36616 9.00001C9.00116 9.39542 7.97266 10.351 7.97266 12.2944C7.97266 14.238 9.39928 16.1162 9.59816 16.38C9.79728 16.6433 12.3523 20.7591 16.3988 22.3425C19.7618 23.6584 20.4462 23.3967 21.176 23.3307C21.906 23.2649 23.5315 22.3754 23.8632 21.453C24.195 20.5307 24.195 19.7401 24.0955 19.5749C23.996 19.4103 23.7305 19.3115 23.3324 19.114C22.9342 18.9164 20.9769 17.9608 20.612 17.8289C20.247 17.6972 19.9817 17.6315 19.7162 18.027C19.4507 18.4219 18.6883 19.3115 18.4559 19.5749C18.2238 19.839 17.9914 19.8718 17.5934 19.6742C17.195 19.476 15.9129 19.0594 14.3918 17.7138C13.2083 16.6667 12.4093 15.3737 12.177 14.9782C11.9448 14.5833 12.1522 14.3692 12.3518 14.1724C12.5307 13.9954 12.75 13.7111 12.9493 13.4805C13.1478 13.2498 13.214 13.0852 13.3468 12.8218C13.4797 12.5581 13.4132 12.3274 13.3138 12.1298C13.214 11.9322 12.4404 9.97862 12.0864 9.19759Z'
					fill='white'
				/>
			</g>
			<defs>
				<linearGradient
					id='paint0_linear_342_15715'
					x1='1545.14'
					y1='3079.56'
					x2='1545.14'
					y2='0.552734'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#1FAF38' />
					<stop offset='1' stopColor={colors.whatsappGreen} />
				</linearGradient>
				<linearGradient
					id='paint1_linear_342_15715'
					x1='1600'
					y1='3189.42'
					x2='1600'
					y2='0'
					gradientUnits='userSpaceOnUse'>
					<stop stopColor='#F9F9F9' />
					<stop offset='1' stopColor='white' />
				</linearGradient>
				<clipPath id='clip0_342_15715'>
					<rect width='32' height='32' fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default WhatsappIcon
