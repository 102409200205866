import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const ClockIcon: FC<Props> = (props) => {
	const { width = 26, height = 26 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M13 2.4375C10.9109 2.4375 8.86879 3.05698 7.13179 4.2176C5.3948 5.37822 4.04098 7.02786 3.24153 8.95791C2.44208 10.8879 2.2329 13.0117 2.64046 15.0606C3.04802 17.1096 4.054 18.9916 5.53119 20.4688C7.00838 21.946 8.89044 22.952 10.9394 23.3595C12.9883 23.7671 15.1121 23.5579 17.0421 22.7585C18.9721 21.959 20.6218 20.6052 21.7824 18.8682C22.943 17.1312 23.5625 15.0891 23.5625 13C23.5595 10.1996 22.4458 7.51466 20.4656 5.53445C18.4853 3.55424 15.8004 2.44046 13 2.4375ZM13 21.9375C11.2323 21.9375 9.50436 21.4133 8.0346 20.4313C6.56483 19.4492 5.41929 18.0533 4.74283 16.4202C4.06637 14.7871 3.88938 12.9901 4.23424 11.2564C4.57909 9.52268 5.43031 7.93016 6.68024 6.68023C7.93017 5.4303 9.52268 4.57909 11.2564 4.23423C12.9901 3.88938 14.7871 4.06637 16.4202 4.74283C18.0534 5.41928 19.4492 6.56483 20.4313 8.03459C21.4133 9.50435 21.9375 11.2323 21.9375 13C21.9348 15.3695 20.9923 17.6413 19.3168 19.3168C17.6413 20.9923 15.3696 21.9348 13 21.9375ZM19.5 13C19.5 13.2155 19.4144 13.4222 19.262 13.5745C19.1097 13.7269 18.903 13.8125 18.6875 13.8125H13C12.7845 13.8125 12.5779 13.7269 12.4255 13.5745C12.2731 13.4222 12.1875 13.2155 12.1875 13V7.3125C12.1875 7.09701 12.2731 6.89035 12.4255 6.73798C12.5779 6.5856 12.7845 6.5 13 6.5C13.2155 6.5 13.4222 6.5856 13.5745 6.73798C13.7269 6.89035 13.8125 7.09701 13.8125 7.3125V12.1875H18.6875C18.903 12.1875 19.1097 12.2731 19.262 12.4255C19.4144 12.5778 19.5 12.7845 19.5 13Z'
				fill={colors.violetMt}
			/>
		</svg>
	)
}

export default ClockIcon
