import { useTranslation } from 'react-i18next'

import { CalendarBlank, CommunicatedFomento, CreateTransfer, Eye, FileSearch } from 'assets/svgs'
import ActionButton from 'components/elements/ActionButton/ActionButton'

import SuccessMenu from 'components/elements/SuccessMenu/SuccessMenu'
import {
	CREATE_URL,
	DAILY_URL,
	FORM_URL,
	TRANSFER_FOMENTO_URL,
	TRANSFER_PDF_URL,
	TRANSFER_STEPS,
	TRANSFER_URL
} from 'constants/routes'
import useToast from 'hooks/useToast'
import { useTransferDetails } from 'hooks/useTransferDetails'
import { useEffect, useState } from 'react'
import { ErrorResponse, useNavigate, useParams } from 'react-router-dom'
import { useFomentoStore } from 'stores/useFomentoStore'
import { emptyString } from 'utils/common'
import colors from 'constants/colors'

const StepEnd = () => {
	const navigate = useNavigate()
	const { transferId } = useParams()
	const { t } = useTranslation()
	const fomentoState = useFomentoStore()
	const { showBackendErrorToast } = useToast()
	const [isFomentoCompleted, setIsFomentoCompleted] = useState<boolean>(false)
	const transferIdNumber = transferId ? parseInt(transferId) : null
	const { transfer } = useTransferDetails(transferIdNumber!)

	const handleFomento = async () => {
		if (!transferId) return
		try {
			await fomentoState.communicate(parseInt(transferId))
			navigate(`${TRANSFER_FOMENTO_URL}/${transferId}`)
		} catch (err) {
			const error = err as ErrorResponse
			const errorCode = error.status
			showBackendErrorToast(err)
			if (errorCode === 400) {
				await fomentoState.fetchFomentoInfoByBookingId(parseInt(transferId))
				navigate(`${TRANSFER_FOMENTO_URL}/${transferId}${FORM_URL}`)
			}
		}
	}

	useEffect(() => {
		const isCompleted = fomentoState.actualFomentoCreation !== null
		setIsFomentoCompleted(isCompleted)
	}, [fomentoState.actualFomentoCreation])

	return (
		<SuccessMenu
			title={`${t('transfer.success_create')} ${transferId ? `[${transferId}]` : emptyString}`}>
			<ActionButton
				icon={<Eye />}
				backGroundIcon={colors.lightBlueMt}
				onClick={() => navigate(`${TRANSFER_URL}/${transferId}`)}>
				{t('transfer.create_steps.step_end.buttons.view_transfer')}
			</ActionButton>
			<ActionButton
				icon={<CommunicatedFomento width={30} height={30} fill='#fff' />}
				backGroundIcon={colors.violetMt}
				onClick={() => handleFomento()}>
				{t('transfer.create_steps.step_end.buttons.communicate_fomento')}
			</ActionButton>
			{!isFomentoCompleted && <p>{t('transfer.create_steps.step_end.extra_info')}</p>}
			<ActionButton
				icon={<CreateTransfer />}
				backGroundIcon={colors.blueMt}
				onClick={() =>
					navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.ROUTE.toLocaleLowerCase()}`)
				}>
				{t('transfer.create_steps.step_end.buttons.create_transfer')}
			</ActionButton>
			<ActionButton
				icon={<CalendarBlank />}
				backGroundIcon={colors.shinyBlue}
				onClick={() => navigate(`${DAILY_URL}/${transfer?.date}`)}>
				{t('transfer.create_steps.step_end.buttons.view_daily')}
			</ActionButton>
			<ActionButton
				icon={<FileSearch />}
				backGroundIcon={colors.shinyViolet}
				onClick={() => navigate(`${TRANSFER_PDF_URL}/${transferId}`)}>
				{t('transfer.create_steps.step_end.buttons.view_contract')}
			</ActionButton>
		</SuccessMenu>
	)
}

export default StepEnd
