import InputDate from 'components/elements/Input/InputDate'
import InputTime from 'components/elements/Input/InputTime'
import { FormikProps } from 'formik'
import { AllStepForms, AllStepFormsWithFomentoForm, StepAddressesForm } from 'models/Transfer'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik:
		| FormikProps<StepAddressesForm>
		| FormikProps<AllStepForms>
		| FormikProps<AllStepFormsWithFomentoForm>

	setFieldRef?: (field: string) => (ref: HTMLInputElement) => void
}

const StepBookingDateInputs: FC<Props> = (props) => {
	const { formik, setFieldRef } = props

	const { t } = useTranslation()

	return (
		<>
			<InputDate
				value={formik.values.date}
				id='date'
				label={t('transfer.create_steps.step_addresses.label.booking_date')}
				onSelect={(date) => formik.setFieldValue('date', date)}
				error={getFormikError(formik as FormikProps<StepAddressesForm>, 'date')}
				ref={setFieldRef ? setFieldRef('date') : undefined}
			/>
			<InputTime
				value={formik.values.time?.substring(0, 5)}
				onSelect={(time) => {
					formik.setFieldValue('time', time)
				}}
				id='time'
				label={t('transfer.create_steps.step_addresses.label.booking_time')}
				error={getFormikError(formik as FormikProps<StepAddressesForm>, 'time')}
				ref={setFieldRef ? setFieldRef('time') : undefined}
			/>
		</>
	)
}

export default StepBookingDateInputs
