import TextArea from 'components/elements/TextArea/TextArea'
import { FormikProps } from 'formik'
import useConfigurations from 'hooks/useConfigurations'
import { Permissions } from 'models/Configurations'
import { AllStepForms, AllStepFormsWithFomentoForm, StepObservationsForm } from 'models/Transfer'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	formik:
		| FormikProps<StepObservationsForm>
		| FormikProps<AllStepForms>
		| FormikProps<AllStepFormsWithFomentoForm>
}

const StepObservationsInputs: FC<Props> = (props) => {
	const { formik } = props

	const { t } = useTranslation()

	const { isConfigurationActive } = useConfigurations()

	return (
		<>
			<TextArea
				valueSelected={formik.values['observations']}
				id='observations'
				label={t('transfer.create_steps.step_observations.inputs.observations.label')}
				onChange={formik.handleChange}
				rows={5}
				placeholder={t('transfer.create_steps.step_observations.inputs.observations.placeholder')}
			/>
			{isConfigurationActive(Permissions.PRIVATE_NOTES) && (
				<TextArea
					valueSelected={formik.values['privateObservations']}
					id='privateObservations'
					label={t('transfer.create_steps.step_observations.inputs.private_observations.label')}
					onChange={formik.handleChange}
					rows={5}
					placeholder={t(
						'transfer.create_steps.step_observations.inputs.private_observations.placeholder'
					)}
				/>
			)}
		</>
	)
}

export default StepObservationsInputs
