import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CreateIcon: FC<Props> = (props) => {
	const { width = 65, height = 72 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 65 72'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g filter='url(#filter0_d_28_1487)'>
				<path
					d='M56.75 32.375C56.75 37.1959 55.3204 41.9086 52.6421 45.917C49.9637 49.9255 46.1569 53.0497 41.7029 54.8946C37.249 56.7394 32.348 57.2222 27.6197 56.2816C22.8914 55.3411 18.5482 53.0196 15.1393 49.6107C11.7304 46.2018 9.40888 41.8586 8.46837 37.1303C7.52785 32.402 8.01056 27.501 9.85545 23.0471C11.7003 18.5931 14.8245 14.7863 18.833 12.1079C22.8414 9.42957 27.5541 8 32.375 8C38.8397 8 45.0395 10.5681 49.6107 15.1393C54.1819 19.7105 56.75 25.9103 56.75 32.375Z'
					fill={colors.blueMt}
				/>
			</g>
			<path
				d='M44.5 32C44.5 32.3978 44.342 32.7794 44.0607 33.0607C43.7794 33.342 43.3978 33.5 43 33.5H33.5V43C33.5 43.3978 33.342 43.7794 33.0607 44.0607C32.7794 44.342 32.3978 44.5 32 44.5C31.6022 44.5 31.2206 44.342 30.9393 44.0607C30.658 43.7794 30.5 43.3978 30.5 43V33.5H21C20.6022 33.5 20.2206 33.342 19.9393 33.0607C19.658 32.7794 19.5 32.3978 19.5 32C19.5 31.6022 19.658 31.2206 19.9393 30.9393C20.2206 30.658 20.6022 30.5 21 30.5H30.5V21C30.5 20.6022 30.658 20.2206 30.9393 19.9393C31.2206 19.658 31.6022 19.5 32 19.5C32.3978 19.5 32.7794 19.658 33.0607 19.9393C33.342 20.2206 33.5 20.6022 33.5 21V30.5H43C43.3978 30.5 43.7794 30.658 44.0607 30.9393C44.342 31.2206 44.5 31.6022 44.5 32Z'
				fill='white'
			/>
			<defs>
				<filter
					id='filter0_d_28_1487'
					x='0.7'
					y='7.7'
					width='63.35'
					height='63.35'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='7' />
					<feGaussianBlur stdDeviation='3.65' />
					<feComposite in2='hardAlpha' operator='out' />
					<feColorMatrix
						type='matrix'
						values='0 0 0 0 0.937255 0 0 0 0 0.921569 0 0 0 0 0.988235 0 0 0 1 0'
					/>
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_28_1487' />
					<feBlend
						mode='normal'
						in='SourceGraphic'
						in2='effect1_dropShadow_28_1487'
						result='shape'
					/>
				</filter>
			</defs>
		</svg>
	)
}

export default CreateIcon
