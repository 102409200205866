import AddressInput from 'components/elements/Input/AddressInput'
import Input from 'components/elements/Input/Input'
import { FormikProps } from 'formik'
import {
	AllStepForms,
	AllStepFormsWithFomentoForm,
	RouteTypeName,
	StepAddressesForm
} from 'models/Transfer'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getTransferPreviousUsedAddresses } from 'services/transfers'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik:
		| FormikProps<StepAddressesForm>
		| FormikProps<AllStepForms>
		| FormikProps<AllStepFormsWithFomentoForm>
	route?: keyof typeof RouteTypeName
	setFieldRef?: (field: string) => (ref: HTMLInputElement) => void
}

interface Option {
	label: string
	value: string
}

const StepAddressesInputs: FC<Props> = (props) => {
	const { formik, route, setFieldRef } = props
	const [showFlight, setShowFlight] = useState(route === RouteTypeName.arrival)
	const [options, setOptions] = useState<Option[]>([])

	const { t } = useTranslation()

	useEffect(() => {
		if (route === RouteTypeName.arrival) {
			setShowFlight(true)
		} else {
			setShowFlight(false)
		}
	}, [route])

	useEffect(() => {
		const fetchOptions = async () => {
			try {
				const response = await getTransferPreviousUsedAddresses()
				const formattedOptions = (response || []).map((address) => ({
					label: address,
					value: address
				}))
				setOptions(formattedOptions)
			} catch (error) {
				console.error('Error fetching options:', error)
			}
		}
		fetchOptions()
	}, [])

	const originAddressError = getFormikError(
		formik as FormikProps<StepAddressesForm>,
		'originAddress'
	)
	const destinationAddressError = getFormikError(
		formik as FormikProps<StepAddressesForm>,
		'destinationAddress'
	)

	return (
		<>
			<AddressInput
				id='originAddress'
				label={t('transfer.create_steps.step_addresses.label.origin')}
				options={options}
				placeholder={t('transfer.create_steps.step_addresses.placeholders.origin')}
				formik={formik}
				fieldName='originAddress'
				error={originAddressError}
				ref={setFieldRef ? setFieldRef('originAddress') : undefined}
			/>
			<AddressInput
				id='destinationAddress'
				label={t('transfer.destination')}
				options={options}
				placeholder={t('transfer.create_steps.step_addresses.placeholders.destination')}
				formik={formik}
				fieldName='destinationAddress'
				error={destinationAddressError}
				ref={setFieldRef ? setFieldRef('destinationAddress') : undefined}
			/>

			{showFlight && (
				<Input
					type='text'
					label={t('transfer.flight')}
					placeholder={t('transfer.create_steps.step_addresses.placeholders.flight')}
					id='flight'
					onChange={(e) => formik.setFieldValue('flight', e.target.value)}
					className={formik.values['flight'] ? 'selected' : 'not-selected'}
					valueSelected={formik.values['flight']}
					error={getFormikError(formik as FormikProps<StepAddressesForm>, 'flight')}
				/>
			)}
		</>
	)
}

export default StepAddressesInputs
