import BellIcon from 'assets/svgs/BellIcon'
import ButtonComponent from 'components/elements/Button/Button'
import { NOTIFICATIONS_URL } from 'constants/routes'
import useNotifications from 'hooks/useNotification'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import './HeaderWithNotificationBell.scss'

interface Props {
	title: string
	subtitle?: string
}

const HeaderWithNotificationBell: React.FC<Props> = (props) => {
	const { title, subtitle } = props

	const navigate = useNavigate()
	const { unReadNotifications } = useNotifications()

	return (
		<header className={`header-with-notification-bell${subtitle ? ' hasSubtitle' : emptyString}`}>
			<div className='header-with-notification-bell-wrapper'>
				<h1>{title}</h1>
				{subtitle && <h2>{subtitle}</h2>}
			</div>
			<ButtonComponent
				variant='only-icon'
				icon={
					<div className='bell-icon-wrapper'>
						<BellIcon />
						{unReadNotifications?.length > 0 && (
							<span className='notification-badge'>{unReadNotifications?.length}</span>
						)}
					</div>
				}
				onClick={() => navigate(NOTIFICATIONS_URL)}
			/>
		</header>
	)
}

export default HeaderWithNotificationBell
