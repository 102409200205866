import CheckboxInput from 'components/elements/Input/CheckboxInput'
import Input from 'components/elements/Input/Input'
import InputDate from 'components/elements/Input/InputDate'
import SelectMunicipalityOptions from 'components/elements/SelectOptions/SelectMunicipalityOptions'
import SelectProvinceOptions from 'components/elements/SelectOptions/SelectProvinceOptions'
import { FormikProps } from 'formik'
import useCompany from 'hooks/useCompany'
import useFomento from 'hooks/useFomento'
import {
	AllStepFormsWithFomentoForm,
	FomentoInfo,
	FomentoPost,
	StepFomentoForm
} from 'models/Transfer'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateToSQL } from 'utils/dateUtils'

interface Props {
	formik: FormikProps<StepFomentoForm> | FormikProps<AllStepFormsWithFomentoForm>
	fomentoData?: FomentoPost | FomentoInfo | null
}

const StepFomentoInputs: FC<Props> = ({ formik, fomentoData }) => {
	const { t } = useTranslation()
	const { company } = useCompany()
	const {
		getProvinceCode,
		getProvinceByCodeHelper,
		getMunicipalityByProvinceCodeAndMunicipalityCodeHelper
	} = useFomento()
	const [hasShowDistant, setHasShowDistant] = useState<boolean>(false)

	useEffect(() => {
		if (!company) return
		const provinceOriginId = fomentoData?.provinceOrigin
			? getProvinceByCodeHelper(fomentoData.provinceOrigin)
			: getProvinceByCodeHelper(company.province)
		formik.setFieldValue('provinceOriginId', provinceOriginId)
		const provinceDestinationId = fomentoData?.provinceDestination
			? getProvinceByCodeHelper(fomentoData.provinceDestination)
			: getProvinceByCodeHelper(company.province)
		formik.setFieldValue('provinceDestinationId', provinceDestinationId)

		if (!fomentoData) return
		formik.setFieldValue(
			'municipalityOriginId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				fomentoData.provinceOrigin,
				fomentoData.municipalityOrigin
			)
		)
		formik.setFieldValue(
			'municipalityDestinationId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				fomentoData.provinceDestination,
				fomentoData.municipalityDestination
			)
		)
		formik.setFieldValue('provinceDistantId', getProvinceByCodeHelper(fomentoData.provinceDistant))
		formik.setFieldValue(
			'municipalityDistantId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				fomentoData.provinceDistant,
				fomentoData.municipalityDistant
			)
		)
		formik.setFieldValue('destinationFreely', fomentoData.destinationFreely)
		formik.setFieldValue('addressDistant', fomentoData.addressDistant)

		if (fomentoData.finishedAt) formik.setFieldValue('finishedAt', fomentoData.finishedAt)
	}, [fomentoData, company])

	useEffect(() => {
		const hasShow = hasToShowDistant()
		setHasShowDistant(hasShow)
	}, [
		formik.values['municipalityOriginId'],
		formik.values['municipalityDestinationId'],
		formik.values['provinceOriginId'],
		formik.values['provinceDestinationId']
	])

	const hasToShowDistant = () => {
		if (!formik.values['municipalityOriginId'] || !formik.values['municipalityDestinationId']) {
			return false
		}
		return formik.values['municipalityOriginId'] === formik.values['municipalityDestinationId']
	}

	return (
		<>
			<InputDate
				id='finishedAt'
				label={t('transfer.create_steps.step_fomento.selectors.finishedAt.label')}
				value={formik.values['finishedAt'] ? new Date(formik.values['finishedAt']) : undefined}
				onSelect={(date) => formik.setFieldValue('finishedAt', dateToSQL(date))}
			/>
			<CheckboxInput
				label={t('transfer.create_steps.step_fomento.free_text')}
				id='destinationFreely'
				onChange={(e) => formik.setFieldValue('destinationFreely', e.target.checked)}
				checked={formik.values['destinationFreely']}
				styles={{ margin: '1rem 0' }}
			/>
			<SelectProvinceOptions
				onSelect={(provinceId) => {
					if (provinceId !== formik.values['provinceOriginId']) {
						formik.setFieldValue('provinceOriginId', provinceId)
						formik.setFieldValue('municipalityOriginId', null)
					}
				}}
				id='provinceOriginId'
				selectedId={formik.values['provinceOriginId']}
				label={t('transfer.create_steps.step_fomento.selectors.province.label.origin')}
				placeholder={t('transfer.create_steps.step_fomento.selectors.province.placeholders.origin')}
			/>
			<SelectMunicipalityOptions
				onSelect={(municipalityId) => formik.setFieldValue('municipalityOriginId', municipalityId)}
				id='municipalityOriginId'
				selectedId={formik.values['municipalityOriginId']}
				provinceSelected={getProvinceCode(formik.values['provinceOriginId'])}
				label={t('transfer.create_steps.step_fomento.selectors.municipality.label.origin')}
				placeholder={t(
					'transfer.create_steps.step_fomento.selectors.municipality.placeholders.origin'
				)}
			/>
			<SelectProvinceOptions
				onSelect={(provinceId) => {
					if (provinceId !== formik.values['provinceDestinationId']) {
						formik.setFieldValue('provinceDestinationId', provinceId)
						formik.setFieldValue('municipalityDestinationId', null)
					}
				}}
				id='provinceDestinationId'
				selectedId={formik.values['provinceDestinationId']}
				label={t('transfer.create_steps.step_fomento.selectors.province.label.destination')}
				placeholder={t(
					'transfer.create_steps.step_fomento.selectors.province.placeholders.destination'
				)}
				isLabelOptional={formik.values['destinationFreely']}
			/>
			<SelectMunicipalityOptions
				onSelect={(municipalityId) =>
					formik.setFieldValue('municipalityDestinationId', municipalityId)
				}
				id='municipalityDestinationId'
				selectedId={formik.values['municipalityDestinationId']}
				provinceSelected={getProvinceCode(formik.values['provinceDestinationId'])}
				label={t('transfer.create_steps.step_fomento.selectors.municipality.label.destination')}
				placeholder={t(
					'transfer.create_steps.step_fomento.selectors.municipality.placeholders.destination'
				)}
				isLabelOptional={formik.values['destinationFreely']}
			/>
			{hasShowDistant && (
				<>
					<p>{t('transfer.create_steps.step_fomento.p')}</p>
					<SelectProvinceOptions
						onSelect={(provinceId) => {
							if (provinceId !== formik.values['provinceDistantId']) {
								formik.setFieldValue('provinceDistantId', provinceId)
								formik.setFieldValue('municipalityDistantId', null)
							}
						}}
						id='provinceDistantId'
						selectedId={formik.values['provinceDistantId']}
						label={t('transfer.create_steps.step_fomento.selectors.province.label.afar')}
						placeholder={t(
							'transfer.create_steps.step_fomento.selectors.province.placeholders.afar'
						)}
					/>
					<SelectMunicipalityOptions
						onSelect={(municipalityId) =>
							formik.setFieldValue('municipalityDistantId', municipalityId)
						}
						id='municipalityDistantId'
						selectedId={formik.values['municipalityDistantId']}
						provinceSelected={getProvinceCode(formik.values['provinceDistantId'])}
						label={t('transfer.create_steps.step_fomento.selectors.municipality.label.afar')}
						placeholder={t(
							'transfer.create_steps.step_fomento.selectors.municipality.placeholders.afar'
						)}
					/>
					<Input
						type='text'
						label={t('transfer.create_steps.step_fomento.selectors.address.label.afar')}
						placeholder={t(
							'transfer.create_steps.step_fomento.selectors.address.placeholders.afar'
						)}
						id='addressDistant'
						onChange={(e) => formik.setFieldValue('addressDistant', e.target.value)}
						className={formik.values['addressDistant'] ? 'selected' : 'not-selected'}
						valueSelected={formik.values['addressDistant']}
					/>
				</>
			)}
		</>
	)
}

export default StepFomentoInputs
