import VehicleInputs from 'components/Resources/Vehicle/VehicleInputs'
import ButtonComponent from 'components/elements/Button/Button'
import StepNumber from 'components/elements/StepNumber/StepNumber'
import { ONBOARDING_END_URL, ONBOARDING_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import useVehicle from 'hooks/useVehicle'
import useVehicles from 'hooks/useVehicles'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { VehicleForm, VehiclePost } from 'models/Vehicle'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString } from 'utils/common'
import * as Yup from 'yup'
import { ONBOARDING_FORM_PROGRESS, ONBOARDING_STEP } from './FirstSteps'
import './FirstSteps.scss'
import FirstStepsCounter from './FirstStepsCounter'

const CreateFirstVehicle: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const configStore = useConfigStore()
	const { vehicles } = useVehicles()
	const { createVehicle } = useVehicle()

	const [loading, setLoading] = useState(false)

	const formik = useFormik<VehicleForm>({
		initialValues: {
			plate: emptyString,
			brand: emptyString,
			model: emptyString,
			classId: null,
			isActive: true
		},
		validationSchema: Yup.object().shape({
			plate: Yup.string()
				.transform((value) => value.toUpperCase())
				.matches(/^\d{4}-[A-Z]{3}$/, t('errors.plate_format'))
				.required(t('errors.required_m', { field: t('vehicle.plate') })),
			brand: Yup.string().required(t('errors.required_m', { field: t('vehicle.brand') })),
			model: Yup.string().required(t('errors.required_m', { field: t('vehicle.model') })),
			classId: Yup.string().required(t('errors.required_m', { field: t('vehicle.class') })),
			isActive: Yup.boolean().required(t('errors.required_m', { field: t('vehicle.is_active') }))
		}),
		onSubmit: async (values) => {
			if (!values.classId) return
			const vehicle: VehiclePost = {
				plate: values.plate,
				brand: values.brand,
				model: values.model,
				class: configStore.vehicleClasses.byId[values.classId].name,
				isActive: values.isActive
			}
			try {
				setLoading(true)
				await createVehicle(vehicle)
				toast.success(t('vehicle.success_create'))
				navigate(ONBOARDING_END_URL)
			} catch (error) {
				toast.error(t('provider.error_create'))
			} finally {
				setLoading(false)
			}
		}
	})

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	useEffect(() => {
		const isComplete = vehicles.length

		if (isComplete) {
			navigate(ONBOARDING_URL)
		}
	}, [vehicles])

	return (
		<FormLayout
			title={t('onboarding.first_steps.form_title')}
			progress={ONBOARDING_FORM_PROGRESS[ONBOARDING_STEP.FOURTH]}
			leftButton={
				<ButtonComponent variant='text' onClick={() => navigate(ONBOARDING_URL)}>
					{t('general.quit')}
				</ButtonComponent>
			}
			rightButton={<FirstStepsCounter step={ONBOARDING_STEP.FOURTH} />}
			footer={
				<ButtonComponent onClick={handleSubmit} fullWidth loading={loading}>
					{t('onboarding.first_steps.save_and_finish_process')}
				</ButtonComponent>
			}>
			<FormSectionLayout
				title={t('onboarding.first_steps.create_first_vehicle')}
				titleIcon={<StepNumber number={ONBOARDING_STEP.FOURTH} size='large' />}
				noDivider>
				<p className='first-steps-form-description'>
					{t('onboarding.first_steps.create_first_vehicle_description')}
				</p>
			</FormSectionLayout>
			<VehicleInputs formik={formik} setFieldRef={setFieldRef} />
		</FormLayout>
	)
}

export default CreateFirstVehicle
