import { emptyString } from 'utils/common'
import './ListItem.scss'

interface Props {
	children: React.ReactNode
	left?: React.ReactNode
	right?: React.ReactNode
	onClick?: () => void
	isSelectedItem?: boolean
}

const ListItem = (props: Props) => {
	const { children, left, right, onClick, isSelectedItem = false } = props

	return (
		<div
			className={`list-item${onClick ? ' cursor-pointer' : emptyString}`}
			onClick={onClick}
			data-testid='list-item'>
			<div className='list-item-left'>
				{left && <div className='list-item-side'>{left}</div>}
				<div className={`list-item-children ${isSelectedItem ? 'selected-item' : emptyString}`}>
					{children}
				</div>
			</div>
			{right && <div className='list-item-side'>{right}</div>}
		</div>
	)
}

export default ListItem
