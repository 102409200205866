import { SharedTransfer } from 'assets/svgs'
import { SharedTransfer as SharedTransferType } from 'models/Transfer'
import './CategoryTag.scss'

interface Props {
	accepted?: SharedTransferType
	small?: boolean
}

const SharedTransferTag: React.FC<Props> = (props) => {
	const { accepted, small = false } = props

	if (!accepted) {
		return null
	}

	return (
		<div className='shared-transfer-category-tag'>
			<SharedTransfer />
			{!small && <span>{accepted.receivingCompanyName}</span>}
		</div>
	)
}

export default SharedTransferTag
