import AssociationList, {
	AssociationOption
} from 'components/elements/AssociationList/AssociationList'
import ButtonComponent from 'components/elements/Button/Button'
import { FRIENDS_LIST_URL, PROVIDERS_NEW_URL } from 'constants/routes'
import useFriends from 'hooks/useFriends'
import useProviders from 'hooks/useProviders'
import useToast from 'hooks/useToast'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { Provider } from 'models/Provider'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import './FriendAssociation.scss'

const enum AssociationType {
	send = 'send',
	receive = 'receive'
}

type NewProviderType = {
	id: Provider['id']
	name: Provider['name']
}

interface LocationState {
	type: AssociationType
	requestId?: number
	companyId?: number
	newProvider?: NewProviderType
}

const FriendAssociation: FC = () => {
	const location = useLocation()
	const { type, requestId, companyId, newProvider } = location?.state as LocationState
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { providers } = useProviders()
	const [selectedProvider, setSelectedProvider] = useState<AssociationOption>()
	const [associationsOptions, setAssociationsOptions] = useState<AssociationOption[]>([])
	const [isLoading, setIsLoading] = useState(true)
	const { acceptFriendRequest, sendFriendRequest } = useFriends()
	const { showErrorToast, showBackendErrorToast } = useToast()

	const formatAssociations = (): AssociationOption[] => {
		return providers.map((provider) => {
			return {
				id: provider.id,
				name: provider.name,
				isSelected: newProvider ? provider.id === newProvider.id : false
			}
		})
	}

	useEffect(() => {
		if (type !== AssociationType.send && type !== AssociationType.receive) {
			navigate(FRIENDS_LIST_URL)
		}
	}, [type])

	useEffect(() => {
		setIsLoading(true)
		const associations = formatAssociations()
		setAssociationsOptions(associations)
		setIsLoading(false)
	}, [providers])

	useEffect(() => {
		if (newProvider) {
			setSelectedProvider({
				id: newProvider.id,
				name: newProvider.name,
				isSelected: true
			})
		}
	}, [newProvider])

	const onSave = async (selected?: AssociationOption) => {
		if (!selected)
			return showErrorToast({
				description: t('friend.friend_search_page.request_friendship_error')
			})
		try {
			if (type === AssociationType.send && companyId) {
				await sendFriendRequest({ companyId, sendingProviderId: selected.id })
				navigate(FRIENDS_LIST_URL)
			}

			if (type === AssociationType.receive && requestId) {
				await acceptFriendRequest({ requestId, sendingProviderId: selected.id })
				navigate(FRIENDS_LIST_URL)
			}
		} catch (error) {
			showBackendErrorToast(error)
			console.error('Error on save', error)
		}
	}

	const handleSelection = (selected: AssociationOption[]) => {
		const selectedProvider = selected.find((provider) => provider.isSelected)
		setSelectedProvider(selectedProvider)
	}

	return (
		<FormLayout
			title={t('provider_association.title')}
			leftButton={
				<ButtonComponent variant='only-text' onClick={() => navigate(FRIENDS_LIST_URL)}>
					{t('general.cancel')}
				</ButtonComponent>
			}
			footer={
				<ButtonComponent fullWidth onClick={() => onSave(selectedProvider)}>
					{t('friend.friend_search_page.request_friendship')}
				</ButtonComponent>
			}>
			<FormSectionLayout
				title={t('provider_association.associate_provider')}
				className='provider-association-modal'>
				<p className='provider-association-modal-description'>
					{t('provider_association.description')}
				</p>
				<AssociationList
					isLoading={isLoading}
					associations={associationsOptions}
					title={t('provider_association.association_list_title')}
					addButtonText={t('provider_association.association_list_button')}
					modalContent={{
						title: t('provider_association.association_list_modal_title'),
						subtitle: t('provider_association.association_list_modal_subtitle'),
						description: t('provider_association.association_list_modal_description')
					}}
					isMultiple={false}
					handleSelection={handleSelection}
					tooltip={{
						title: t('provider_association.association_list_tooltip_title'),
						description: t('provider_association.association_list_tooltip_description')
					}}
				/>
				<ButtonComponent
					onClick={() =>
						navigate(PROVIDERS_NEW_URL, {
							state: { type: 'sendFriendRequest', companyId: companyId }
						})
					}
					variant='only-text'>
					+ {t('provider_association.association_list_modal_add_provider')}
				</ButtonComponent>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default FriendAssociation
