import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
	isActive?: boolean
	activeColor?: string
}

const CarIcon: FC<Props> = (props) => {
	const { width = 32, height = 32, isActive = false, activeColor = colors.violetMt } = props

	return isActive ? (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'>
			<path
				opacity='0.2'
				d='M23 23H28V26C28 26.2652 27.8946 26.5196 27.7071 26.7071C27.5196 26.8946 27.2652 27 27 27H24C23.7348 27 23.4804 26.8946 23.2929 26.7071C23.1054 26.5196 23 26.2652 23 26V23ZM4 26C4 26.2652 4.10536 26.5196 4.29289 26.7071C4.48043 26.8946 4.73478 27 5 27H8C8.26522 27 8.51957 26.8946 8.70711 26.7071C8.89464 26.5196 9 26.2652 9 26V23H4V26ZM24.2638 6.59375C24.1852 6.41702 24.057 6.26686 23.8949 6.16148C23.7327 6.05609 23.5434 6 23.35 6H8.65C8.45659 6 8.26733 6.05609 8.10514 6.16148C7.94296 6.26686 7.81482 6.41702 7.73625 6.59375L4 15H28L24.2638 6.59375Z'
				fill={activeColor}
			/>
			<path
				d='M30 14H28.65L25.1775 6.1875C25.0204 5.83403 24.7641 5.53372 24.4397 5.32296C24.1153 5.11219 23.7368 5 23.35 5H8.65C8.26317 5 7.88465 5.11219 7.56029 5.32296C7.23593 5.53372 6.97965 5.83403 6.8225 6.1875L3.35 14H2C1.73478 14 1.48043 14.1054 1.29289 14.2929C1.10536 14.4804 1 14.7348 1 15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H3V26C3 26.5304 3.21071 27.0391 3.58579 27.4142C3.96086 27.7893 4.46957 28 5 28H8C8.53043 28 9.03914 27.7893 9.41421 27.4142C9.78929 27.0391 10 26.5304 10 26V24H22V26C22 26.5304 22.2107 27.0391 22.5858 27.4142C22.9609 27.7893 23.4696 28 24 28H27C27.5304 28 28.0391 27.7893 28.4142 27.4142C28.7893 27.0391 29 26.5304 29 26V16H30C30.2652 16 30.5196 15.8946 30.7071 15.7071C30.8946 15.5196 31 15.2652 31 15C31 14.7348 30.8946 14.4804 30.7071 14.2929C30.5196 14.1054 30.2652 14 30 14ZM8.65 7H23.35L26.4613 14H5.53875L8.65 7ZM8 26H5V24H8V26ZM24 26V24H27V26H24ZM27 22H5V16H27V22ZM7 19C7 18.7348 7.10536 18.4804 7.29289 18.2929C7.48043 18.1054 7.73478 18 8 18H10C10.2652 18 10.5196 18.1054 10.7071 18.2929C10.8946 18.4804 11 18.7348 11 19C11 19.2652 10.8946 19.5196 10.7071 19.7071C10.5196 19.8946 10.2652 20 10 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19ZM21 19C21 18.7348 21.1054 18.4804 21.2929 18.2929C21.4804 18.1054 21.7348 18 22 18H24C24.2652 18 24.5196 18.1054 24.7071 18.2929C24.8946 18.4804 25 18.7348 25 19C25 19.2652 24.8946 19.5196 24.7071 19.7071C24.5196 19.8946 24.2652 20 24 20H22C21.7348 20 21.4804 19.8946 21.2929 19.7071C21.1054 19.5196 21 19.2652 21 19Z'
				fill={activeColor}
			/>
		</svg>
	) : (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'>
			<path
				d='M30 14H28.65L25.1775 6.1875C25.0204 5.83403 24.7641 5.53372 24.4397 5.32296C24.1153 5.11219 23.7368 5 23.35 5H8.65C8.26317 5 7.88465 5.11219 7.56029 5.32296C7.23593 5.53372 6.97965 5.83403 6.8225 6.1875L3.35 14H2C1.73478 14 1.48043 14.1054 1.29289 14.2929C1.10536 14.4804 1 14.7348 1 15C1 15.2652 1.10536 15.5196 1.29289 15.7071C1.48043 15.8946 1.73478 16 2 16H3V26C3 26.5304 3.21071 27.0391 3.58579 27.4142C3.96086 27.7893 4.46957 28 5 28H8C8.53043 28 9.03914 27.7893 9.41421 27.4142C9.78929 27.0391 10 26.5304 10 26V24H22V26C22 26.5304 22.2107 27.0391 22.5858 27.4142C22.9609 27.7893 23.4696 28 24 28H27C27.5304 28 28.0391 27.7893 28.4142 27.4142C28.7893 27.0391 29 26.5304 29 26V16H30C30.2652 16 30.5196 15.8946 30.7071 15.7071C30.8946 15.5196 31 15.2652 31 15C31 14.7348 30.8946 14.4804 30.7071 14.2929C30.5196 14.1054 30.2652 14 30 14ZM8.65 7H23.35L26.4613 14H5.53875L8.65 7ZM8 26H5V24H8V26ZM24 26V24H27V26H24ZM27 22H5V16H27V22ZM7 19C7 18.7348 7.10536 18.4804 7.29289 18.2929C7.48043 18.1054 7.73478 18 8 18H10C10.2652 18 10.5196 18.1054 10.7071 18.2929C10.8946 18.4804 11 18.7348 11 19C11 19.2652 10.8946 19.5196 10.7071 19.7071C10.5196 19.8946 10.2652 20 10 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19ZM21 19C21 18.7348 21.1054 18.4804 21.2929 18.2929C21.4804 18.1054 21.7348 18 22 18H24C24.2652 18 24.5196 18.1054 24.7071 18.2929C24.8946 18.4804 25 18.7348 25 19C25 19.2652 24.8946 19.5196 24.7071 19.7071C24.5196 19.8946 24.2652 20 24 20H22C21.7348 20 21.4804 19.8946 21.2929 19.7071C21.1054 19.5196 21 19.2652 21 19Z'
				fill='#2D2D47'
			/>
		</svg>
	)
}

export default CarIcon
