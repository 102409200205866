import InputButton from 'components/elements/InputButton/InputButton'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import { useModal } from 'hooks/useModal'
import { RefObject, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString, firstElement } from 'utils/common'

const id = 'select-vehicle-class'

interface Props {
	selectedId: number | null
	onSelect: (id: number | null) => void
	error?: string
	label: string
	isLabelOptional?: boolean
}

const SelecteVehicleClassOptions = forwardRef<HTMLElement, Props>((props, ref) => {
	const { selectedId, onSelect, error, label, isLabelOptional } = props

	const { t } = useTranslation()
	const [modalIsVisible, toggleModalVisibility] = useModal()
	const vehicleClass = useConfigStore((state) => state.vehicleClasses)

	const placeholder = selectedId
		? vehicleClass.byId[selectedId].name
		: t('vehicle.class_placeholder')

	const handleClick = (ids: number[]) => {
		onSelect(ids[firstElement])
		toggleModalVisibility()
	}

	const handleReset = () => {
		onSelect(null)
		toggleModalVisibility()
	}

	const itemsDropdownModal = vehicleClass.allIds.map((id) => {
		const classType = vehicleClass.byId[id]

		return {
			id: classType.id,
			name: classType.name
		}
	})

	const valueSelected = selectedId ? vehicleClass.byId[selectedId]?.name : undefined

	return (
		<div className={`select-option${error ? ' error' : emptyString}`}>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>
			<InputButton
				ref={ref as RefObject<HTMLButtonElement>}
				id={id}
				type='select'
				placeholder={placeholder}
				onClick={toggleModalVisibility}
				text={valueSelected}
				error={error}
			/>
			<DropdownSelectorModal
				visible={modalIsVisible}
				items={itemsDropdownModal}
				toggleVisibility={toggleModalVisibility}
				handleClick={handleClick}
				handleReset={handleReset}
				title={t('form.select_at', { field: t('transfer.provider') })}
				selected={selectedId}
			/>
			{error && <span className='input-error-message'>{error}</span>}
		</div>
	)
})

export default SelecteVehicleClassOptions
