import { useQuery } from '@tanstack/react-query'
import { configurationsQueryKey } from 'constants/reactQueryKeys'
import { useEffect, useMemo, useState } from 'react'
import { getConfigurations } from 'services/config'

const useConfigurations = () => {
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
	const {
		data: configurations,
		isLoading,
		isError: isErrorConfigurations,
		error: errorConfigurations
	} = useQuery({
		queryKey: [configurationsQueryKey],
		queryFn: getConfigurations,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	useEffect(() => {
		if (isErrorConfigurations && errorConfigurations) {
			setErrorMessage('Error loading configurations')
		}
	}, [isErrorConfigurations, errorConfigurations])

	const isConfigurationActive = useMemo(() => {
		return (key: string) => {
			const config = configurations?.find((config) => config.key === key)
			return config?.active || false
		}
	}, [configurations])

	return { configurations, loading: isLoading, error: errorMessage, isConfigurationActive }
}

export default useConfigurations
