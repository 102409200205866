import { TransferShareIcon, UserCirclePlusFilledIcon } from 'assets/svgs'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import { DAILY_URL, FRIENDS_LIST_URL, TRANSFER_REQUESTS_URL } from 'constants/routes'
import useNotifications from 'hooks/useNotification'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { FriendTab, RequestTypeTab } from 'models/Elements'
import { AppNotification, NotificationTypes } from 'models/Notification'
import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import './Notifications.scss'

const Notifications: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { notifications, markAsRead, refetchNotifications } = useNotifications()

	const icon = useMemo(
		() => ({
			[NotificationTypes.transferShare]: <TransferShareIcon />,
			[NotificationTypes.friendRequest]: <UserCirclePlusFilledIcon />
		}),
		[]
	)

	useEffect(() => {
		refetchNotifications()
	}, [])

	const handleNotification = async (notification: AppNotification) => {
		await markAsRead(notification.id)
		if (notification.type === NotificationTypes.transferShare) {
			navigate(`${TRANSFER_REQUESTS_URL}/${RequestTypeTab.received}`)
		} else if (notification.type === NotificationTypes.friendRequest) {
			navigate(`${FRIENDS_LIST_URL}/${FriendTab.requests}/${RequestTypeTab.received}`)
		}
	}

	return (
		<FormLayout title={t('notifications.title')} leftButton={<ButtonBack url={DAILY_URL} />}>
			{notifications.map((notification) => (
				<button
					className={`notification-card${notification.isRead ? ' read' : emptyString}`}
					key={notification.id}
					onClick={() => handleNotification(notification)}>
					<div className='notification-card-icon'>{icon[notification.type]}</div>
					<p className='notification-card-text body1'>{notification.text}</p>
				</button>
			))}
		</FormLayout>
	)
}

export default Notifications
