import colors from 'constants/colors'
import React from 'react'

interface Props {
	width?: number
	height?: number
}

const StartTransfer: React.FC<Props> = (props) => {
	const { width = 15, height = 15 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.0625 11.5312C13.5392 11.532 13.029 11.6946 12.6018 11.9967C12.1745 12.2988 11.8512 12.7256 11.6761 13.2188H5.0625C4.54035 13.2188 4.0396 13.0113 3.67038 12.6421C3.30117 12.2729 3.09375 11.7721 3.09375 11.25C3.09375 10.7279 3.30117 10.2271 3.67038 9.85788C4.0396 9.48867 4.54035 9.28125 5.0625 9.28125H11.8125C12.633 9.28125 13.4199 8.9553 14.0001 8.37511C14.5803 7.79492 14.9062 7.00801 14.9062 6.1875C14.9062 5.36699 14.5803 4.58008 14.0001 3.99989C13.4199 3.4197 12.633 3.09375 11.8125 3.09375H5.0625C4.83872 3.09375 4.62411 3.18264 4.46588 3.34088C4.30764 3.49911 4.21875 3.71372 4.21875 3.9375C4.21875 4.16128 4.30764 4.37589 4.46588 4.53412C4.62411 4.69236 4.83872 4.78125 5.0625 4.78125H11.8125C12.1855 4.78125 12.5431 4.92941 12.8069 5.19313C13.0706 5.45685 13.2188 5.81454 13.2188 6.1875C13.2188 6.56046 13.0706 6.91815 12.8069 7.18187C12.5431 7.44559 12.1855 7.59375 11.8125 7.59375H5.0625C4.0928 7.59375 3.16282 7.97896 2.47714 8.66464C1.79146 9.35032 1.40625 10.2803 1.40625 11.25C1.40625 12.2197 1.79146 13.1497 2.47714 13.8354C3.16282 14.521 4.0928 14.9062 5.0625 14.9062H11.6761C11.8308 15.3438 12.1028 15.7304 12.4623 16.0237C12.8219 16.3171 13.2552 16.506 13.7148 16.5698C14.1745 16.6335 14.6428 16.5697 15.0687 16.3852C15.4945 16.2008 15.8614 15.9028 16.1294 15.5239C16.3973 15.145 16.5559 14.6997 16.5878 14.2367C16.6198 13.7738 16.5238 13.3109 16.3105 12.8988C16.0971 12.4867 15.7746 12.1412 15.3782 11.9C14.9817 11.6588 14.5266 11.5312 14.0625 11.5312ZM14.0625 14.9062C13.8956 14.9062 13.7325 14.8568 13.5937 14.7641C13.455 14.6713 13.3468 14.5396 13.283 14.3854C13.2191 14.2312 13.2024 14.0616 13.235 13.8979C13.2675 13.7342 13.3479 13.5839 13.4659 13.4659C13.5839 13.3479 13.7342 13.2675 13.8979 13.235C14.0616 13.2024 14.2312 13.2191 14.3854 13.283C14.5396 13.3468 14.6713 13.455 14.7641 13.5937C14.8568 13.7325 14.9062 13.8956 14.9062 14.0625C14.9062 14.2863 14.8174 14.5009 14.6591 14.6591C14.5009 14.8174 14.2863 14.9062 14.0625 14.9062Z'
				fill={colors.violetMt}
			/>
			<circle cx='3.375' cy='3.9375' r='2.1875' fill='#DCD9E4' stroke={colors.violetMt} />
			<circle cx='14' cy='14' r='2.5' fill={colors.hasEndBorder} stroke='#FFAB00' />
		</svg>
	)
}

export default StartTransfer
