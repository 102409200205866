import { ItemDropdown } from 'components/elements/DropdownSelector/DropdownSelector'
import { ENVIRONMENT } from 'constants/environment'
import { Transfer, TransferReceived, type SharedTransfer } from 'models/Transfer'
import { normalize } from 'normalizr'
import {
	receivedTransfersSchema,
	sharedTransferSchema,
	sharedTransferSchemaAfterConfig,
	sharedTransferSchemaInitialConfig
} from 'schemas/index'
import {
	TransferShareConfiguration,
	acceptTransferSharedRequestService,
	cancelTransferSharedRequestService,
	getAllTransferShareConfigurationsService,
	getAllTransferSharedRequestService,
	getTransferReceivedByRequestService,
	getTransferSharedByRequestId,
	rejectTransferSharedRequestService,
	sendTransferSharedRequestService,
	updateAfterConfigurationService,
	updateInitialConfigurationService
} from 'services/transfers'
import { getCompanyId } from 'services/utils'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface State {
	sharedTransfers: {
		byId: { [id: number]: SharedTransfer }
		allIds: number[]
	}
	receivedTransfers: {
		byId: { [id: number]: TransferReceived }
		allIds: number[]
	}
	initialConfiguration: {
		byId: { [id: number]: ItemDropdown }
		allIds: number[]
	}
	afterConfiguration: {
		byId: { [id: number]: ItemDropdown }
		allIds: number[]
	}
	fetchShareTransferByRequestId: (bookingId: number) => Promise<SharedTransfer>
	fetchAllTransferSharedRequests: () => Promise<SharedTransfer[]>
	fetchAllTransferShareConfigurations: () => Promise<TransferShareConfiguration>
	sendTransferSharedRequest: (
		bookingId: number,
		receivingCompanyId: number
	) => Promise<SharedTransfer>
	updateTransferShareInitialConfiguration: (ids: number[]) => Promise<ItemDropdown[]>
	updateTransferShareAfterConfiguration: (ids: number[]) => Promise<ItemDropdown[]>
	fetchTransferReceivedByRequest: (id: number) => Promise<TransferReceived>
	getAllTransferSharedRequestsSent: () => SharedTransfer[]
	getAllTransferSharedRequestsReceived: () => SharedTransfer[]
	acceptTransferSharedRequest: (requestId: number) => Promise<Transfer>
	rejectTransferSharedRequest: (requestId: number) => Promise<void>
	cancelTransferSharedRequest: (requestId: number) => Promise<Transfer>
	resetAllInfo: () => void
}

export const useTransfersShareStore = create<State>()(
	devtools(
		(set, get) => ({
			sharedTransfers: {
				byId: {},
				allIds: [] as number[]
			},
			receivedTransfers: {
				byId: {},
				allIds: [] as number[]
			},
			initialConfiguration: {
				byId: {},
				allIds: [] as number[]
			},
			afterConfiguration: {
				byId: {},
				allIds: [] as number[]
			},
			fetchShareTransferByRequestId: async (requestId) => {
				const sharedTransferData = await getTransferSharedByRequestId(requestId)
				const normalizedData = normalize(sharedTransferData, sharedTransferSchema)
				set(
					(state) => ({
						sharedTransfers: {
							byId: {
								...state.sharedTransfers.byId,
								...normalizedData.entities.sharedTransfers
							},
							allIds: Array.from(new Set([...state.sharedTransfers.allIds, normalizedData.result]))
						}
					}),
					false,
					'FETCH_SHARED_TRANSFER_REQUESTS'
				)
				return sharedTransferData
			},
			fetchAllTransferSharedRequests: async () => {
				const sharedTransferData = await getAllTransferSharedRequestService()
				const normalizedData = normalize(sharedTransferData, [sharedTransferSchema])

				set(
					(state) => ({
						sharedTransfers: {
							byId: {
								...state.sharedTransfers.byId,
								...normalizedData.entities.sharedTransfers
							},
							allIds: Array.from(
								new Set([...state.sharedTransfers.allIds, ...normalizedData.result])
							)
						}
					}),
					false,
					'FETCH_ALL_SHARED_TRANSFER_REQUESTS'
				)

				return sharedTransferData
			},
			fetchAllTransferShareConfigurations: async () => {
				const { initial, after } = await getAllTransferShareConfigurationsService()
				const normalizeInitialData = normalize(initial, [sharedTransferSchemaInitialConfig])
				const normalizeAfterData = normalize(after, [sharedTransferSchemaAfterConfig])

				set(
					(state) => ({
						initialConfiguration: {
							byId: {
								...state.initialConfiguration.byId,
								...normalizeInitialData.entities.initialConfiguration
							},
							allIds: Array.from(
								new Set([...state.initialConfiguration.allIds, ...normalizeInitialData.result])
							)
						},
						afterConfiguration: {
							byId: {
								...state.afterConfiguration.byId,
								...normalizeAfterData.entities.afterConfiguration
							},
							allIds: Array.from(
								new Set([...state.afterConfiguration.allIds, ...normalizeAfterData.result])
							)
						}
					}),
					false,
					'FETCH_ALL_SHARED_TRANSFER_CONFIGURATIONS'
				)
				return {
					initial: initial,
					after: after
				}
			},
			sendTransferSharedRequest: async (bookingId, receivingCompanyId) => {
				const sharedTransfer = await sendTransferSharedRequestService(bookingId, receivingCompanyId)
				const normalizeData = normalize(sharedTransfer, sharedTransferSchema)

				set(
					(state) => ({
						sharedTransfers: {
							byId: {
								...state.sharedTransfers.byId,
								...normalizeData.entities.sharedTransfers
							},
							allIds: [...state.sharedTransfers.allIds, normalizeData.result]
						}
					}),
					false,
					'SEND_SHARED_TRANSFER_REQUEST'
				)

				return sharedTransfer
			},
			updateTransferShareInitialConfiguration: async (ids) => {
				const initial = await updateInitialConfigurationService(ids)
				const normalizeData = normalize(initial, [sharedTransferSchemaInitialConfig])
				set(
					(state) => ({
						initialConfiguration: {
							byId: {
								...state.initialConfiguration.byId,
								...normalizeData.entities.initialConfiguration
							},
							allIds: Array.from(
								new Set([...state.initialConfiguration.allIds, normalizeData.result])
							)
						}
					}),
					false,
					'UPDATE_INITIAL_CONFIGURATION'
				)
				return initial
			},
			updateTransferShareAfterConfiguration: async (ids) => {
				const after = await updateAfterConfigurationService(ids)
				const normalizeData = normalize(after, [sharedTransferSchemaAfterConfig])
				set(
					(state) => ({
						afterConfiguration: {
							byId: {
								...state.afterConfiguration.byId,
								...normalizeData.entities.afterConfiguration
							},
							allIds: Array.from(
								new Set([...state.afterConfiguration.allIds, normalizeData.result])
							)
						}
					}),
					false,
					'UPDATE_AFTER_CONFIGURATION'
				)
				return after
			},
			fetchTransferReceivedByRequest: async (id) => {
				const transferReceived = await getTransferReceivedByRequestService(id)
				const normalizeData = normalize(transferReceived, receivedTransfersSchema)
				set(
					(state) => ({
						receivedTransfers: {
							byId: {
								...state.receivedTransfers.byId,
								...normalizeData.entities.receivedTransfers
							},
							allIds: Array.from(new Set([...state.receivedTransfers.allIds, normalizeData.result]))
						}
					}),
					false,
					'FETCH_TRANSFER_RECEIVED'
				)
				return transferReceived
			},
			getAllTransferSharedRequestsSent: () => {
				const companyId = getCompanyId()

				if (!companyId) return []
				const sharedTransferSent = get()
					.sharedTransfers.allIds.filter((id) => {
						const sharedTransfer = get().sharedTransfers.byId[id]
						return sharedTransfer.sendingCompanyId === companyId
					})
					.map((id) => get().sharedTransfers.byId[id])

				return sharedTransferSent
			},
			getAllTransferSharedRequestsReceived: () => {
				const companyId = getCompanyId()

				if (!companyId) return []
				const sharedTransferReceived = get()
					.sharedTransfers.allIds.filter((id) => {
						const sharedTransfer = get().sharedTransfers.byId[id]
						if (!sharedTransfer?.receivingCompanyId) return false
						return sharedTransfer.receivingCompanyId === companyId
					})
					.map((id) => get().sharedTransfers.byId[id])

				return sharedTransferReceived
			},
			acceptTransferSharedRequest: async (requestId) => {
				const transfer = await acceptTransferSharedRequestService(requestId)
				return transfer
			},
			rejectTransferSharedRequest: async (requestId) => {
				await rejectTransferSharedRequestService(requestId)

				set((state) => {
					const newSharedTransfers: { [key: string]: SharedTransfer } = {
						...state.sharedTransfers.byId
					}
					Object.keys(newSharedTransfers).forEach((key) => {
						if (newSharedTransfers[key].id === requestId) {
							delete newSharedTransfers[key]
						}
					})
					return {
						sharedTransfers: {
							byId: newSharedTransfers,
							allIds: Object.keys(newSharedTransfers).map((key) => parseInt(key))
						}
					}
				})
			},
			cancelTransferSharedRequest: async (requestId) => {
				const transfer = await cancelTransferSharedRequestService(requestId)

				set((state) => {
					const newSharedTransfers: { [key: string]: SharedTransfer } = {
						...state.sharedTransfers.byId
					}
					Object.keys(newSharedTransfers).forEach((key) => {
						if (newSharedTransfers[key].id === requestId) {
							delete newSharedTransfers[key]
						}
					})
					return {
						sharedTransfers: {
							byId: newSharedTransfers,
							allIds: Object.keys(newSharedTransfers).map((key) => parseInt(key))
						}
					}
				})
				return transfer
			},
			resetAllInfo: () => {
				set(
					{
						sharedTransfers: {
							byId: {},
							allIds: []
						},
						receivedTransfers: {
							byId: {},
							allIds: []
						},
						initialConfiguration: {
							byId: {},
							allIds: []
						},
						afterConfiguration: {
							byId: {},
							allIds: []
						}
					},
					false,
					'RESET_ALL_INFO'
				)
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'transfers Share store'
		}
	)
)
