import Input from 'components/elements/Input/Input'
import SelectDocumentTypeOptions from 'components/elements/SelectOptions/SelectDocumentTypeOptions'
import SelectMunicipalityOptions from 'components/elements/SelectOptions/SelectMunicipalityOptions'
import SelectProvinceOptions from 'components/elements/SelectOptions/SelectProvinceOptions'
import { FormikProps } from 'formik'
import { CompanyForm } from 'models/Company'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfigStore } from 'stores/useConfigStore'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik: FormikProps<CompanyForm>
	setFieldRef: (field: string) => (ref: HTMLInputElement) => void
}

const MyCompanyInputs: FC<Props> = (props) => {
	const { formik, setFieldRef } = props

	const configStore = useConfigStore()
	const { t } = useTranslation()

	const handleProvinceChange = (provinceId: number | null) => {
		formik.setFieldValue('provinceId', provinceId)
		formik.setFieldValue('cityId', null)
	}

	return (
		<>
			<Input
				ref={setFieldRef('name')}
				id='name'
				valueSelected={formik.values.name}
				onChange={(e) => formik.setFieldValue('name', e.target.value)}
				label={t('settings.my_account.my_company.name_label')}
				placeholder={t('settings.my_account.my_company.name_placeholder')}
				error={getFormikError(formik, 'name')}
			/>
			<Input
				ref={setFieldRef('document')}
				id='document'
				valueSelected={formik.values.document}
				onChange={(e) => formik.setFieldValue('document', e.target.value)}
				label={t('settings.my_account.my_company.nif_label')}
				placeholder={t('settings.my_account.my_company.nif_label')}
				error={getFormikError(formik, 'document')}
			/>
			<SelectDocumentTypeOptions
				ref={setFieldRef('documentTypeId')}
				label={t('settings.my_account.my_company.document_type')}
				placeholder={t('settings.my_account.my_company.document_type')}
				selectedId={formik.values.documentTypeId}
				onSelect={(id) => formik.setFieldValue('documentTypeId', id)}
				error={getFormikError(formik, 'documentTypeId')}
			/>
			<Input
				ref={setFieldRef('mainEmail')}
				id='mainEmail'
				valueSelected={formik.values.mainEmail}
				onChange={(e) => formik.setFieldValue('mainEmail', e.target.value)}
				type='email'
				label={t('settings.my_account.my_company.main_email_label')}
				placeholder={t('settings.my_account.my_company.main_email_label')}
				error={getFormikError(formik, 'mainEmail')}
			/>
			<Input
				ref={setFieldRef('website')}
				id='website'
				valueSelected={formik.values.website}
				onChange={(e) => formik.setFieldValue('website', e.target.value)}
				label={t('settings.my_account.my_company.website')}
				labelHelper
				placeholder={t('settings.my_account.my_company.website')}
				error={getFormikError(formik, 'website')}
			/>
			<Input
				ref={setFieldRef('phone')}
				id='phone'
				valueSelected={formik.values.phone}
				onChange={(e) => formik.setFieldValue('phone', e.target.value)}
				label={t('settings.my_account.my_company.telephone_label')}
				placeholder={t('settings.my_account.my_company.telephone_label')}
				error={getFormikError(formik, 'phone')}
			/>
			<Input
				ref={setFieldRef('address')}
				id='address'
				valueSelected={formik.values.address}
				onChange={(e) => formik.setFieldValue('address', e.target.value)}
				label={t('settings.my_account.my_company.address_label')}
				placeholder={t('settings.my_account.my_company.address_label')}
				error={getFormikError(formik, 'address')}
			/>
			<SelectProvinceOptions
				ref={setFieldRef('provinceId')}
				label={t('settings.my_account.my_company.province_label')}
				placeholder={t('settings.my_account.my_company.province_label')}
				onSelect={handleProvinceChange}
				selectedId={formik.values.provinceId ? parseInt(formik.values.provinceId) : null}
				error={getFormikError(formik, 'provinceId')}
			/>
			<SelectMunicipalityOptions
				ref={setFieldRef('cityId')}
				label={t('settings.my_account.my_company.city_label')}
				placeholder={t('settings.my_account.my_company.city_label')}
				onSelect={(cityId) => formik.setFieldValue('cityId', cityId)}
				selectedId={formik.values.cityId ? parseInt(formik.values.cityId) : null}
				provinceSelected={
					formik.values.provinceId
						? configStore.provinces.byId[parseInt(formik.values.provinceId)]?.code
						: null
				}
				error={getFormikError(formik, 'cityId')}
			/>
			<Input
				ref={setFieldRef('zipCode')}
				id='zipCode'
				type='number'
				valueSelected={formik.values.zipCode}
				onChange={(e) => formik.setFieldValue('zipCode', e.target.value)}
				label={t('settings.my_account.my_company.post_code_label')}
				placeholder={t('settings.my_account.my_company.post_code_label')}
				error={getFormikError(formik, 'zipCode')}
			/>
		</>
	)
}

export default MyCompanyInputs
