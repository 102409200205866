import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './Loading.scss'

interface Props {
	fullscreen?: boolean
	hasText?: boolean
	isLoadingInput?: boolean
}

const Loading: React.FC<Props> = (props) => {
	const { fullscreen, hasText = true, isLoadingInput = false } = props

	const { t } = useTranslation()
	return (
		<div className={`loading ${fullscreen ? ' fullscreen' : emptyString}`}>
			<div className={`${isLoadingInput ? 'loadingInput' : 'spinner'}`}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			{hasText && <span>{t('general.loading')}</span>}
		</div>
	)
}

export default Loading
