import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import GenericList from 'components/elements/GenericList/GenericList'
import ListItem from 'components/elements/List/ListItem/ListItem'
import SwipeList from 'components/elements/List/SwipeList/SwipeList'
import SwipeListItem, {
	SwipeListItemMode
} from 'components/elements/List/SwipeList/SwipeListItem/SwipeListItem'
import Loading from 'components/elements/Loading/Loading'
import {
	ERROR_URL,
	RESOURCES_URL,
	VEHICLES_EDIT_URL,
	VEHICLES_NEW_URL,
	VEHICLES_VIEW_URL
} from 'constants/routes'
import useSearch from 'hooks/useSearch'
import useVehicle from 'hooks/useVehicle'
import useVehicles from 'hooks/useVehicles'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { StatusTabs } from 'models/Elements'

import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'

const VehicleList = () => {
	const { vehicles, loading, error } = useVehicles()
	const { removeVehicle, activateVehicle, deactivateVehicle } = useVehicle()
	const [tab, setTab] = useState(StatusTabs.active)
	const [searchTerm, setSearchTerm] = useState(emptyString)
	const hasItems = vehicles.length > 0
	const navigate = useNavigate()
	const { t } = useTranslation()

	const filteredVehicles = useMemo(() => {
		return vehicles
			.filter((vehicle) => vehicle.isVisible)
			.filter((vehicle) => (tab === StatusTabs.active ? vehicle.isActive : !vehicle.isActive))
	}, [vehicles, tab])

	const searchResults = useSearch(filteredVehicles, ['plate'], searchTerm)

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })

	const handleClick = (id: number) => {
		navigate(`${VEHICLES_VIEW_URL}/${id}`)
	}

	return (
		<FormLayout
			title={t('vehicle.list_title')}
			leftButton={<ButtonBack url={RESOURCES_URL} />}
			rightButton={
				<ButtonComponent onClick={() => navigate(VEHICLES_NEW_URL)} variant='only-text'>
					<strong>{t('general.add')}</strong>
				</ButtonComponent>
			}>
			<GenericList
				hasItems={hasItems}
				searchTerm={searchTerm}
				hasResults={!!searchResults.length}
				setSearchTerm={setSearchTerm}
				tabSelected={tab}
				setTab={setTab}
				noItemsText={
					<Trans
						i18nKey='vehicle.no_items_text'
						components={{
							span: <span />
						}}
					/>
				}
				noResultsText={
					<Trans
						i18nKey='vehicle.no_results_text'
						components={{
							span: <span />
						}}
						values={{ query: searchTerm }}
					/>
				}
				searchPlaceholder={t('vehicle.search_placeholder')}>
				<SwipeList>
					{searchResults.map((vehicle) => (
						<SwipeListItem
							key={vehicle.id}
							mode={
								tab === StatusTabs.active
									? SwipeListItemMode.deactivate
									: SwipeListItemMode.activate
							}
							onAction={() =>
								tab === StatusTabs.active
									? deactivateVehicle(vehicle.id)
									: activateVehicle(vehicle.id)
							}
							onEdit={() => navigate(`${VEHICLES_EDIT_URL}/${vehicle.id}`)}
							onDelete={() => removeVehicle(vehicle.id)}
							onClick={() => handleClick(vehicle.id)}>
							<ListItem key={vehicle.id}>
								<span>{vehicle.plate}</span>
							</ListItem>
						</SwipeListItem>
					))}
				</SwipeList>
			</GenericList>
		</FormLayout>
	)
}

export default VehicleList
