import { BillIcon, CarIcon, ConfigIcon, CreateIcon, ResourcesIcon } from 'assets/svgs'
import {
	CREATE_URL,
	DAILY_URL,
	HISTORICAL_URL,
	RESOURCES_URL,
	SETTINGS_URL,
	TRANSFER_STEPS,
	TRANSFER_URL
} from 'constants/routes'
import useConfigurations from 'hooks/useConfigurations'
import { Permissions } from 'models/Configurations'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { emptyString } from 'utils/common'
import { currentUserIsAdmin } from 'utils/currentUser'
import './Footer.scss'

interface Route {
	icon: React.ReactNode
	iconActive?: React.ReactNode
	label?: string
	routeLink: string
	testId?: string
	skip?: boolean
}

const Footer: FC = () => {
	const { t } = useTranslation()

	const location = useLocation()
	const pathname = location?.pathname

	const isRouteActive = (route: string) => pathname.includes(route)
	const isAdmin = currentUserIsAdmin()
	const { isConfigurationActive } = useConfigurations()

	const adminRoutes: Route[] = [
		{
			icon: <CarIcon />,
			iconActive: <CarIcon isActive />,
			label: t('routes.diary'),
			routeLink: DAILY_URL
		},
		{
			icon: <BillIcon />,
			iconActive: <BillIcon isActive />,
			label: t('routes.historical'),
			routeLink: HISTORICAL_URL
		},
		{
			icon: <CreateIcon />,
			iconActive: <CreateIcon />,
			routeLink: TRANSFER_URL + CREATE_URL + '/' + TRANSFER_STEPS.ROUTE.toLocaleLowerCase(),
			testId: 'footer-create',
			skip: !isConfigurationActive(Permissions.CREATE_TRANSFER)
		},
		{
			icon: <ResourcesIcon />,
			iconActive: <ResourcesIcon isActive />,
			label: t('routes.resources'),
			routeLink: RESOURCES_URL,
			testId: 'footer-resources',
			skip: !isAdmin
		},
		{
			icon: <ConfigIcon />,
			iconActive: <ConfigIcon isActive />,
			label: t('routes.configuration'),
			routeLink: SETTINGS_URL,
			testId: 'footer-settings'
		}
	]

	return (
		<div className='footer'>
			<div className='app-logo'>
				{adminRoutes
					.filter((route) => !route.skip)
					.map((route) => (
						<Link
							to={route.routeLink}
							key={route.routeLink}
							data-testid={route.testId}
							className={`sidebar-item ${
								isRouteActive(route.routeLink) ? 'active' : emptyString
							}`.trim()}>
							<div className='sidebar-icon'>
								{isRouteActive(route.routeLink) ? route.iconActive : route.icon}
							</div>
							{isRouteActive(route.routeLink) && route.label ? (
								<div className='sidebar-label'>{route.label}</div>
							) : null}
						</Link>
					))}
			</div>
		</div>
	)
}

export default Footer
