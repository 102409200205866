import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
	isActive?: boolean
}

const BillIcon: FC<Props> = (props) => {
	const { width = 32, height = 32, isActive = false } = props

	return isActive ? (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'>
			<path
				d='M16 11C15.0111 11 14.0444 11.2932 13.2221 11.8427C12.3999 12.3921 11.759 13.173 11.3806 14.0866C11.0022 15.0002 10.9031 16.0055 11.0961 16.9755C11.289 17.9454 11.7652 18.8363 12.4645 19.5355C13.1637 20.2348 14.0546 20.711 15.0245 20.9039C15.9945 21.0969 16.9998 20.9978 17.9134 20.6194C18.827 20.241 19.6079 19.6001 20.1573 18.7779C20.7068 17.9556 21 16.9889 21 16C21 14.6739 20.4732 13.4021 19.5355 12.4645C18.5979 11.5268 17.3261 11 16 11ZM16 19C15.4067 19 14.8266 18.8241 14.3333 18.4944C13.8399 18.1648 13.4554 17.6962 13.2284 17.1481C13.0013 16.5999 12.9419 15.9967 13.0576 15.4147C13.1734 14.8328 13.4591 14.2982 13.8787 13.8787C14.2982 13.4591 14.8328 13.1734 15.4147 13.0576C15.9967 12.9419 16.5999 13.0013 17.1481 13.2284C17.6962 13.4554 18.1648 13.8399 18.4944 14.3333C18.8241 14.8266 19 15.4067 19 16C19 16.7956 18.6839 17.5587 18.1213 18.1213C17.5587 18.6839 16.7956 19 16 19ZM30 7H2C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8V24C1 24.2652 1.10536 24.5196 1.29289 24.7071C1.48043 24.8946 1.73478 25 2 25H30C30.2652 25 30.5196 24.8946 30.7071 24.7071C30.8946 24.5196 31 24.2652 31 24V8C31 7.73478 30.8946 7.48043 30.7071 7.29289C30.5196 7.10536 30.2652 7 30 7ZM24.2062 23H7.79375C7.45801 21.8645 6.84351 20.8311 6.00623 19.9938C5.16895 19.1565 4.1355 18.542 3 18.2062V13.7937C4.1355 13.458 5.16895 12.8435 6.00623 12.0062C6.84351 11.1689 7.45801 10.1355 7.79375 9H24.2062C24.542 10.1355 25.1565 11.1689 25.9938 12.0062C26.8311 12.8435 27.8645 13.458 29 13.7937V18.2062C27.8645 18.542 26.8311 19.1565 25.9938 19.9938C25.1565 20.8311 24.542 21.8645 24.2062 23ZM29 11.6713C27.8005 11.1555 26.8445 10.1995 26.3288 9H29V11.6713ZM5.67125 9C5.15549 10.1995 4.19945 11.1555 3 11.6713V9H5.67125ZM3 20.3288C4.19945 20.8445 5.15549 21.8005 5.67125 23H3V20.3288ZM26.3288 23C26.8445 21.8005 27.8005 20.8445 29 20.3288V23H26.3288Z'
				fill={colors.violetMt}
			/>
		</svg>
	) : (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'>
			<path
				d='M16 11C15.0111 11 14.0444 11.2932 13.2221 11.8427C12.3999 12.3921 11.759 13.173 11.3806 14.0866C11.0022 15.0002 10.9031 16.0055 11.0961 16.9755C11.289 17.9454 11.7652 18.8363 12.4645 19.5355C13.1637 20.2348 14.0546 20.711 15.0245 20.9039C15.9945 21.0969 16.9998 20.9978 17.9134 20.6194C18.827 20.241 19.6079 19.6001 20.1573 18.7779C20.7068 17.9556 21 16.9889 21 16C21 14.6739 20.4732 13.4021 19.5355 12.4645C18.5979 11.5268 17.3261 11 16 11ZM16 19C15.4067 19 14.8266 18.8241 14.3333 18.4944C13.8399 18.1648 13.4554 17.6962 13.2284 17.1481C13.0013 16.5999 12.9419 15.9967 13.0576 15.4147C13.1734 14.8328 13.4591 14.2982 13.8787 13.8787C14.2982 13.4591 14.8328 13.1734 15.4147 13.0576C15.9967 12.9419 16.5999 13.0013 17.1481 13.2284C17.6962 13.4554 18.1648 13.8399 18.4944 14.3333C18.8241 14.8266 19 15.4067 19 16C19 16.7956 18.6839 17.5587 18.1213 18.1213C17.5587 18.6839 16.7956 19 16 19ZM30 7H2C1.73478 7 1.48043 7.10536 1.29289 7.29289C1.10536 7.48043 1 7.73478 1 8V24C1 24.2652 1.10536 24.5196 1.29289 24.7071C1.48043 24.8946 1.73478 25 2 25H30C30.2652 25 30.5196 24.8946 30.7071 24.7071C30.8946 24.5196 31 24.2652 31 24V8C31 7.73478 30.8946 7.48043 30.7071 7.29289C30.5196 7.10536 30.2652 7 30 7ZM24.2062 23H7.79375C7.45801 21.8645 6.84351 20.8311 6.00623 19.9938C5.16895 19.1565 4.1355 18.542 3 18.2062V13.7937C4.1355 13.458 5.16895 12.8435 6.00623 12.0062C6.84351 11.1689 7.45801 10.1355 7.79375 9H24.2062C24.542 10.1355 25.1565 11.1689 25.9938 12.0062C26.8311 12.8435 27.8645 13.458 29 13.7937V18.2062C27.8645 18.542 26.8311 19.1565 25.9938 19.9938C25.1565 20.8311 24.542 21.8645 24.2062 23ZM29 11.6713C27.8005 11.1555 26.8445 10.1995 26.3288 9H29V11.6713ZM5.67125 9C5.15549 10.1995 4.19945 11.1555 3 11.6713V9H5.67125ZM3 20.3288C4.19945 20.8445 5.15549 21.8005 5.67125 23H3V20.3288ZM26.3288 23C26.8445 21.8005 27.8005 20.8445 29 20.3288V23H26.3288Z'
				fill='#2D2D47'
			/>
		</svg>
	)
}

export default BillIcon
