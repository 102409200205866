import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import { REGISTER_URL } from 'constants/routes'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { useTranslation } from 'react-i18next'
import './Legal.scss'

const LEGAL_TEXTS = {
	protection_of_data: {
		title: 'Protección de datos Responsable:',
		body: `Mytransfer APP S.L. Finalidad: Gestionar su solicitud Legitimación: Su consentimiento
					para la finalidad descrita. Destinatarios: No se cederán datos a terceros no necesarios
					para el desarrollo de la actividad, salvo por obligación legal. No se harán transferencias
					de datos a terceros países. Derechos: Puede acceder, rectificar y suprimir sus datos, así
					como otros derechos, como se explica en la información adicional. Información adicional:
					Política de Privacidad`
	},
	privacy_policy: {
		title: 'Política de privacidad',
		body: `La privacidad es de gran importancia para MyTransfer.app y queremos mantener una actitud
					abierta y transparente al tratar sus datos personales. Por lo tanto, disponemos de una
					política que establece cómo se tratan y protegen sus datos personales. ¿Quién es el
					responsable del tratamiento de sus datos? Denominación comercial: Mytransfer.app Identidad
					del responsable: Mytransfer APP S.L.`
	}
}

const Legal: React.FC = () => {
	const { t } = useTranslation()

	return (
		<FormLayout title={t('iam.legal_title')} leftButton={<ButtonBack url={REGISTER_URL} />}>
			<div className='legal-body'>
				<h3>{LEGAL_TEXTS.protection_of_data.title}</h3>
				<p>{LEGAL_TEXTS.protection_of_data.body}</p>
				<h3>{LEGAL_TEXTS.privacy_policy.title}</h3>
				<p>{LEGAL_TEXTS.privacy_policy.body}</p>
			</div>
		</FormLayout>
	)
}

export default Legal
