import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import StepFomentoInputs from 'components/Transfer/StepInputs/StepFomentoInputs'
import { TRANSFER_FOMENTO_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFomento from 'hooks/useFomento'
import useToast from 'hooks/useToast'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { FomentoInfo, FomentoPost, StepFomentoForm } from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { getFomentoInfoByBookingIdService } from 'services/fomento'
import { useFomentoStore } from 'stores/useFomentoStore'
import { emptyString } from 'utils/common'

const FomentoForm = () => {
	const { transferId } = useParams() as { transferId: string }
	const transferIdNumber = parseInt(transferId)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const fomentoState = useFomentoStore()
	const { getProvinceCode, getMunicipalityCode } = useFomento()
	const [fomentoInfo, setFomentoInfo] = useState<FomentoInfo | null>(null)
	const [loadingFomentoInfo, setLoadingFomentoInfo] = useState(false)
	const { showBackendErrorToast } = useToast()

	const FOMENTO_STATUS_URL = `${TRANSFER_FOMENTO_URL}/${transferId}`

	const communicate = async () => {
		if (!transferId) return

		try {
			await fomentoState.communicate(parseInt(transferId))
			navigate(FOMENTO_STATUS_URL)
		} catch (err) {
			showBackendErrorToast(err)
		}
	}

	const formik = useFormik<StepFomentoForm>({
		initialValues: {
			provinceOriginId: null,
			municipalityOriginId: null,
			provinceDestinationId: null,
			municipalityDestinationId: null,
			provinceDistantId: null,
			municipalityDistantId: null,
			destinationFreely: false,
			addressDistant: emptyString,
			finishedAt: undefined
		},
		onSubmit: async (values) => {
			const fomentoForm: FomentoPost = {
				provinceOrigin: getProvinceCode(values.provinceOriginId),
				municipalityOrigin: getMunicipalityCode(values.municipalityOriginId),
				provinceDestination: getProvinceCode(values.provinceDestinationId),
				municipalityDestination: getMunicipalityCode(values.municipalityDestinationId),
				provinceDistant: getProvinceCode(values.provinceDistantId),
				municipalityDistant: getMunicipalityCode(values.municipalityDistantId),
				destinationFreely: values.destinationFreely,
				addressDistant: values.addressDistant,
				finishedAt: values.finishedAt ? values.finishedAt : undefined
			}

			try {
				if (fomentoInfo?.id) {
					await fomentoState.updateFomento(fomentoForm, parseInt(transferId))
				} else {
					await fomentoState.createFomento(fomentoForm, parseInt(transferId))
				}
				await communicate()
			} catch (err) {
				console.error(err)
				showBackendErrorToast(err)
			}
		}
	})

	useEffect(() => {
		const fetchFomentoInfo = async () => {
			if (transferIdNumber) {
				setLoadingFomentoInfo(true)
				const fomentoInfo = await getFomentoInfoByBookingIdService(transferIdNumber)
				setFomentoInfo(fomentoInfo)
				setLoadingFomentoInfo(false)
			}
		}
		fetchFomentoInfo()
	}, [transferIdNumber])

	if (loadingFomentoInfo) {
		return <Loading />
	}

	return (
		<FormLayout
			title={t('fomento.form_title')}
			leftButton={<ButtonBack />}
			footer={
				<ButtonComponent fullWidth onClick={formik.handleSubmit}>
					{t('fomento.communicate')}
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('transfer.create_steps.step_fomento.h1')} noDivider>
				<StepFomentoInputs formik={formik} fomentoData={fomentoInfo} />
			</FormSectionLayout>
		</FormLayout>
	)
}

export default FomentoForm
