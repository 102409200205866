import { ItemDropdown } from 'components/elements/DropdownSelector/DropdownSelector'
import InputButton from 'components/elements/InputButton/InputButton'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import { useModal } from 'hooks/useModal'
import { RefObject, forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString, firstElement } from 'utils/common'

interface Props {
	provinceSelected: string | null
	onSelect: (id: number | null) => void
	selectedId?: number | null
	error?: string
	label: string
	placeholder?: string
	isLabelOptional?: boolean
	id?: string
}

const SelectMunicipalityOptions = forwardRef<HTMLElement, Props>((props, ref) => {
	const {
		provinceSelected,
		onSelect,
		selectedId,
		error,
		label,
		placeholder = emptyString,
		isLabelOptional = false,
		id = 'municipality'
	} = props

	const { t } = useTranslation()
	const [modalIsVisible, toggleModalVisibility] = useModal()
	const [isDisabled, setIsDisabled] = useState<boolean>(true)
	const municipalityState = useConfigStore()
	const municipalities = municipalityState.municipalities
	const [items, setItems] = useState<ItemDropdown[]>([])

	const handleClick = (ids: number[]) => {
		onSelect(ids[firstElement])
		toggleModalVisibility()
	}

	const handleReset = () => {
		onSelect(null)
		toggleModalVisibility()
	}

	const dynamicPlaceholder = selectedId
		? municipalities.byId[selectedId]?.name || t('general.unknown')
		: placeholder

	useEffect(() => {
		if (provinceSelected) {
			setIsDisabled(false)
			const municipalities = municipalityState.getMunicipalityByProvinceCode(provinceSelected)
			const itemsDropdownModal = municipalities.map((municipality) => ({
				id: municipality.id,
				name: municipality.name
			}))

			setItems(itemsDropdownModal)
			return
		}

		setIsDisabled(true)
	}, [provinceSelected])

	const valueSelected = selectedId
		? municipalityState.municipalities.byId[selectedId]?.name
		: undefined

	return (
		<div className={`select-option${error ? ' error' : emptyString}`}>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>

			<InputButton
				ref={ref as RefObject<HTMLButtonElement>}
				type='select'
				id={id}
				onClick={toggleModalVisibility}
				placeholder={dynamicPlaceholder}
				disabled={isDisabled}
				text={valueSelected}
				error={error}
			/>

			<DropdownSelectorModal
				title={t('form.select_at', { field: t('provider.city').toLocaleLowerCase() })}
				visible={modalIsVisible}
				items={items}
				toggleVisibility={toggleModalVisibility}
				handleClick={handleClick}
				handleReset={handleReset}
				selected={selectedId}
			/>

			{error && <span className='input-error-message'>{error}</span>}
		</div>
	)
})

export default SelectMunicipalityOptions
