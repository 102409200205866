import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import GenericList from 'components/elements/GenericList/GenericList'
import ListItem from 'components/elements/List/ListItem/ListItem'
import Loading from 'components/elements/Loading/Loading'
import { ERROR_URL, SETTINGS_URL, USERS_NEW_URL, USERS_URL } from 'constants/routes'
import useSearch from 'hooks/useSearch'
import useUsers from 'hooks/useUsers'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { StatusTabs } from 'models/Elements'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import RoleTag from './RoleTag/RoleTag'

const UserList = () => {
	const { users, loading, error } = useUsers()
	const [tab, setTab] = useState(StatusTabs.active)
	const [searchTerm, setSearchTerm] = useState(emptyString)
	const hasItems = users.length > 0
	const navigate = useNavigate()
	const { t } = useTranslation()

	const filteredUsers = useMemo(() => {
		return users.filter((user) => (tab === StatusTabs.active ? user.isActive : !user.isActive))
	}, [users, tab])

	const searchResults = useSearch(filteredUsers, ['name', 'lastName'], searchTerm)

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: SETTINGS_URL } })

	const handleClick = (id: number) => {
		navigate(`${USERS_URL}/${id}`)
	}

	return (
		<FormLayout
			title={t('user.list_title')}
			leftButton={<ButtonBack url={SETTINGS_URL} />}
			rightButton={
				<ButtonComponent onClick={() => navigate(USERS_NEW_URL)} variant='only-text'>
					<strong>{t('general.add')}</strong>
				</ButtonComponent>
			}>
			<GenericList
				hasItems={hasItems}
				searchTerm={searchTerm}
				hasResults={!!searchResults.length}
				setSearchTerm={setSearchTerm}
				tabSelected={tab}
				setTab={setTab}
				noItemsText={
					<Trans
						i18nKey='user.no_items_text'
						components={{
							span: <span />
						}}
					/>
				}
				noResultsText={
					<Trans
						i18nKey='user.no_results_text'
						components={{
							span: <span />
						}}
						values={{ query: searchTerm }}
					/>
				}
				searchPlaceholder={t('user.search_placeholder')}>
				<>
					{searchResults.map((user) => {
						return (
							<ListItem
								key={user.id}
								onClick={() => handleClick(user.id)}
								right={<RoleTag roleId={user.userTypeId} />}>
								<span>
									{user.name} {user.lastName}
								</span>
							</ListItem>
						)
					})}
				</>
			</GenericList>
		</FormLayout>
	)
}

export default UserList
