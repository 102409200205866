import { useQuery } from '@tanstack/react-query'
import { driversQueryKey } from 'constants/reactQueryKeys'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllDriversService } from 'services/drivers'

const useDrivers = () => {
	const { t } = useTranslation()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	// GET ALL DRIVERS
	const {
		data: drivers,
		isLoading: isLoadingDrivers,
		error: driversError,
		isError: isErrorDrivers
	} = useQuery({
		queryKey: [driversQueryKey],
		queryFn: getAllDriversService,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	useEffect(() => {
		if (isErrorDrivers && driversError) {
			setErrorMessage(t('driver.error_load_drivers'))
		}
	}, [isErrorDrivers, driversError, t])

	return {
		drivers: drivers || [],
		loading: isLoadingDrivers,
		error: errorMessage
	}
}

export default useDrivers
