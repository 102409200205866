import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const BellCheckedIcon: FC<Props> = (props) => {
	const { width = 34, height = 34 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 34 34'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M25.0399 24.9615C24.4134 23.8822 23.482 20.8285 23.482 16.84C23.482 14.4447 22.5305 12.1475 20.8368 10.4538C19.1431 8.76011 16.8459 7.80859 14.4506 7.80859C12.0554 7.80859 9.75818 8.76011 8.06446 10.4538C6.37075 12.1475 5.41923 14.4447 5.41923 16.84C5.41923 20.8296 4.48674 23.8822 3.86018 24.9615C3.70018 25.2359 3.61536 25.5476 3.61427 25.8652C3.61318 26.1828 3.69586 26.4951 3.85397 26.7706C4.01209 27.046 4.24004 27.2749 4.51485 27.4342C4.78966 27.5935 5.10161 27.6774 5.41923 27.6777H10.0264C10.2347 28.6973 10.7889 29.6136 11.595 30.2717C12.4012 30.9298 13.41 31.2892 14.4506 31.2892C15.4913 31.2892 16.5 30.9298 17.3062 30.2717C18.1124 29.6136 18.6665 28.6973 18.8749 27.6777H23.482C23.7995 27.6772 24.1114 27.5931 24.386 27.4338C24.6607 27.2744 24.8885 27.0455 25.0464 26.7701C25.2044 26.4946 25.287 26.1824 25.2859 25.8649C25.2847 25.5474 25.1999 25.2358 25.0399 24.9615ZM14.4506 29.4839C13.8905 29.4838 13.3441 29.31 12.8868 28.9865C12.4295 28.663 12.0837 28.2058 11.897 27.6777H17.0043C16.8175 28.2058 16.4717 28.663 16.0144 28.9865C15.5571 29.31 15.0108 29.4838 14.4506 29.4839Z'
				fill={colors.violetMt}
			/>
			<ellipse cx='22.1004' cy='11.9002' rx='11.9002' ry='11.9002' fill='#FAF6FF' />
			<path
				d='M22.1006 2.23047C20.1882 2.23047 18.3188 2.79754 16.7288 3.85997C15.1388 4.92241 13.8995 6.43248 13.1676 8.19925C12.4358 9.96601 12.2444 11.9101 12.6174 13.7857C12.9905 15.6613 13.9114 17.3841 15.2636 18.7363C16.6158 20.0885 18.3387 21.0094 20.2142 21.3825C22.0898 21.7556 24.0339 21.5641 25.8007 20.8323C27.5674 20.1005 29.0775 18.8612 30.14 17.2711C31.2024 15.6811 31.7695 13.8117 31.7695 11.8994C31.7668 9.33585 30.7472 6.8781 28.9345 5.06542C27.1218 3.25273 24.6641 2.23318 22.1006 2.23047ZM26.3456 10.1943L21.1392 15.4006C21.0702 15.4698 20.9881 15.5246 20.8978 15.5621C20.8076 15.5995 20.7108 15.6188 20.613 15.6188C20.5153 15.6188 20.4185 15.5995 20.3282 15.5621C20.2379 15.5246 20.1559 15.4698 20.0868 15.4006L17.8555 13.1694C17.716 13.0298 17.6376 12.8405 17.6376 12.6431C17.6376 12.4458 17.716 12.2565 17.8555 12.1169C17.9951 11.9774 18.1844 11.899 18.3817 11.899C18.5791 11.899 18.7684 11.9774 18.908 12.1169L20.613 13.8229L25.2932 9.14188C25.3623 9.07277 25.4443 9.01796 25.5346 8.98056C25.6249 8.94316 25.7216 8.92391 25.8194 8.92391C25.9171 8.92391 26.0139 8.94316 26.1041 8.98056C26.1944 9.01796 26.2765 9.07277 26.3456 9.14188C26.4147 9.21098 26.4695 9.29302 26.5069 9.38331C26.5443 9.47359 26.5635 9.57036 26.5635 9.66809C26.5635 9.76582 26.5443 9.86259 26.5069 9.95287C26.4695 10.0432 26.4147 10.1252 26.3456 10.1943Z'
				fill='#55E38E'
			/>
		</svg>
	)
}

export default BellCheckedIcon
