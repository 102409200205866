import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const ArrowLeft: FC<Props> = (props) => {
	const { width = 22, height = 22 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M19.5946 11.0009C19.5946 11.2744 19.4859 11.5367 19.2925 11.7301C19.0991 11.9235 18.8368 12.0322 18.5633 12.0322H5.9305L10.358 16.4588C10.5517 16.6526 10.6606 16.9153 10.6606 17.1893C10.6606 17.4633 10.5517 17.726 10.358 17.9198C10.1643 18.1135 9.90151 18.2223 9.62753 18.2223C9.35355 18.2223 9.0908 18.1135 8.89706 17.9198L2.70956 11.7323C2.61342 11.6365 2.53714 11.5226 2.48509 11.3973C2.43304 11.2719 2.40625 11.1375 2.40625 11.0018C2.40625 10.8661 2.43304 10.7317 2.48509 10.6063C2.53714 10.481 2.61342 10.3671 2.70956 10.2713L8.89706 4.08382C8.99299 3.98789 9.10687 3.9118 9.2322 3.85989C9.35754 3.80797 9.49187 3.78125 9.62753 3.78125C9.76319 3.78125 9.89752 3.80797 10.0229 3.85989C10.1482 3.9118 10.2621 3.98789 10.358 4.08382C10.4539 4.17975 10.53 4.29363 10.5819 4.41896C10.6339 4.5443 10.6606 4.67863 10.6606 4.81429C10.6606 4.94995 10.6339 5.08428 10.5819 5.20962C10.53 5.33495 10.4539 5.44883 10.358 5.54476L5.9305 9.96968H18.5633C18.8368 9.96968 19.0991 10.0783 19.2925 10.2717C19.4859 10.4651 19.5946 10.7274 19.5946 11.0009Z'
				fill={colors.blueMt}
			/>
		</svg>
	)
}

export default ArrowLeft
