import { useMutation, useQuery } from '@tanstack/react-query'
import {
	friendsAvailableQueryKey,
	friendsQueryKey,
	requestsReceivedQueryKey,
	requestsSentQueryKey
} from 'constants/reactQueryKeys'
import { Company } from 'models/Company'
import { Friend } from 'models/Friend'
import { Provider } from 'models/Provider'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
	acceptFriendRequestService,
	getAllCompaniesAvailableService,
	getAllFriendsService,
	getFriendRequestsReceivedService,
	getFriendRequestsSentService,
	rejectReceivedRequestService,
	rejectSentRequestService,
	sendFriendRequestService
} from 'services/friends'
import { toast } from 'sonner'
import useToast from './useToast'

const useFriends = () => {
	const { showBackendErrorToast } = useToast()
	const { t } = useTranslation()

	// GET FRIENDS
	const {
		data: friends = [],
		isLoading: loadingFriends,
		isError: isErrorFriends,
		error: errorFriends
	} = useQuery({
		queryKey: [friendsQueryKey],
		queryFn: getAllFriendsService,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	// GET SENT REQUESTS
	const {
		data: requestsSent = [],
		isLoading: loadingRequestsSent,
		isError: isErrorRequestsSent,
		error: errorRequestsSent,
		refetch: refetchRequestsSent
	} = useQuery({
		queryKey: [requestsSentQueryKey],
		queryFn: getFriendRequestsSentService,
		staleTime: 1000 * 60 * 5
	})

	// GET RECEIVED REQUEST
	const {
		data: requestReceived = [],
		isLoading: loadingRequestsReceived,
		isError: isErrorRequestsReceived,
		error: errorRequestsReceived,
		refetch: refetchRequestsReceived
	} = useQuery({
		queryKey: [requestsReceivedQueryKey],
		queryFn: getFriendRequestsReceivedService,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	//GET FRIENDS AVAILABLE
	const {
		data: friendsAvailable = [],
		isLoading: loadingFriendsAvailable,
		isError: isErrorFriendsAvailable,
		error: errorFriendsAvailable
	} = useQuery({
		queryKey: [friendsAvailableQueryKey],
		queryFn: getAllCompaniesAvailableService,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	// GET FRIEND PROVIDER NAME BY ID

	// HANDLE ERRORS
	useEffect(() => {
		if (isErrorFriends) {
			showBackendErrorToast(errorFriends)
		}
		if (isErrorRequestsSent) {
			showBackendErrorToast(errorRequestsSent)
		}
		if (isErrorRequestsReceived) {
			showBackendErrorToast(errorRequestsReceived)
		}
		if (isErrorFriendsAvailable) {
			showBackendErrorToast(errorFriendsAvailable)
		}
	}, [
		isErrorFriends,
		isErrorRequestsSent,
		isErrorRequestsReceived,
		errorFriends,
		errorRequestsSent,
		errorRequestsReceived,
		showBackendErrorToast,
		errorFriendsAvailable,
		isErrorFriendsAvailable
	])

	const { mutateAsync: sendFriendRequest } = useMutation({
		mutationFn: ({
			companyId,
			sendingProviderId
		}: {
			companyId: Company['id']
			sendingProviderId: Provider['id']
		}) => sendFriendRequestService(companyId, sendingProviderId),
		onSuccess: () => {
			toast.success(t('friend.request_sent'))
			refetchRequestsSent()
		},
		onError: (error) => {
			console.error(error)
			showBackendErrorToast(error)
		}
	})

	const { mutateAsync: acceptFriendRequest } = useMutation({
		mutationFn: ({
			requestId,
			sendingProviderId
		}: {
			requestId: Friend['id']
			sendingProviderId: Provider['id']
		}) => acceptFriendRequestService(requestId, sendingProviderId),
		onSuccess: () => {
			toast.success(t('friend.request_accepted'))
			refetchRequestsReceived()
		},
		onError: (error) => {
			console.error(error)
			showBackendErrorToast(error)
		}
	})

	const { mutateAsync: rejectReceivedRequest } = useMutation({
		mutationFn: rejectReceivedRequestService,
		onSuccess: () => {
			toast.success(t('friend.request_rejected'))
			refetchRequestsReceived()
		},
		onError: (error) => {
			console.error(error)
			showBackendErrorToast(error)
		}
	})

	const { mutateAsync: rejectSentRequest } = useMutation({
		mutationFn: rejectSentRequestService,
		onSuccess: () => {
			toast.success(t('friend.request_rejected'))
			refetchRequestsSent()
		},
		onError: (error) => {
			console.error(error)
			showBackendErrorToast(error)
		}
	})

	const getFriendProviderNameById = (friendId: Friend['id']) => {
		const friend = friends.find((friend) => friend.receivingCompanyId === friendId)
		return friend ? friend.sendingProviderName : undefined
	}

	return {
		friends,
		requestsSent,
		requestReceived,
		loading:
			loadingFriends || loadingRequestsSent || loadingRequestsReceived || loadingFriendsAvailable,
		sendFriendRequest,
		acceptFriendRequest,
		rejectReceivedRequest,
		rejectSentRequest,
		error:
			isErrorFriends || isErrorRequestsSent || isErrorRequestsReceived || isErrorFriendsAvailable,
		getFriendProviderNameById,
		friendsAvailable: friendsAvailable
	}
}

export default useFriends
