import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
	color?: string
}

const BellFilledIcon: FC<Props> = (props) => {
	const { width = 17, height = 17, color = colors.violetMt } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 17 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.7291 11.6835C14.3606 11.0487 13.8127 9.25238 13.8127 6.90625C13.8127 5.49729 13.253 4.14603 12.2567 3.14975C11.2604 2.15346 9.90917 1.59375 8.50021 1.59375C7.09124 1.59375 5.73999 2.15346 4.7437 3.14975C3.74742 4.14603 3.18771 5.49729 3.18771 6.90625C3.18771 9.25305 2.63919 11.0487 2.27064 11.6835C2.17652 11.8449 2.12662 12.0283 2.12598 12.2151C2.12534 12.4019 2.17398 12.5856 2.26698 12.7477C2.35999 12.9097 2.49408 13.0444 2.65573 13.138C2.81738 13.2317 3.00087 13.2811 3.18771 13.2812H5.89775C6.02031 13.881 6.34627 14.42 6.82048 14.8071C7.2947 15.1942 7.88806 15.4057 8.50021 15.4057C9.11236 15.4057 9.70572 15.1942 10.1799 14.8071C10.6541 14.42 10.9801 13.881 11.1027 13.2812H13.8127C13.9995 13.281 14.1829 13.2315 14.3445 13.1378C14.506 13.0441 14.64 12.9094 14.7329 12.7474C14.8259 12.5854 14.8744 12.4017 14.8738 12.2149C14.8731 12.0282 14.8232 11.8449 14.7291 11.6835ZM8.50021 14.3438C8.17071 14.3436 7.84935 14.2414 7.58035 14.0511C7.31134 13.8609 7.10793 13.5919 6.9981 13.2812H10.0023C9.89248 13.5919 9.68907 13.8609 9.42007 14.0511C9.15107 14.2414 8.8297 14.3436 8.50021 14.3438Z'
				fill={color}
			/>
		</svg>
	)
}

export default BellFilledIcon
