import { UserFilledIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import GenericList from 'components/elements/GenericList/GenericList'
import ListItem from 'components/elements/List/ListItem/ListItem'
import SwipeList from 'components/elements/List/SwipeList/SwipeList'
import SwipeListItem, {
	SwipeListItemMode
} from 'components/elements/List/SwipeList/SwipeListItem/SwipeListItem'
import Loading from 'components/elements/Loading/Loading'
import {
	DRIVERS_EDIT_URL,
	DRIVERS_NEW_URL,
	DRIVERS_VIEW_URL,
	ERROR_URL,
	RESOURCES_URL
} from 'constants/routes'
import useDriver from 'hooks/useDriver'
import useDrivers from 'hooks/useDrivers'
import useSearch from 'hooks/useSearch'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { StatusTabs } from 'models/Elements'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import './DriverList.scss'

const DriverList = () => {
	const { drivers, loading, error } = useDrivers()
	const { hasAssociations, removeDriver, activateDriver, deactivateDriver, refetchAssociations } =
		useDriver()
	const [tab, setTab] = useState(StatusTabs.active)
	const [searchTerm, setSearchTerm] = useState(emptyString)
	const hasItems = drivers.filter((driver) => driver.isPending === false).length > 0
	const navigate = useNavigate()
	const { t } = useTranslation()

	const filteredDrivers = useMemo(() => {
		return drivers
			.filter((driver) => (tab === StatusTabs.active ? driver.isActive : !driver.isActive))
			.filter((driver) => driver.isPending === false)
			.filter((driver) => driver.isVisible)
	}, [drivers, tab])

	const searchResults = useSearch(filteredDrivers, ['name'], searchTerm)

	useEffect(() => {
		refetchAssociations()
	}, [])

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })

	const handleClick = (id: number) => {
		navigate(`${DRIVERS_VIEW_URL}/${id}`)
	}

	return (
		<FormLayout
			title={t('driver.list_title')}
			leftButton={<ButtonBack url={RESOURCES_URL} />}
			rightButton={
				<ButtonComponent onClick={() => navigate(DRIVERS_NEW_URL)} variant='only-text'>
					<strong>{t('general.add')}</strong>
				</ButtonComponent>
			}>
			<GenericList
				hasItems={hasItems}
				searchTerm={searchTerm}
				hasResults={!!searchResults.length}
				setSearchTerm={setSearchTerm}
				tabSelected={tab}
				setTab={setTab}
				noItemsText={
					<Trans
						i18nKey='driver.no_items_text'
						components={{
							span: <span />
						}}
					/>
				}
				noResultsText={
					<Trans
						i18nKey='driver.no_results_text'
						components={{
							span: <span />
						}}
						values={{ query: searchTerm }}
					/>
				}
				searchPlaceholder={t('driver.search_placeholder')}>
				<SwipeList>
					{searchResults.map((driver) => (
						<SwipeListItem
							key={driver.id}
							mode={
								tab === StatusTabs.active
									? SwipeListItemMode.deactivate
									: SwipeListItemMode.activate
							}
							onAction={() =>
								tab === StatusTabs.active ? deactivateDriver(driver.id) : activateDriver(driver.id)
							}
							onEdit={() => navigate(`${DRIVERS_EDIT_URL}/${driver.id}`)}
							onDelete={async () => await removeDriver(driver.id)}
							onClick={() => handleClick(driver.id)}>
							<ListItem key={driver.id}>
								<div className='generic-list-item-children-with-icon'>
									<span>{driver.name}</span>
									{hasAssociations(driver.id) ? <UserFilledIcon /> : null}
								</div>
							</ListItem>
						</SwipeListItem>
					))}
				</SwipeList>
			</GenericList>
		</FormLayout>
	)
}

export default DriverList
