import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const FileCommunicatedIcon: FC<Props> = (props) => {
	const { width = 22, height = 22 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M26.7075 9.2925L19.7075 2.2925C19.6146 2.19967 19.5042 2.12605 19.3829 2.07586C19.2615 2.02568 19.1314 1.9999 19 2H7C6.46957 2 5.96086 2.21071 5.58579 2.58579C5.21071 2.96086 5 3.46957 5 4V15C5 15.2652 5.10536 15.5196 5.29289 15.7071C5.48043 15.8946 5.73478 16 6 16C6.26522 16 6.51957 15.8946 6.70711 15.7071C6.89464 15.5196 7 15.2652 7 15V4H18V10C18 10.2652 18.1054 10.5196 18.2929 10.7071C18.4804 10.8946 18.7348 11 19 11H25V26H22C21.7348 26 21.4804 26.1054 21.2929 26.2929C21.1054 26.4804 21 26.7348 21 27C21 27.2652 21.1054 27.5196 21.2929 27.7071C21.4804 27.8946 21.7348 28 22 28H25C25.5304 28 26.0391 27.7893 26.4142 27.4142C26.7893 27.0391 27 26.5304 27 26V10C27.0001 9.86864 26.9743 9.73855 26.9241 9.61715C26.8739 9.49576 26.8003 9.38544 26.7075 9.2925ZM20 5.41375L23.5863 9H20V5.41375Z'
				fill={colors.violetMt}
			/>
			<path
				d='M18.5955 26.3708C18.2052 25.6986 17.6252 23.7966 17.6252 21.3125C17.6252 19.8207 17.0325 18.3899 15.9776 17.335C14.9227 16.2801 13.492 15.6875 12.0002 15.6875C10.5083 15.6875 9.07758 16.2801 8.02269 17.335C6.96779 18.3899 6.37516 19.8207 6.37516 21.3125C6.37516 23.7973 5.79438 25.6986 5.40415 26.3708C5.30449 26.5417 5.25166 26.7358 5.25098 26.9336C5.2503 27.1315 5.3018 27.326 5.40028 27.4975C5.49876 27.6691 5.64073 27.8117 5.81189 27.9109C5.98305 28.0101 6.17734 28.0624 6.37516 28.0625H9.24461C9.37439 28.6975 9.71952 29.2683 10.2216 29.6781C10.7237 30.088 11.352 30.3119 12.0002 30.3119C12.6483 30.3119 13.2766 30.088 13.7787 29.6781C14.2808 29.2683 14.6259 28.6975 14.7557 28.0625H17.6252C17.8229 28.0622 18.0171 28.0098 18.1882 27.9106C18.3592 27.8114 18.5011 27.6688 18.5995 27.4972C18.6979 27.3257 18.7493 27.1312 18.7486 26.9335C18.7479 26.7357 18.6951 26.5416 18.5955 26.3708ZM12.0002 29.1875C11.6513 29.1874 11.311 29.0792 11.0262 28.8777C10.7414 28.6762 10.526 28.3914 10.4097 28.0625H13.5906C13.4743 28.3914 13.259 28.6762 12.9741 28.8777C12.6893 29.0792 12.349 29.1874 12.0002 29.1875Z'
				fill={colors.violetMt}
			/>
		</svg>
	)
}

export default FileCommunicatedIcon
