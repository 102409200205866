import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const InfoIcon: FC<Props> = (props) => {
	const { width = 28, height = 28 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='Info'>
				<path
					id='Vector'
					d='M14 2.625C11.7502 2.625 9.551 3.29213 7.68039 4.54203C5.80978 5.79193 4.35182 7.56847 3.49088 9.64698C2.62993 11.7255 2.40467 14.0126 2.84357 16.2192C3.28248 18.4257 4.36584 20.4525 5.95667 22.0433C7.54749 23.6342 9.57432 24.7175 11.7809 25.1564C13.9874 25.5953 16.2745 25.3701 18.353 24.5091C20.4315 23.6482 22.2081 22.1902 23.458 20.3196C24.7079 18.449 25.375 16.2498 25.375 14C25.3718 10.9841 24.1724 8.09271 22.0398 5.96018C19.9073 3.82764 17.0159 2.62818 14 2.625ZM14 23.625C12.0964 23.625 10.2355 23.0605 8.65264 22.0029C7.06982 20.9453 5.83616 19.4421 5.10766 17.6833C4.37917 15.9246 4.18856 13.9893 4.55995 12.1223C4.93133 10.2552 5.84802 8.54018 7.1941 7.1941C8.54018 5.84802 10.2552 4.93132 12.1223 4.55994C13.9893 4.18856 15.9246 4.37917 17.6833 5.10766C19.4421 5.83615 20.9453 7.06981 22.0029 8.65264C23.0605 10.2355 23.625 12.0964 23.625 14C23.6221 16.5518 22.6071 18.9983 20.8027 20.8027C18.9983 22.6071 16.5518 23.6221 14 23.625ZM15.75 19.25C15.75 19.4821 15.6578 19.7046 15.4937 19.8687C15.3296 20.0328 15.1071 20.125 14.875 20.125C14.4109 20.125 13.9658 19.9406 13.6376 19.6124C13.3094 19.2842 13.125 18.8391 13.125 18.375V14C12.8929 14 12.6704 13.9078 12.5063 13.7437C12.3422 13.5796 12.25 13.3571 12.25 13.125C12.25 12.8929 12.3422 12.6704 12.5063 12.5063C12.6704 12.3422 12.8929 12.25 13.125 12.25C13.5891 12.25 14.0343 12.4344 14.3624 12.7626C14.6906 13.0908 14.875 13.5359 14.875 14V18.375C15.1071 18.375 15.3296 18.4672 15.4937 18.6313C15.6578 18.7954 15.75 19.0179 15.75 19.25ZM12.25 9.1875C12.25 8.92791 12.327 8.67415 12.4712 8.45831C12.6154 8.24248 12.8204 8.07425 13.0602 7.97491C13.3001 7.87557 13.564 7.84958 13.8186 7.90022C14.0732 7.95086 14.307 8.07587 14.4906 8.25942C14.6741 8.44298 14.7991 8.67684 14.8498 8.93144C14.9004 9.18604 14.8744 9.44994 14.7751 9.68977C14.6758 9.9296 14.5075 10.1346 14.2917 10.2788C14.0759 10.423 13.8221 10.5 13.5625 10.5C13.2144 10.5 12.8806 10.3617 12.6344 10.1156C12.3883 9.86944 12.25 9.5356 12.25 9.1875Z'
					fill={colors.blueMt}
					fillOpacity='0.5'
				/>
			</g>
		</svg>
	)
}

export default InfoIcon
