import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CaretCircleLeft: FC<Props> = (props) => {
	const { width = 19, height = 19 } = props

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'>
			<path
				d='M9.5 1.78125C11.0266 1.78125 12.519 2.23395 13.7883 3.08209C15.0576 3.93024 16.047 5.13574 16.6312 6.54616C17.2154 7.95658 17.3683 9.50856 17.0704 11.0059C16.7726 12.5031 16.0375 13.8785 14.958 14.958C13.8785 16.0375 12.5031 16.7726 11.0058 17.0704C9.50856 17.3683 7.95658 17.2154 6.54616 16.6312C5.13574 16.047 3.93024 15.0576 3.08209 13.7883C2.23394 12.519 1.78125 11.0266 1.78125 9.5C1.78341 7.45352 2.59733 5.49148 4.0444 4.04441C5.49148 2.59733 7.45352 1.78341 9.5 1.78125ZM9.5 16.0312C10.7918 16.0312 12.0545 15.6482 13.1286 14.9305C14.2026 14.2129 15.0398 13.1928 15.5341 11.9994C16.0284 10.806 16.1578 9.49275 15.9058 8.22582C15.6537 6.95888 15.0317 5.79512 14.1183 4.88171C13.2049 3.9683 12.0411 3.34626 10.7742 3.09425C9.50724 2.84224 8.19403 2.97158 7.0006 3.46591C5.80717 3.96025 4.78712 4.79737 4.06946 5.87143C3.3518 6.94549 2.96875 8.20824 2.96875 9.5C2.97071 11.2316 3.65945 12.8917 4.88387 14.1161C6.1083 15.3405 7.7684 16.0293 9.5 16.0312ZM7.29867 9.07992C7.24346 9.13507 7.19967 9.20055 7.16979 9.27263C7.13991 9.34471 7.12453 9.42197 7.12453 9.5C7.12453 9.57803 7.13991 9.65529 7.16979 9.72737C7.19967 9.79945 7.24346 9.86493 7.29867 9.92008L10.2674 12.8888C10.3226 12.944 10.3881 12.9878 10.4602 13.0176C10.5322 13.0475 10.6095 13.0628 10.6875 13.0628C10.7655 13.0628 10.8428 13.0475 10.9148 13.0176C10.9869 12.9878 11.0524 12.944 11.1076 12.8888C11.1627 12.8337 11.2065 12.7682 11.2364 12.6961C11.2662 12.624 11.2816 12.5468 11.2816 12.4688C11.2816 12.3907 11.2662 12.3135 11.2364 12.2414C11.2065 12.1693 11.1627 12.1038 11.1076 12.0487L8.55816 9.5L11.1076 6.95133C11.219 6.83992 11.2816 6.68881 11.2816 6.53125C11.2816 6.37369 11.219 6.22258 11.1076 6.11117C10.9962 5.99976 10.8451 5.93717 10.6875 5.93717C10.5299 5.93717 10.3788 5.99976 10.2674 6.11117L7.29867 9.07992Z'
				fill={colors.violetMt}
			/>
		</svg>
	)
}

export default CaretCircleLeft
