import { FetchResponse } from 'models/CustomFetch'
import { AppNotification } from 'models/Notification'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getNotifications = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<AppNotification[]>>(
			`${endpoints.notifications}`,
			{
				method: 'GET',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getUnReadNotifications = async (): Promise<AppNotification[]> => {
	const token = getUserToken()
	try {
		const queryParams = {
			isRead: false
		}
		const response = await customFetch<FetchResponse<AppNotification[]>>(
			`${endpoints.notifications}`,
			{
				method: 'GET',
				token,
				params: queryParams
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const markNotificationAsRead = async (
	notificationId: AppNotification['id']
): Promise<void> => {
	const token = getUserToken()
	try {
		const body = { isRead: true }
		const response = await customFetch<FetchResponse<void>>(
			`${endpoints.notifications}/${notificationId}`,
			{
				method: 'PATCH',
				bodyReq: body,
				token
			}
		)
		if ('error' in response) throw response
	} catch (error) {
		throw error
	}
}
