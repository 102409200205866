import { CompanyAvailable, FetchResponse, Friend, FriendRequestReceived } from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getAllFriendsService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Friend[]>>(`${endpoints.friends}`, {
			method: 'GET',
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getFriendRequestsSentService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Friend[]>>(
			`${endpoints.friendRequests}/sent`,
			{
				method: 'GET',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getFriendRequestsReceivedService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<FriendRequestReceived[]>>(
			`${endpoints.friendRequests}/received`,
			{
				method: 'GET',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getAllCompaniesAvailableService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<CompanyAvailable[]>>(
			`${endpoints.friendsCompaniesAvailable}`,
			{
				method: 'GET',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const sendFriendRequestService = async (companyId: number, sendingProviderId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(`${endpoints.friendSendRequest}`, {
			method: 'POST',
			token,
			bodyReq: {
				companyId,
				sendingProviderId
			}
		})

		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}

export const acceptFriendRequestService = async (requestId: number, sendingProviderId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(`${endpoints.friendAcceptRequest}`, {
			method: 'POST',
			token,
			bodyReq: {
				requestId,
				sendingProviderId
			}
		})

		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}

export const rejectReceivedRequestService = async (requestId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(
			`${endpoints.friendRejectReceivedRequest}`,
			{
				method: 'POST',
				token,
				bodyReq: {
					requestId
				}
			}
		)

		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}

export const rejectSentRequestService = async (requestId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(
			`${endpoints.friendRejectSentRequest}`,
			{
				method: 'POST',
				token,
				bodyReq: {
					requestId
				}
			}
		)

		if ('error' in response) throw response
	} catch (err) {
		throw err
	}
}
