import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const PlayIcon: FC<Props> = (props) => {
	const { width = 15, height = 15 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				opacity='0.2'
				d='M13.3729 7.89227L4.93066 13.0556C4.86021 13.0983 4.77973 13.1218 4.69733 13.1235C4.61493 13.1252 4.53352 13.1052 4.46132 13.0655C4.38913 13.0257 4.32869 12.9676 4.2861 12.8971C4.24351 12.8265 4.22028 12.746 4.21875 12.6636V2.337C4.22028 2.25459 4.24351 2.17405 4.2861 2.10349C4.32869 2.03292 4.38913 1.97484 4.46132 1.93508C4.53352 1.89533 4.61493 1.87532 4.69733 1.87706C4.77973 1.8788 4.86021 1.90224 4.93066 1.94501L13.3729 7.10829C13.4403 7.1491 13.4961 7.20662 13.5348 7.27529C13.5735 7.34396 13.5938 7.42145 13.5938 7.50028C13.5938 7.5791 13.5735 7.6566 13.5348 7.72526C13.4961 7.79393 13.4403 7.85146 13.3729 7.89227Z'
				fill={colors.violetMt}
			/>
			<path
				d='M13.6172 6.70869L5.175 1.54424C5.03267 1.45708 4.86965 1.4095 4.70278 1.40641C4.53591 1.40332 4.37124 1.44483 4.22578 1.52666C4.08171 1.60722 3.96169 1.72469 3.87806 1.86701C3.79444 2.00933 3.75024 2.17136 3.75 2.33643V12.6642C3.75109 12.9118 3.85045 13.1488 4.02625 13.3232C4.20205 13.4976 4.43989 13.595 4.6875 13.594C4.86032 13.594 5.02979 13.5463 5.17734 13.4563L13.6172 8.29189C13.7529 8.20921 13.865 8.09302 13.9428 7.95449C14.0206 7.81596 14.0614 7.65976 14.0614 7.50088C14.0614 7.342 14.0206 7.18579 13.9428 7.04727C13.865 6.90874 13.7529 6.79255 13.6172 6.70986V6.70869ZM4.6875 12.653V2.34404L13.115 7.50029L4.6875 12.653Z'
				fill={colors.violetMt}
			/>
		</svg>
	)
}

export default PlayIcon
