import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	providerAssociationsQueryKey,
	providerQueryKey,
	providersQueryKey
} from 'constants/reactQueryKeys'
import { Provider, ProviderAssociations } from 'models/Provider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	createProviderService,
	deleteAssociationsByProviderId,
	deleteProviderById,
	getProviderAssociations,
	getProviderById,
	updateProviderService
} from 'services/providers'
import { createProviderAssociationsByUserService } from 'services/user'
import { resetAndSetAssociationsToProvider } from '../services/providers'

const useProvider = (providerId?: number) => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	// GET PROVIDER (Single)
	const {
		data: provider,
		isLoading: isLoadingProvider,
		error: providerError,
		isError: isErrorProvider,
		isSuccess: isSuccessProvider
	} = useQuery({
		queryKey: [providerQueryKey, providerId],
		queryFn: () => getProviderById(providerId!),
		enabled: !!providerId,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	// GET PROVIDER ASSOCIATIONS
	const {
		data: providerAssociations,
		isLoading: isLoadingAssociations,
		error: associationsError,
		isError: isErrorAssociations,
		refetch: refetchAssociations
	} = useQuery({
		queryKey: [providerAssociationsQueryKey],
		queryFn: getProviderAssociations,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true,
		enabled: isSuccessProvider
	})

	useEffect(() => {
		if (isErrorProvider && providerError) {
			setErrorMessage(t('provider.provider_error'))
		}
		if (isErrorAssociations && associationsError) {
			setErrorMessage(t('provider.associations_error'))
		}
	}, [isErrorProvider, providerError, isErrorAssociations, associationsError, t])

	// Invalidate Queries
	const invalidateProviderQueries = (providerId?: number) => {
		if (providerId) {
			queryClient.invalidateQueries({
				queryKey: [providerQueryKey, providerId]
			})
		}
		queryClient.invalidateQueries({
			queryKey: [providersQueryKey]
		})

		queryClient.invalidateQueries({
			queryKey: [providerAssociationsQueryKey]
		})
	}

	// CREATE PROVIDER
	const { mutateAsync: createProvider } = useMutation({
		mutationFn: createProviderService,
		onSuccess: () => {
			invalidateProviderQueries()
		},
		onError: (error: unknown) => {
			setErrorMessage(t('provider.error_create'))
			console.error('Error creando proveedor:', error)
		}
	})

	// UPDATE PROVIDER
	const { mutateAsync: updateProvider } = useMutation({
		mutationFn: (data: { provider: Partial<Provider>; providerId: number }) =>
			updateProviderService(data.provider, data.providerId),
		onSuccess: (_data, variables) => {
			invalidateProviderQueries(variables.providerId)
		},
		onError: (error: unknown) => {
			setErrorMessage(t('provider.error_update'))
			console.error('Error actualizando proveedor:', error)
		}
	})

	// DELETE PROVIDER
	const { mutateAsync: removeProvider } = useMutation({
		mutationFn: deleteProviderById,
		onSuccess: (_data, providerId) => {
			invalidateProviderQueries(providerId)
		},
		onError: () => {
			setErrorMessage(t('provider.delete_provider_error'))
		}
	})

	// resetAndSetAssociationsToProvider
	const { mutateAsync: resetAndSetAssociations } = useMutation({
		mutationFn: (data: { providerId: number; userIds: number[] }) =>
			resetAndSetAssociationsToProvider(data.providerId, data.userIds),
		onSuccess: () => {
			invalidateProviderQueries()
		},
		onError: () => {
			setErrorMessage(t('provider.reset_set_associations_error'))
		}
	})

	// Activate Provider
	const activateProvider = async (providerId: number) => {
		try {
			await updateProvider({ provider: { isActive: true }, providerId })
		} catch (e) {
			setErrorMessage(t('provider.activate_provider_error'))
			console.error(e)
		}
	}

	const deactivateProvider = async (providerId: number) => {
		try {
			await updateProvider({ provider: { isActive: false }, providerId })
		} catch (e) {
			setErrorMessage(t('provider.deactivate_provider_error'))
			console.error(e)
		}
	}

	const deleteAssociationsByProvider = async (providerId: number) => {
		try {
			await deleteAssociationsByProviderId(providerId)
		} catch (e) {
			setErrorMessage(t('provider.delete_associations_error'))
			console.error(e)
		}
	}

	const createAssociationsByUserId = async (userId: number, providerIds: number[]) => {
		try {
			return await createProviderAssociationsByUserService(userId, providerIds)
		} catch (e) {
			setErrorMessage(t('provider.create_associations_error'))
			console.error(e)
		}
	}

	const getAssociationsByUserId = (userId: number, associations: ProviderAssociations[]) => {
		return associations?.filter((association) => association.userId === userId) || []
	}

	const getProviderByIdFromStore = (providerId: number) => {
		return queryClient
			.getQueryData<Provider[]>([providersQueryKey])
			?.find((p) => p.id === providerId)
	}

	const hasAssociations = (providerId: number) => {
		return (
			providerAssociations?.some((association) => association.providerId === providerId) || false
		)
	}

	const getAssociationsByProviderId = (providerId: number) => {
		return (
			providerAssociations?.filter((association) => association.providerId === providerId) || []
		)
	}

	return {
		provider: provider || null,
		providerAssociations: providerAssociations || [],
		loading: isLoadingProvider || isLoadingAssociations,
		error: errorMessage,
		hasAssociations,
		createProvider,
		updateProvider,
		removeProvider,
		activateProvider,
		deactivateProvider,
		getAssociationsByUserId,
		getProviderByIdFromStore,
		deleteAssociationsByProvider,
		resetAndSetAssociations,
		getAssociationsByProviderId,
		createAssociationsByUserId,
		refetchAssociations
	}
}

export default useProvider
