import { ENVIRONMENT } from 'constants/environment'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

interface StepModeState {
	withStepsMode: boolean
	setWithStepsMode: (activated: boolean) => void
}

export const useStepModeStore = create<StepModeState>()(
	devtools(
		persist(
			(set) => ({
				withStepsMode: true,
				setWithStepsMode: (activated: boolean) => {
					set({ withStepsMode: activated })
				}
			}),
			{
				name: 'withStepsMode',
				storage: createJSONStorage(() => localStorage)
			}
		),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'withStepsMode'
		}
	)
)
