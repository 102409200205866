import Accordion from 'components/elements/Accordion/Accordion'
import AccordionItem from 'components/elements/Accordion/AccordionItem'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Input from 'components/elements/Input/Input'
import InputSwitch from 'components/elements/Input/InputSwitch'
import Loading from 'components/elements/Loading/Loading'
import SelectRoleOptions from 'components/elements/SelectOptions/SelectRoleOptions/SelectRoleOptions'
import { USERS_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useDriver from 'hooks/useDriver'
import useProvider from 'hooks/useProvider'
import useToast from 'hooks/useToast'
import useUsers from 'hooks/useUser'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { NonAdminRoleKeys, UserForm, UserRoles } from 'models/User'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { emptyString } from 'utils/common'
import { getFormikError } from 'utils/formikUtils'
import * as Yup from 'yup'
import AssociationsDriver from './AssociationsDriver'
import AssociationsProvider from './AssociationsProvider'

const UserEdit: FC = () => {
	const { userId } = useParams()
	const { user, currentUser, updateUser } = useUsers(parseInt(userId ?? emptyString))
	const {
		createAssociationsByUserId: createProviderAssociationsByUserId,
		refetchAssociations: refetchProviderAssociations
	} = useProvider()
	const {
		createAssociationsByUserId: createDriverAssociationsByUserId,
		refetchAssociations: refetchDriverAssociations
	} = useDriver()
	const { showSuccessToast, showBackendErrorToast } = useToast()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [selectedAssociations, setSelectedAssociations] = useState<number[]>([])
	const [hasSelectedAssociations, setHasSelectedAssociations] = useState(false)
	const [isUserLoaded, setIsUserLoaded] = useState(false)

	const onSubmit = async (values: UserForm) => {
		if (!userId || !user) return

		try {
			setLoading(true)

			if (currentUser?.id === user.id) await updateUser({ user: values, userId: currentUser.id })
			else
				await updateUser({
					user: values,
					userId: user.id
				})

			if (hasSelectedAssociations) {
				if (formik.values.userTypeId === UserRoles.provider) {
					await createProviderAssociationsByUserId(user.id, selectedAssociations)
				} else if (formik.values.userTypeId === UserRoles.driver) {
					await createDriverAssociationsByUserId(user.id, selectedAssociations)
				}
			}

			showSuccessToast({ description: t('user.success_update') })
			navigate(`${USERS_URL}/${userId}`)
		} catch (error) {
			console.error(error)
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
			setHasSelectedAssociations(false)
		}
	}

	const formik = useFormik<UserForm>({
		initialValues: {
			name: emptyString,
			lastName: emptyString,
			email: emptyString,
			isActive: true,
			userTypeId: UserRoles.provider
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t('errors.required_m', { field: t('user.name') })),
			lastName: Yup.string().required(t('errors.required_m', { field: t('user.lastName') })),
			email: Yup.string().email(t('errors.mail_not_valid'))
		}),
		onSubmit
	})

	const onChangeAssociations = (associations: number[]) => {
		setHasSelectedAssociations(true)
		setSelectedAssociations(associations)
	}

	useEffect(() => {
		if (!user || isUserLoaded) return
		formik.setValues({
			name: user.name,
			lastName: user.lastName,
			email: user.email,
			isActive: user.isActive,
			userTypeId: user.userTypeId
		})
		setIsUserLoaded(true)
	}, [user, isUserLoaded])

	useEffect(() => {
		if (user && user.userTypeId === UserRoles.provider) {
			refetchProviderAssociations()
		} else if (user && user.userTypeId === UserRoles.driver) {
			refetchDriverAssociations()
		}
	}, [user])

	if (loading) return <Loading fullscreen />

	return (
		<FormLayout
			title={t('user.edit_title')}
			leftButton={<ButtonBack url={`${USERS_URL}/${userId}`} />}
			footer={
				<ButtonComponent fullWidth onClick={formik.handleSubmit}>
					{t('general.save_changes')}
				</ButtonComponent>
			}>
			<Accordion>
				<AccordionItem title={t('user.user_information')} isActive={true} isToggleText>
					<Input
						id='name'
						label={t('user.name')}
						valueSelected={formik.values.name}
						onChange={formik.handleChange}
						error={getFormikError(formik, 'name')}
					/>
					<Input
						id='lastName'
						label={t('user.lastName')}
						valueSelected={formik.values.lastName}
						onChange={formik.handleChange}
						error={getFormikError(formik, 'lastName')}
					/>
					<Input
						id='email'
						label={t('user.email')}
						valueSelected={formik.values.email}
						onChange={formik.handleChange}
						error={getFormikError(formik, 'email')}
					/>
				</AccordionItem>
				<AccordionItem title={t('user.user_status')} isToggleText>
					<InputSwitch
						id='isActive'
						label={t('user.active')}
						value={formik.values.isActive}
						onChange={(value) => formik.setFieldValue('isActive', value)}
						dataTest='switch-active'
					/>
				</AccordionItem>
				{user &&
				formik.values.userTypeId !== UserRoles.admin &&
				formik.values.userTypeId !== UserRoles['super-admin'] ? (
					<AccordionItem title={t('user.user_role')} isToggleText>
						<SelectRoleOptions
							id='userTypeId'
							handleRoleSelected={(role) => formik.setFieldValue('userTypeId', role)}
							selectedId={formik.values.userTypeId as NonAdminRoleKeys}
							error={getFormikError(formik, 'userTypeId')}
						/>
					</AccordionItem>
				) : (
					<></>
				)}
				{user && formik.values.userTypeId === UserRoles.provider ? (
					<AccordionItem title={t('user.associated_providers')} isToggleText>
						<AssociationsProvider user={user} onChangeAssociations={onChangeAssociations} />
					</AccordionItem>
				) : (
					<></>
				)}
				{user && formik.values.userTypeId === UserRoles.driver ? (
					<AccordionItem title={t('user.associated_drivers')} isToggleText>
						<AssociationsDriver user={user} onChangeAssociations={onChangeAssociations} />
					</AccordionItem>
				) : (
					<></>
				)}
			</Accordion>
		</FormLayout>
	)
}

export default UserEdit
