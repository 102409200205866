import { TrashFilledIcon } from 'assets/svgs'
import TrashIcon from 'assets/svgs/TrashIcon'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import InputSwitch from 'components/elements/Input/InputSwitch'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { ERROR_URL, USERS_EDIT_URL, USERS_URL } from 'constants/routes'
import useDriver from 'hooks/useDriver'
import useDrivers from 'hooks/useDrivers'
import useProvider from 'hooks/useProvider'
import useProviders from 'hooks/useProviders'
import useUsers from 'hooks/useUser'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { UserRoles } from 'models/User'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { emptyString } from 'utils/common'
import RoleTag from './RoleTag/RoleTag'

const UserDetails: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { userId } = useParams<{ userId: string }>()
	const userIdNumber = parseInt(userId ?? emptyString) || undefined
	const { user, loading, error, removeUser } = useUsers(userIdNumber)
	const provider = useProvider()
	const driver = useDriver()
	const { drivers } = useDrivers()
	const { providers } = useProviders()

	const getAssociatedProviders = () => {
		if (!userIdNumber) return []
		return provider.providerAssociations?.filter(
			(association) => association.userId === userIdNumber
		)
	}
	const getAssociatedDrivers = () => {
		if (!userIdNumber) return []
		return driver.driverAssociations?.filter((association) => association.userId === userIdNumber)
	}

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

	const closeDeleteModal = () => setIsDeleteModalVisible(false)

	const deleteUser = async () => {
		if (!userIdNumber) return
		await removeUser(userIdNumber)
		navigate(USERS_URL)
	}

	useEffect(() => {
		driver.refetchAssociations()
		provider.refetchAssociations()
	}, [userIdNumber])

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: USERS_URL } })
	if (!user) return <div>{t('user.not_found')}</div>

	return (
		<>
			<FormLayout
				title={t('user.title')}
				leftButton={<ButtonBack url={USERS_URL} />}
				rightButton={
					<ButtonComponent
						variant='only-text'
						onClick={() => navigate(`${USERS_EDIT_URL}/${userId}`)}>
						{t('general.edit')}
					</ButtonComponent>
				}>
				<FormSectionLayout title={t('user.user_details')}>
					<DetailsCard>
						<DetailsCardItem title={t('user.details.fullname')}>
							{user.name} {user.lastName}
						</DetailsCardItem>
						<DetailsCardItem title={t('user.details.email')}>{user.email}</DetailsCardItem>
						<DetailsCardItem title={t('user.details.role')}>
							<RoleTag roleId={user.userTypeId as UserRoles} />
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<FormSectionLayout title={t('user.user_status')}>
					<DetailsCard>
						<DetailsCardItem title={t('user.status')}>
							<InputSwitch
								id='status'
								label={t('user.active')}
								value={user.isActive}
								dataTest='switch-active'
							/>
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				{user.userTypeId === UserRoles.driver ? (
					<FormSectionLayout title={t('user.associated_driver')}>
						<DetailsCard>
							{getAssociatedDrivers()?.length ? (
								getAssociatedDrivers()?.map((association) => {
									const associatedDriver = drivers.find(
										(driver) => driver.id === association.driverId
									)
									return (
										<DetailsCardItem key={association.id} title={t('user.details.name')}>
											{associatedDriver ? associatedDriver.name : t('user.no_name_found')}
										</DetailsCardItem>
									)
								})
							) : (
								<DetailsCardItem title={t('user.name')}>
									{t('user.details.none_associated_driver')}
								</DetailsCardItem>
							)}
						</DetailsCard>
					</FormSectionLayout>
				) : null}
				{user.userTypeId === UserRoles.provider ? (
					<FormSectionLayout title={t('user.associated_provider')}>
						<DetailsCard>
							{getAssociatedProviders().length ? (
								getAssociatedProviders().map((association) => {
									const associatedProvider = providers.find(
										(provider) => provider.id === association.providerId
									)
									return (
										<DetailsCardItem key={association.id} title={t('user.details.name')}>
											{associatedProvider ? associatedProvider.name : t('user.no_name_found')}
										</DetailsCardItem>
									)
								})
							) : (
								<DetailsCardItem title={t('user.name')}>
									{t('user.details.none_associated_provider')}
								</DetailsCardItem>
							)}
						</DetailsCard>
					</FormSectionLayout>
				) : null}
				<ButtonComponent
					className='resource-view-delete-button'
					onClick={() => setIsDeleteModalVisible(true)}>
					<TrashFilledIcon />
					<span className='body1'>{t('user.delete_user_button')}</span>
				</ButtonComponent>
			</FormLayout>
			<ModalActions
				type='error'
				icon={<TrashIcon />}
				title={t('user.delete_modal.title')}
				description={t('user.delete_modal.description')}
				primaryButton={{
					text: t('user.delete_modal.primary_button'),
					className: 'modal-delete-resource-primary-button',
					onClick: deleteUser
				}}
				secondaryButton={{
					text: t('general.cancel'),
					className: 'modal-delete-resource-secondary-button',
					onClick: closeDeleteModal
				}}
				isVisible={isDeleteModalVisible}
				onClose={closeDeleteModal}
			/>
		</>
	)
}

export default UserDetails
