import { CaretCircleRight, CreditCardIcon, ReceiptIcon, WhatsappIcon } from 'assets/svgs'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import colors from 'constants/colors'
import { MY_ACCOUNT_URL, RECEIPTS_URL, SUBSCRIPTIONS_URL } from 'constants/routes'
import useCompany from 'hooks/useCompany'
import useSupport from 'hooks/useSupport'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { MySubscription as MySubscriptionType } from 'models/Company'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { getMySubscriptionService } from 'services/company'
import { emptyString, zeroAmount } from 'utils/common'
import './MySubscription.scss'
import SubscriptionCard, { SubscriptionCardMode } from './SubscriptionCard/SubscriptionCard'
import SubscriptionCardContent from './SubscriptionCard/SubscriptionCardContent/SubscriptionCardContent'
import SubscriptionTrialCardContent from './SubscriptionCard/SubscriptionCardContent/SubscriptionTrialCardContent'

const actionIconBgColor = colors.lightBlueMt
const actionIconWhatsappBgColor = colors.actionIconWhatsappColor

const MySubscription: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { company, loading } = useCompany()
	const handleSupport = useSupport()

	const [lastSubscription, setLastSubscription] = useState<MySubscriptionType>()

	useEffect(() => {
		fetchMySubscriptions()
	}, [])

	const fetchMySubscriptions = async () => {
		const subscriptions = await getMySubscriptionService()
		if (!subscriptions.length) return
		const lastItemKey = subscriptions.length - 1
		setLastSubscription(subscriptions[lastItemKey])
	}

	const subscriptionsDaysLeft = () => {
		if (!company) return zeroAmount
		const contractEnd = company.contractEnd
		if (!contractEnd) return zeroAmount
		const contractEndDate = new Date(contractEnd)
		const currentDate = new Date()

		const diffTime = contractEndDate.getTime() - currentDate.getTime()

		const millisecondsInASecond = 1000
		const secondsInAMinute = 60
		const minutesInAnHour = 60
		const hoursInADay = 24

		const millisecondsInADay =
			millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay

		const diffDays = Math.ceil(diffTime / millisecondsInADay)
		return diffDays
	}

	if (loading) return <Loading fullscreen />

	return (
		<FormLayout
			title={t('settings.my_account.subscription.title')}
			leftButton={<ButtonBack url={MY_ACCOUNT_URL} />}>
			<FormSectionLayout>
				{lastSubscription ? (
					<SubscriptionCard>
						<SubscriptionCardContent
							title={lastSubscription.subscriptionTypeName}
							price={lastSubscription.amountVatIncluded}
							details={[t(`settings.my_account.subscription.${lastSubscription.interval}`)]}
						/>
					</SubscriptionCard>
				) : (
					<SubscriptionCard mode={SubscriptionCardMode.DARK}>
						<SubscriptionTrialCardContent
							title={t('settings.my_account.subscription.free_subscription')}
							detail={`${t('settings.my_account.subscription.day_left', {
								count: subscriptionsDaysLeft()
							})}`}
						/>
					</SubscriptionCard>
				)}
				<div className='my-subscription-section'>
					{lastSubscription ? (
						<p className='my-subscription-section-info body2'>
							<Trans
								i18nKey='settings.my_account.subscription.subscription_info_link'
								components={{
									a: <Link to={emptyString} className='my-subscription-section-info-link' />
								}}
							/>
						</p>
					) : (
						<ButtonComponent
							className='my-subscription-plans-button'
							variant='text'
							rightIcon={<CaretCircleRight />}
							onClick={() => navigate(SUBSCRIPTIONS_URL)}>
							{t('settings.my_account.subscription.see_available_plans')}
						</ButtonComponent>
					)}
				</div>
				{lastSubscription && (
					<>
						<ActionButton
							icon={<ReceiptIcon />}
							backGroundIcon={actionIconBgColor}
							onClick={() => navigate(RECEIPTS_URL)}>
							{t('settings.my_account.subscription.my_receipts')}
						</ActionButton>
						<ActionButton
							icon={<CreditCardIcon />}
							backGroundIcon={actionIconBgColor}
							onClick={() => null}>
							{t('settings.my_account.subscription.my_payment_details')}
						</ActionButton>
					</>
				)}
				<ActionButton
					icon={<WhatsappIcon />}
					backGroundIcon={actionIconWhatsappBgColor}
					noRightArrow
					onClick={handleSupport}>
					{t('settings.my_account.support')}
				</ActionButton>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default MySubscription
