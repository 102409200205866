import ButtonComponent from 'components/elements/Button/Button'
import InputDate from 'components/elements/Input/InputDate'
import { VEHICLES_LIST_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useVehicle from 'hooks/useVehicle'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { VehicleTechinicalPost, VehicleTechnicalDetailsForm } from 'models/Vehicle'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { dateToSQL } from 'utils/dateUtils'
import { getFormikError } from 'utils/formikUtils'
import VehicleCreationSuccess from './VehicleCreationSuccess'

const VehicleNewTechnicalDetails: FC = () => {
	const { vehicleId } = useParams() as { vehicleId: string }
	const vehicleIdNumber = parseInt(vehicleId)
	const { updateVehicleTechnical } = useVehicle(vehicleIdNumber)
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [showSuccessMenu, setShowSuccessMenu] = useState<boolean>(false)

	const formik = useFormik<VehicleTechnicalDetailsForm>({
		initialValues: {
			cardDate: undefined,
			itvDate: undefined,
			oilDate: undefined,
			tiresDate: undefined
		},
		onSubmit: async (values) => {
			if (!vehicleIdNumber) return

			const vehicleTechnical: Partial<VehicleTechinicalPost> = {
				cardDate: values.cardDate ? dateToSQL(values.cardDate) : undefined,
				itvDate: values.itvDate ? dateToSQL(values.itvDate) : undefined,
				oilDate: values.oilDate ? dateToSQL(values.oilDate) : undefined,
				tiresDate: values.tiresDate ? dateToSQL(values.tiresDate) : undefined
			}
			try {
				await updateVehicleTechnical({ vehicle: vehicleTechnical, vehicleId: vehicleIdNumber })
				toast.success(t('vehicle.success_update'))
				setShowSuccessMenu(true)
			} catch (error) {
				toast.error(t('vehicle.error_update'))
			}
		}
	})

	return (
		<>
			<FormLayout
				title={t('vehicle.new_technical_details_title')}
				leftButton={
					<ButtonComponent onClick={() => navigate(VEHICLES_LIST_URL)} variant='only-text'>
						{t('general.cancel')}
					</ButtonComponent>
				}
				footer={
					<ButtonComponent type='submit' onClick={formik.handleSubmit} fullWidth>
						{t('general.continue')}
					</ButtonComponent>
				}>
				<FormSectionLayout
					title={t('vehicle.maintenance_tooltip_title')}
					tooltip={{
						title: t('vehicle.maintenance_tooltip_title'),
						description: t('vehicle.maintenance_tooltip_description')
					}}>
					<InputDate
						id='cardDate'
						label={t('vehicle.transport_card_label')}
						value={formik.values.cardDate}
						onSelect={(date) => formik.setFieldValue('cardDate', date)}
						error={getFormikError(formik, 'cardDate')}
					/>
					<InputDate
						id='itvDate'
						label={t('vehicle.itv')}
						value={formik.values.itvDate}
						onSelect={(date) => formik.setFieldValue('itvDate', date)}
						error={getFormikError(formik, 'itvDate')}
					/>
					<InputDate
						id='oilDate'
						label={t('vehicle.oil_check_label')}
						value={formik.values.oilDate}
						onSelect={(date) => formik.setFieldValue('oilDate', date)}
						error={getFormikError(formik, 'oilDate')}
					/>
					<InputDate
						id='tiresDate'
						label={t('vehicle.change_tires_label')}
						value={formik.values.tiresDate}
						onSelect={(date) => formik.setFieldValue('tiresDate', date)}
						error={getFormikError(formik, 'tiresDate')}
					/>
				</FormSectionLayout>
			</FormLayout>
			{showSuccessMenu && vehicleIdNumber ? (
				<VehicleCreationSuccess vehicleId={vehicleIdNumber} />
			) : null}
		</>
	)
}

export default VehicleNewTechnicalDetails
