import { useQuery } from '@tanstack/react-query'
import { providersQueryKey } from 'constants/reactQueryKeys'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getAllProvidersService } from 'services/providers'

const useProviders = () => {
	const { t } = useTranslation()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	// GET ALL PROVIDERS
	const {
		data: providers,
		isLoading: isLoadingProviders,
		error: providersError,
		isError: isErrorProviders
	} = useQuery({
		queryKey: [providersQueryKey],
		queryFn: getAllProvidersService,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	useEffect(() => {
		if (isErrorProviders && providersError) {
			setErrorMessage(t('provider.providers_error'))
		}
	}, [isErrorProviders, providersError, t])

	return {
		providers: providers || [],
		loading: isLoadingProviders,
		error: errorMessage
	}
}

export default useProviders
