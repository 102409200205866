import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CreditCardIcon: FC<Props> = (props) => {
	const { width = 23, height = 23 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 23 23'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				opacity='0.2'
				d='M20.8438 8.625V17.25C20.8438 17.4406 20.768 17.6234 20.6332 17.7582C20.4984 17.893 20.3156 17.9688 20.125 17.9688H2.875C2.68438 17.9688 2.50156 17.893 2.36677 17.7582C2.23198 17.6234 2.15625 17.4406 2.15625 17.25V8.625H20.8438Z'
				fill='url(#paint0_linear_413_14485)'
			/>
			<path
				d='M20.125 4.3125H2.875C2.49375 4.3125 2.12812 4.46395 1.85853 4.73353C1.58895 5.00312 1.4375 5.36875 1.4375 5.75V17.25C1.4375 17.6312 1.58895 17.9969 1.85853 18.2665C2.12812 18.536 2.49375 18.6875 2.875 18.6875H20.125C20.5062 18.6875 20.8719 18.536 21.1415 18.2665C21.411 17.9969 21.5625 17.6312 21.5625 17.25V5.75C21.5625 5.36875 21.411 5.00312 21.1415 4.73353C20.8719 4.46395 20.5062 4.3125 20.125 4.3125ZM20.125 5.75V7.90625H2.875V5.75H20.125ZM20.125 17.25H2.875V9.34375H20.125V17.25ZM18.6875 15.0938C18.6875 15.2844 18.6118 15.4672 18.477 15.602C18.3422 15.7368 18.1594 15.8125 17.9688 15.8125H15.0938C14.9031 15.8125 14.7203 15.7368 14.5855 15.602C14.4507 15.4672 14.375 15.2844 14.375 15.0938C14.375 14.9031 14.4507 14.7203 14.5855 14.5855C14.7203 14.4507 14.9031 14.375 15.0938 14.375H17.9688C18.1594 14.375 18.3422 14.4507 18.477 14.5855C18.6118 14.7203 18.6875 14.9031 18.6875 15.0938ZM12.9375 15.0938C12.9375 15.2844 12.8618 15.4672 12.727 15.602C12.5922 15.7368 12.4094 15.8125 12.2188 15.8125H10.7812C10.5906 15.8125 10.4078 15.7368 10.273 15.602C10.1382 15.4672 10.0625 15.2844 10.0625 15.0938C10.0625 14.9031 10.1382 14.7203 10.273 14.5855C10.4078 14.4507 10.5906 14.375 10.7812 14.375H12.2188C12.4094 14.375 12.5922 14.4507 12.727 14.5855C12.8618 14.7203 12.9375 14.9031 12.9375 15.0938Z'
				fill='url(#paint1_linear_413_14485)'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_413_14485'
					x1='2.15625'
					y1='9.50314'
					x2='16.053'
					y2='23.1281'
					gradientUnits='userSpaceOnUse'>
					<stop offset='0.0001' stopColor={colors.blueMt} />
					<stop offset='1' stopColor={colors.violetMt} />
				</linearGradient>
				<linearGradient
					id='paint1_linear_413_14485'
					x1='1.4375'
					y1='5.66348'
					x2='21.3909'
					y2='19.3577'
					gradientUnits='userSpaceOnUse'>
					<stop offset='0.0001' stopColor={colors.blueMt} />
					<stop offset='1' stopColor={colors.violetMt} />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default CreditCardIcon
