import { UserFilledIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import GenericList from 'components/elements/GenericList/GenericList'
import ListItem from 'components/elements/List/ListItem/ListItem'
import SwipeList from 'components/elements/List/SwipeList/SwipeList'
import SwipeListItem, {
	SwipeListItemMode
} from 'components/elements/List/SwipeList/SwipeListItem/SwipeListItem'
import Loading from 'components/elements/Loading/Loading'
import {
	ERROR_URL,
	PROVIDERS_EDIT_URL,
	PROVIDERS_NEW_URL,
	PROVIDERS_VIEW_URL,
	RESOURCES_URL
} from 'constants/routes'
import useProvider from 'hooks/useProvider'
import useProviders from 'hooks/useProviders'
import useSearch from 'hooks/useSearch'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { StatusTabs } from 'models/Elements'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import './ProviderList.scss'

const ProviderList = () => {
	const { providers, loading, error } = useProviders()
	const {
		hasAssociations,
		removeProvider,
		activateProvider,
		deactivateProvider,
		refetchAssociations
	} = useProvider()
	const [tab, setTab] = useState(StatusTabs.active)
	const [searchTerm, setSearchTerm] = useState(emptyString)
	const hasItems = providers.filter((provider) => provider.isManualInvoice === false).length > 0
	const navigate = useNavigate()
	const { t } = useTranslation()

	const filteredProviders = useMemo(() => {
		return providers
			.filter((provider) => (tab === StatusTabs.active ? provider.isActive : !provider.isActive))
			.filter((provider) => provider.isManualInvoice === false)
			.filter((provider) => provider.isVisible)
	}, [providers, tab])

	const previousRoute = useMemo(() => {
		return RESOURCES_URL
	}, [])

	const searchResults = useSearch(filteredProviders, ['name'], searchTerm)

	useEffect(() => {
		refetchAssociations()
	}, [])

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })

	const handleClick = (id: number) => {
		navigate(`${PROVIDERS_VIEW_URL}/${id}`)
	}

	return (
		<FormLayout
			title={t('provider.list_title')}
			leftButton={<ButtonBack url={previousRoute} />}
			rightButton={
				<ButtonComponent onClick={() => navigate(PROVIDERS_NEW_URL)} variant='only-text'>
					<strong>{t('general.add')}</strong>
				</ButtonComponent>
			}>
			<GenericList
				hasItems={hasItems}
				searchTerm={searchTerm}
				hasResults={!!searchResults.length}
				setSearchTerm={setSearchTerm}
				tabSelected={tab}
				setTab={setTab}
				noItemsText={
					<Trans
						i18nKey='provider.no_items_text'
						components={{
							span: <span />
						}}
					/>
				}
				noResultsText={
					<Trans
						i18nKey='provider.no_results_text'
						components={{
							span: <span />
						}}
						values={{ query: searchTerm }}
					/>
				}
				searchPlaceholder={t('provider.search_placeholder')}>
				<SwipeList>
					{searchResults.map((provider) => (
						<SwipeListItem
							key={provider.id}
							mode={
								tab === StatusTabs.active
									? SwipeListItemMode.deactivate
									: SwipeListItemMode.activate
							}
							onAction={() =>
								tab === StatusTabs.active
									? deactivateProvider(provider.id)
									: activateProvider(provider.id)
							}
							onEdit={() => navigate(`${PROVIDERS_EDIT_URL}/${provider.id}`)}
							onDelete={async () => await removeProvider(provider.id)}
							onClick={() => handleClick(provider.id)}>
							<ListItem>
								<div className='generic-list-item-children-with-icon'>
									<span>{provider.name}</span>
									{hasAssociations(provider.id) ? <UserFilledIcon /> : null}
								</div>
							</ListItem>
						</SwipeListItem>
					))}
				</SwipeList>
			</GenericList>
		</FormLayout>
	)
}

export default ProviderList
