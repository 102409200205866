import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const PencilSimpleLineIcon: FC<Props> = (props) => {
	const { width = 25, height = 25 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='PencilSimpleLine'>
				<path
					id='Vector'
					d='M22.1992 7.1648L17.835 2.80152C17.6899 2.65639 17.5176 2.54127 17.328 2.46272C17.1384 2.38418 16.9352 2.34375 16.73 2.34375C16.5248 2.34375 16.3216 2.38418 16.132 2.46272C15.9424 2.54127 15.7701 2.65639 15.625 2.80152L3.58302 14.8435C3.43729 14.9881 3.32176 15.1602 3.24313 15.3498C3.1645 15.5394 3.12435 15.7427 3.12501 15.948V20.3123C3.12501 20.7267 3.28963 21.1241 3.58265 21.4171C3.87568 21.7101 4.27311 21.8748 4.68751 21.8748H21.0938C21.301 21.8748 21.4997 21.7925 21.6462 21.6459C21.7927 21.4994 21.875 21.3007 21.875 21.0935C21.875 20.8863 21.7927 20.6876 21.6462 20.5411C21.4997 20.3946 21.301 20.3123 21.0938 20.3123H11.2617L22.1992 9.37476C22.3444 9.22967 22.4595 9.0574 22.538 8.86781C22.6166 8.67821 22.657 8.475 22.657 8.26978C22.657 8.06456 22.6166 7.86135 22.538 7.67176C22.4595 7.48216 22.3444 7.3099 22.1992 7.1648ZM18.75 10.614L14.3867 6.24976L16.7305 3.90601L21.0938 8.27027L18.75 10.614Z'
					fill={colors.lightBlueMt}
				/>
			</g>
		</svg>
	)
}

export default PencilSimpleLineIcon
