import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dailyTransfersQueryKey } from 'constants/reactQueryKeys'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createTransferService } from 'services/transfers'

export const useCreateTransfer = () => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	// CREATE TRANSFER
	const { mutateAsync: createTransfer } = useMutation({
		mutationFn: createTransferService,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [dailyTransfersQueryKey]
			})
		},
		onError: () => {
			setErrorMessage(t('transfer.error_create'))
		}
	})

	return {
		createTransfer,
		error: errorMessage
	}
}
