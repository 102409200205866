import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFomento from 'hooks/useFomento'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { FomentoPost, StepFomentoForm } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFomentoStore } from 'stores/useFomentoStore'
import { emptyString } from 'utils/common'
import StepFomentoInputs from '../StepInputs/StepFomentoInputs'

const StepFomento = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const fomentoState = useFomentoStore()
	const { getProvinceCode, getMunicipalityCode } = useFomento()

	const formik = useFormik<StepFomentoForm>({
		initialValues: {
			provinceOriginId: null,
			municipalityOriginId: null,
			provinceDestinationId: null,
			municipalityDestinationId: null,
			provinceDistantId: null,
			municipalityDistantId: null,
			destinationFreely: false,
			addressDistant: emptyString,
			finishedAt: undefined
		},
		onSubmit: (values) => {
			const fomentoForm: FomentoPost = {
				provinceOrigin: getProvinceCode(values.provinceOriginId),
				municipalityOrigin: getMunicipalityCode(values.municipalityOriginId),
				provinceDestination: getProvinceCode(values.provinceDestinationId),
				municipalityDestination: getMunicipalityCode(values.municipalityDestinationId),
				provinceDistant: getProvinceCode(values.provinceDistantId),
				municipalityDistant: getMunicipalityCode(values.municipalityDistantId),
				destinationFreely: values.destinationFreely,
				addressDistant: values.addressDistant,
				finishedAt: values.finishedAt ? values.finishedAt : undefined
			}
			fomentoState.setActualFomentoCreation(fomentoForm)
			navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.PRICES.toLocaleLowerCase()}`)
		}
	})

	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={4}>
			<FormSectionLayout title={t('transfer.create_steps.step_fomento.h1')} noDivider>
				<StepFomentoInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepFomento
