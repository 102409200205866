import Accordion from 'components/elements/Accordion/Accordion'
import AccordionItem from 'components/elements/Accordion/AccordionItem'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Input from 'components/elements/Input/Input'
import InputDate from 'components/elements/Input/InputDate'
import InputSwitch from 'components/elements/Input/InputSwitch'
import Loading from 'components/elements/Loading/Loading'
import SelecteVehicleClassOptions from 'components/elements/SelectOptions/SelecteVehicleClassOptions'
import { ERROR_URL, NOT_FOUND_URL, RESOURCES_URL, VEHICLES_VIEW_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import useVehicle from 'hooks/useVehicle'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { VehicleForm, VehiclePost } from 'models/Vehicle'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString } from 'utils/common'
import { dateToSQL } from 'utils/dateUtils'
import { getFormikError } from 'utils/formikUtils'
import * as Yup from 'yup'
import './VehicleEdit.scss'

const VehicleEdit = () => {
	const { vehicleId } = useParams<{ vehicleId: string }>()
	const vehicleIdNumber = parseInt(vehicleId ?? emptyString) || undefined
	const { vehicle, loading, error, updateVehicle } = useVehicle(vehicleIdNumber)
	const [isVehicleLoaded, setIsVehicleLoaded] = useState(false)
	const configStore = useConfigStore()
	const { t } = useTranslation()
	const navigate = useNavigate()

	const formik = useFormik<VehicleForm>({
		initialValues: {
			plate: emptyString,
			brand: emptyString,
			model: emptyString,
			classId: null,
			isActive: false,
			cardDate: undefined,
			itvDate: undefined,
			oilDate: undefined,
			tiresDate: undefined
		},
		validationSchema: Yup.object().shape({
			plate: Yup.string()
				.transform((value) => value.toUpperCase())
				.matches(/^\d{4}-[A-Z]{3}$/, t('errors.plate_format'))
				.required(t('errors.required_m', { field: t('vehicle.plate') })),
			brand: Yup.string().required(t('errors.required_m', { field: t('vehicle.brand') })),
			model: Yup.string().required(t('errors.required_m', { field: t('vehicle.model') })),
			classId: Yup.string().required(t('errors.required_m', { field: t('vehicle.class') })),
			isActive: Yup.boolean().required(t('errors.required_m', { field: t('vehicle.is_active') }))
		}),
		onSubmit: async (values) => {
			if (!values.classId || !vehicleId) return
			const vehicle: VehiclePost = {
				plate: values.plate,
				brand: values.brand,
				model: values.model,
				class: configStore.vehicleClasses.byId[values.classId].name,
				isActive: values.isActive,
				cardDate: values.cardDate ? dateToSQL(values.cardDate) : undefined,
				itvDate: values.itvDate ? dateToSQL(values.itvDate) : undefined,
				oilDate: values.oilDate ? dateToSQL(values.oilDate) : undefined,
				tiresDate: values.tiresDate ? dateToSQL(values.tiresDate) : undefined
			}
			try {
				updateVehicle({ vehicle, vehicleId: parseInt(vehicleId) })
				// await vehicleStore.updateVehicle(vehicle, parseInt(vehicleId))
				toast.success(t('vehicle.success_update'))
				navigate(`${VEHICLES_VIEW_URL}/${vehicleId}`)
			} catch (error) {
				toast.error(t('vehicle.error_update'))
			}
		}
	})

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	useEffect(() => {
		if (!vehicle || isVehicleLoaded) return

		const classId = configStore.getVehicleClassByClass(vehicle.class)?.id

		formik.setValues({
			plate: vehicle.plate,
			brand: vehicle.brand,
			model: vehicle.model,
			classId,
			isActive: vehicle.isActive,
			cardDate: vehicle.cardDate ? new Date(vehicle.cardDate) : undefined,
			itvDate: vehicle.itvDate ? new Date(vehicle.itvDate) : undefined,
			oilDate: vehicle.oilDate ? new Date(vehicle.oilDate) : undefined,
			tiresDate: vehicle.tiresDate ? new Date(vehicle.tiresDate) : undefined
		})
		setIsVehicleLoaded(true)
	}, [vehicle, isVehicleLoaded])

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })
	if (!vehicle || !vehicle.isVisible) {
		navigate(NOT_FOUND_URL)
		return null
	}

	return (
		<FormLayout
			title={t('vehicle.edit_title')}
			leftButton={<ButtonBack url={`${VEHICLES_VIEW_URL}/${vehicleId}`} />}
			footer={
				<ButtonComponent type='submit' onClick={handleSubmit} fullWidth>
					{t('general.save_changes')}
				</ButtonComponent>
			}>
			<Accordion>
				<AccordionItem title={t('vehicle.vehicle_details')}>
					<div className='accordion-input-wrapper'>
						<Input
							ref={setFieldRef('plate')}
							type='text'
							valueSelected={formik.values['plate']}
							id='plate'
							label={t('vehicle.plate_label')}
							onChange={(e) => {
								formik.setFieldValue('plate', e.target.value.toUpperCase())
							}}
							error={formik.errors['plate']}
							placeholder={t('vehicle.plate_placeholder')}
						/>
						<Input
							ref={setFieldRef('brand')}
							type='text'
							valueSelected={formik.values['brand']}
							id='brand'
							label={t('vehicle.brand_label')}
							onChange={formik.handleChange}
							error={formik.errors['brand']}
							placeholder={t('vehicle.brand_placeholder')}
						/>
						<Input
							ref={setFieldRef('model')}
							type='text'
							valueSelected={formik.values['model']}
							id='model'
							label={t('vehicle.model_label')}
							onChange={formik.handleChange}
							error={formik.errors['model']}
							placeholder={t('vehicle.model_placeholder')}
						/>
						<SelecteVehicleClassOptions
							ref={setFieldRef('classId')}
							label={t('vehicle.class_label')}
							selectedId={formik.values['classId']}
							onSelect={(id) => formik.setFieldValue('classId', id)}
							error={formik.errors['classId']}
						/>
					</div>
				</AccordionItem>
				<AccordionItem title={t('vehicle.maintenance')}>
					<InputDate
						id='cardDate'
						label={t('vehicle.transport_card_label')}
						value={formik.values['cardDate']}
						onSelect={(date) => formik.setFieldValue('cardDate', date)}
						error={getFormikError(formik, 'cardDate')}
					/>
					<InputDate
						id='itvDate'
						label={t('vehicle.itv')}
						value={formik.values['itvDate']}
						onSelect={(date) => formik.setFieldValue('itvDate', date)}
						error={getFormikError(formik, 'itvDate')}
					/>
					<InputDate
						id='oilDate'
						label={t('vehicle.oil_check_label')}
						value={formik.values['oilDate']}
						onSelect={(date) => formik.setFieldValue('oilDate', date)}
						error={getFormikError(formik, 'oilDate')}
					/>
					<InputDate
						id='tiresDate'
						label={t('vehicle.change_tires_label')}
						value={formik.values['tiresDate']}
						onSelect={(date) => formik.setFieldValue('tiresDate', date)}
						error={getFormikError(formik, 'tiresDate')}
					/>
				</AccordionItem>
				<AccordionItem title={t('vehicle.vehicle_condition')}>
					<InputSwitch
						id='isActive'
						label={t('general.active')}
						value={formik.values['isActive']}
						onChange={(value) => formik.setFieldValue('isActive', value)}
					/>
				</AccordionItem>
			</Accordion>
		</FormLayout>
	)
}

export default VehicleEdit
