import colors from 'constants/colors'
import React from 'react'

interface Props {
	width?: number
	height?: number
}

const AnulatedFomento: React.FC<Props> = (props) => {
	const { width = 15, height = 15 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.7289 14.6835C14.3603 14.0487 13.8125 12.2524 13.8125 9.90625C13.8125 8.49729 13.2528 7.14603 12.2565 6.14975C11.2602 5.15346 9.90893 4.59375 8.49996 4.59375C7.091 4.59375 5.73975 5.15346 4.74346 6.14975C3.74717 7.14603 3.18746 8.49729 3.18746 9.90625C3.18746 12.253 2.63895 14.0487 2.27039 14.6835C2.17628 14.8449 2.12638 15.0283 2.12574 15.2151C2.1251 15.4019 2.17373 15.5856 2.26674 15.7477C2.35975 15.9097 2.49384 16.0444 2.65549 16.138C2.81714 16.2317 3.00063 16.2811 3.18746 16.2812H5.8975C6.02007 16.881 6.34603 17.42 6.82024 17.8071C7.29445 18.1942 7.88781 18.4057 8.49996 18.4057C9.11211 18.4057 9.70547 18.1942 10.1797 17.8071C10.6539 17.42 10.9799 16.881 11.1024 16.2812H13.8125C13.9992 16.281 14.1826 16.2315 14.3442 16.1378C14.5058 16.0441 14.6398 15.9094 14.7327 15.7474C14.8256 15.5854 14.8742 15.4017 14.8735 15.2149C14.8729 15.0282 14.823 14.8449 14.7289 14.6835ZM8.49996 17.3438C8.17047 17.3436 7.8491 17.2414 7.5801 17.0511C7.3111 16.8609 7.10768 16.5919 6.99785 16.2812H10.0021C9.89224 16.5919 9.68883 16.8609 9.41982 17.0511C9.15082 17.2414 8.82946 17.3436 8.49996 17.3438Z'
				fill={colors.violetMt}
			/>
			<circle cx='13' cy='7' r='7' fill='white' />
			<path
				d='M13 1.3125C11.8751 1.3125 10.7755 1.64607 9.8402 2.27102C8.90489 2.89597 8.17591 3.78423 7.74544 4.82349C7.31496 5.86274 7.20233 7.00631 7.42179 8.10958C7.64124 9.21284 8.18292 10.2263 8.97833 11.0217C9.77374 11.8171 10.7872 12.3588 11.8904 12.5782C12.9937 12.7977 14.1373 12.685 15.1765 12.2546C16.2158 11.8241 17.104 11.0951 17.729 10.1598C18.3539 9.2245 18.6875 8.12488 18.6875 7C18.6859 5.49207 18.0862 4.04636 17.0199 2.98009C15.9536 1.91382 14.5079 1.31409 13 1.3125ZM15.0595 8.44047C15.1002 8.48112 15.1324 8.52937 15.1544 8.58248C15.1764 8.63559 15.1877 8.69251 15.1877 8.75C15.1877 8.80749 15.1764 8.86441 15.1544 8.91752C15.1324 8.97063 15.1002 9.01888 15.0595 9.05953C15.0189 9.10018 14.9706 9.13242 14.9175 9.15442C14.8644 9.17642 14.8075 9.18774 14.75 9.18774C14.6925 9.18774 14.6356 9.17642 14.5825 9.15442C14.5294 9.13242 14.4811 9.10018 14.4405 9.05953L13 7.61852L11.5595 9.05953C11.5189 9.10018 11.4706 9.13242 11.4175 9.15442C11.3644 9.17642 11.3075 9.18774 11.25 9.18774C11.1925 9.18774 11.1356 9.17642 11.0825 9.15442C11.0294 9.13242 10.9811 9.10018 10.9405 9.05953C10.8998 9.01888 10.8676 8.97063 10.8456 8.91752C10.8236 8.86441 10.8123 8.80749 10.8123 8.75C10.8123 8.69251 10.8236 8.63559 10.8456 8.58248C10.8676 8.52937 10.8998 8.48112 10.9405 8.44047L12.3815 7L10.9405 5.55953C10.8584 5.47744 10.8123 5.3661 10.8123 5.25C10.8123 5.1339 10.8584 5.02256 10.9405 4.94047C11.0226 4.85838 11.1339 4.81226 11.25 4.81226C11.3661 4.81226 11.4774 4.85838 11.5595 4.94047L13 6.38148L14.4405 4.94047C14.4811 4.89982 14.5294 4.86758 14.5825 4.84558C14.6356 4.82358 14.6925 4.81226 14.75 4.81226C14.8075 4.81226 14.8644 4.82358 14.9175 4.84558C14.9706 4.86758 15.0189 4.89982 15.0595 4.94047C15.1002 4.98112 15.1324 5.02937 15.1544 5.08248C15.1764 5.13559 15.1877 5.19251 15.1877 5.25C15.1877 5.30749 15.1764 5.36441 15.1544 5.41752C15.1324 5.47063 15.1002 5.51888 15.0595 5.55953L13.6185 7L15.0595 8.44047Z'
				fill='#F4516C'
			/>
		</svg>
	)
}

export default AnulatedFomento
