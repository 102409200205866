export const enum NotificationTypes {
	friendRequest = 'friend-request',
	transferShare = 'transfer-share'
}

export interface AppNotification {
	id: string
	type: NotificationTypes
	text: string
	isRead: boolean
}
