import { FetchResponse, Vehicle, VehiclePost, VehicleTechinicalPost } from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getAllVehiclesService = async (): Promise<Vehicle[]> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Vehicle[]>>(`${endpoints.vehicles}`, {
			method: 'GET',
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getVehicleById = async (id: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Vehicle>>(`${endpoints.vehicles}/${id}`, {
			method: 'GET',
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const createVehicleService = async (vehicle: VehiclePost) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Vehicle>>(`${endpoints.vehicles}`, {
			method: 'POST',
			token,
			bodyReq: vehicle
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateVehicleService = async (vehicle: Partial<VehiclePost>, vehicleId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Vehicle>>(
			`${endpoints.vehicles}/${vehicleId}`,
			{
				method: 'PATCH',
				token,
				bodyReq: vehicle
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateVehicleTechnicalService = async (
	vehicle: VehicleTechinicalPost,
	vehicleId: number
) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Vehicle>>(
			`${endpoints.vehicles}/${vehicleId}`,
			{
				method: 'PUT',
				token,
				bodyReq: vehicle
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const deleteVehicleService = async (vehicleId: number) => {
	const token = getUserToken()
	const response = await customFetch<FetchResponse<void>>(`${endpoints.vehicles}/${vehicleId}`, {
		method: 'DELETE',
		token
	})

	if ('error' in response) throw response

	return response.data
}

export const deleteVehicleById = async (vehicleId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(`${endpoints.vehicles}/${vehicleId}`, {
			method: 'DELETE',
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}
