import AssociationsUser, { ASSOCIATION_TYPES } from 'components/Resources/AssociationsUser'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import { DRIVERS_VIEW_URL, ERROR_URL, NOT_FOUND_URL, RESOURCES_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useDriver from 'hooks/useDriver'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { DriverForm, DriverPost } from 'models/Driver'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { emptyString, zeroAmount } from 'utils/common'
import * as Yup from 'yup'
import DriverInputs from './DriverInputs'

const DriverEdit = () => {
	const { driverId } = useParams<{ driverId: string }>()
	const driverIdNumber = parseInt(driverId ?? emptyString) || undefined
	const {
		driver,
		loading,
		error,
		updateDriver,
		deleteAssociationsByDriver,
		resetAndSetAssociations
	} = useDriver(driverIdNumber!)
	const [associationUserIds, setAssociationUserIds] = useState<number[]>([])
	const [isDriverLoaded, setIsDriverLoaded] = useState(false)
	const { t } = useTranslation()
	const navigate = useNavigate()

	const formik = useFormik<DriverForm>({
		initialValues: {
			name: emptyString,
			email: emptyString,
			changeTransferEmail: false,
			communicationEmail: false,
			confirmationEmail: false,
			isActive: false,
			profit: undefined
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t('errors.required_m', { field: t('driver.name') })),
			email: Yup.string().email(t('errors.mail_not_valid'))
		}),
		onSubmit: async (values) => {
			if (!driverIdNumber) return

			const driver: DriverPost = {
				name: values.name,
				email: values.email,
				changeTransferEmail: values.changeTransferEmail,
				communicationEmail: values.communicationEmail,
				confirmationEmail: values.confirmationEmail,
				isActive: values.isActive,
				profit: values.profit ?? zeroAmount
			}

			try {
				const driverUpdated = await updateDriver({ driver, driverId: driverIdNumber })

				if (associationUserIds.length) {
					await resetAndSetAssociations({
						driverId: driverUpdated.id,
						userIds: associationUserIds
					})
				} else {
					await deleteAssociationsByDriver(driverUpdated.id)
				}

				toast.success(t('driver.success_update'))
				navigate(`${DRIVERS_VIEW_URL}/${driverUpdated.id}`)
			} catch (error) {
				console.error({ error })
				toast.error(t('driver.error_update'))
			}
		}
	})

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	useEffect(() => {
		if (!driver || !driver.isVisible || isDriverLoaded) {
			return
		}

		if (!loading && !driver) {
			navigate(NOT_FOUND_URL)
			return
		}

		formik.setValues({
			name: driver.name,
			email: driver.email,
			changeTransferEmail: driver.changeTransferEmail,
			communicationEmail: driver.communicationEmail,
			confirmationEmail: driver.confirmationEmail,
			isActive: driver.isActive,
			profit: driver.profit
		})
		setIsDriverLoaded(true)
	}, [driver, isDriverLoaded])

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })
	if (!driver || !driver.isVisible) {
		return null
	}

	return (
		<FormLayout
			title={t('driver.edit_title')}
			leftButton={<ButtonBack url={`${DRIVERS_VIEW_URL}/${driverId}`} />}
			rightButton={
				<ButtonComponent type='submit' onClick={handleSubmit} variant='only-text'>
					{t('general.save')}
				</ButtonComponent>
			}>
			<DriverInputs formik={formik} setFieldRef={setFieldRef} />
			<FormSectionLayout title={t('provider.associate_user')}>
				<AssociationsUser
					type={ASSOCIATION_TYPES.DRIVER}
					resource={driver}
					onChangeAssociations={(userIds) => setAssociationUserIds(userIds)}
				/>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default DriverEdit
