import { useQuery, useQueryClient } from '@tanstack/react-query'
import { userQueryKey, usersQueryKey } from 'constants/reactQueryKeys'
import { User } from 'models/User'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchUsersService } from 'services/user'

const useUsers = () => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)

	// GET ALL USERS
	const {
		data: users,
		isLoading: isLoadingUsers,
		error: usersError,
		isError: isErrorUsers
	} = useQuery({
		queryKey: [usersQueryKey],
		queryFn: fetchUsersService,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	useEffect(() => {
		if (isErrorUsers && usersError) {
			setErrorMessage(t('user.error_load_users'))
		}
	}, [isErrorUsers, usersError, t])

	return {
		users: users || [],
		currentUser: queryClient.getQueryData<User>([userQueryKey]),
		loading: isLoadingUsers,
		error: errorMessage
	}
}

export default useUsers
