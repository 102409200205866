import Input from 'components/elements/Input/Input'
import { FormikProps } from 'formik'
import { AllStepForms, AllStepFormsWithFomentoForm, StepClientForm } from 'models/Transfer'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik:
		| FormikProps<StepClientForm>
		| FormikProps<AllStepForms>
		| FormikProps<AllStepFormsWithFomentoForm>

	setFieldRef?: (field: string) => (ref: HTMLInputElement) => void
}

const StepClientInputs: FC<Props> = (props) => {
	const { formik, setFieldRef } = props

	const { t } = useTranslation()

	return (
		<>
			<Input
				type='text'
				id='clientName'
				label={t('transfer.clientName')}
				placeholder={t('transfer.create_steps.step_client.inputs.name.placeholder')}
				valueSelected={formik.values['clientName']}
				onChange={formik.handleChange}
				styles={{ margin: '1rem 0' }}
				error={getFormikError(formik as FormikProps<StepClientForm>, 'clientName')}
				ref={setFieldRef ? setFieldRef('clientName') : undefined}
			/>
			<Input
				type='text'
				id='phone'
				label={t('transfer.phone')}
				placeholder={t('transfer.create_steps.step_client.inputs.telephone.placeholder')}
				valueSelected={formik.values['phone']}
				onChange={formik.handleChange}
				styles={{ margin: '1rem 0' }}
				error={getFormikError(formik as FormikProps<StepClientForm>, 'phone')}
				labelHelper
			/>
			<Input
				type='text'
				id='email'
				label={t('transfer.email')}
				placeholder={t('transfer.create_steps.step_client.inputs.email.placeholder')}
				valueSelected={formik.values['email']}
				onChange={formik.handleChange}
				styles={{ margin: '1rem 0' }}
				error={getFormikError(formik as FormikProps<StepClientForm>, 'email')}
				labelHelper
			/>
		</>
	)
}

export default StepClientInputs
