export const driversQueryKey = 'drivers'
export const driverQueryKey = 'driver'
export const driverAssociationsQueryKey = 'driverAssociations'
export const providersQueryKey = 'providers'
export const providerQueryKey = 'provider'
export const providerAssociationsQueryKey = 'providerAssociations'
export const usersQueryKey = 'users'
export const userQueryKey = 'user'
export const vehiclesQueryKey = 'vehicles'
export const vehicleQueryKey = 'vehicle'
export const dailyTransfersQueryKey = 'dailyTransfers'
export const transferQueryKey = 'transfer'
export const transfersQueryKey = 'transfers'
export const fomentoQueryKey = 'fomento'
export const sharedTransfersQueryKey = 'sharedTransfers'
export const startEndTransfersQueryKey = 'startEndTransfers'
export const companyQueryKey = 'company'
export const friendsQueryKey = 'friends'
export const requestsSentQueryKey = 'requestsSent'
export const requestsReceivedQueryKey = 'requestsReceived'
export const friendsAvailableQueryKey = 'friendsAvailable'
export const notificationsQueryKey = 'notifications'
export const unReadNotificationsQueryKey = 'unReadNotifications'
export const configurationsQueryKey = 'configurations'
